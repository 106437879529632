<ng-template #noQuestionText>
  <div class="text-14-600-dark">
    {{ 'NO' | translate }} {{ 'QUESTION_TEXT' | translate | lowercase }}
  </div>
</ng-template>
<ng-template #noAnswerText>
  <div class="text-14-600-dark">
    {{ 'NO' | translate }} {{ 'ANSWER_TEXT' | translate | lowercase }}
  </div>
</ng-template>
<div *ngIf="interaction" class="similar-inquiry-link">
  <div class="header-row">
    <div class="text-16-400-dark medis-mr-10">
      {{ 'ID' | translate }}: <span class="text-16-800-dark">{{ interaction | pk }}</span>
    </div>
    <div class="text-16-400-dark medis-mr-10">
      {{ 'INQUIRER' | translate }}:
      <span
        class="text-16-800-dark medis-clickable"
        (click)="previewInquirer(interaction.inquirer?.pk())"
      >
        {{ interaction.inquirer | fullName }}
      </span>
    </div>
    <div class="text-16-400-dark medis-mr-10">
      {{ 'TEAM' | translate }}: <span class="text-16-800-dark">{{ interaction.team?.name }}</span>
    </div>
    <div class="text-16-400-dark">
      {{ 'CREATED_ON' | translate }}:
      <span class="text-16-800-dark">{{ interaction.createdTs | dateChecker }}</span>
    </div>
  </div>
  <div
    class="content-row"
    *ngFor="let inquiry of interaction.inquiries; let i = index"
    [class.without-select-container]="!showSelectContainer"
  >
    <div class="text-16-800-dark">
      {{ inquiry.title() }} ({{ i + 1 }} / {{ interaction.inquiries.length }})
    </div>
    <div>
      <span class="text-13-800-white medis-tag">{{ 'QUESTION' | translate }}</span>
    </div>
    <ng-container *ngIf="inquiry.question?.text; else noQuestionText">
      <div class="html-preview" [innerHtml]="inquiry.question.text | sanitizeHtml"></div>
    </ng-container>
    <div>
      <span class="text-13-800-white medis-tag">{{ 'ANSWER' | translate }}</span>
    </div>
    <ng-container *ngIf="inquiry.answer?.text; else noAnswerText">
      <div class="html-preview" [innerHtml]="inquiry.answer.text | sanitizeHtml"></div>
    </ng-container>
    <div>
      <app-link-visualization
        *ngFor="let link of getAttachments(inquiry)"
        [link]="link"
        [allowRemove]="false"
      ></app-link-visualization>
    </div>
    <div *ngIf="showSelectContainer" class="select-container">
      <i
        class="far medis-icon"
        [ngClass]="
          selectedInquiry?.id === inquiry.id || selectedInquiryIds.includes(inquiry.id)
            ? 'fa-check-square active'
            : 'fa-square'
        "
        (click)="
          isMultipleSelect ? handleSelectedInquiryChange(inquiry.id) : (selectedInquiry = inquiry)
        "
        [matTooltip]="'SELECT_QUESTION' | translate"
      ></i>
    </div>
  </div>
</div>
