import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlResolverService } from 'app/common/url-resolver.service';
import { Ajax } from 'app/common/ajax';
import { BAttachment } from 'app/modules/data-model/data-model.module';
import { map } from 'rxjs/operators';

@Injectable()
export class AppNotificationsService {
  private readonly URL: string;

  constructor(urlResolver: UrlResolverService, private http: HttpClient) {
    this.URL = urlResolver.misApiUrlForPath('/notifications');
  }

  get(): Observable<AppNotificationsService.Notification[]> {
    return this.http
      .get<AppNotificationsService.Notification[]>(this.URL, Ajax.X_NO_LOADING_OPTIONS)
      .pipe(map((resp) => AppNotificationsService.Notification.fromRestArray(resp)));
  }

  dismiss(id: number): Observable<AppNotificationsService.Notification[]> {
    return this.http
      .delete<AppNotificationsService.Notification[]>(this.URL + '/' + id)
      .pipe(map((resp) => AppNotificationsService.Notification.fromRestArray(resp)));
  }
}

export namespace AppNotificationsService {
  export type NotificationType = 'internal_comm';

  export class Notification {
    id: number;
    text: string;
    type: NotificationType;
    refId: number;
    createdTs: Date;
    attachments: BAttachment[];

    static fromRest(json: any): Notification {
      json.attachments = BAttachment.fromRestArray(json.attachments);
      return Object.assign(new Notification(), json);
    }

    static fromRestArray(json: any[]): Notification[] {
      return json && json.map((v) => Notification.fromRest(v));
    }
  }
}
