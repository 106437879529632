import { Injectable, Injector } from '@angular/core';
import { BNotificationType } from './notification-type';
import { allNotificationTypesQuery } from './queries';
import { BaseDataService } from '../data-service';

@Injectable()
export class NotificationTypeService extends BaseDataService<BNotificationType> {
  query = allNotificationTypesQuery;
  objectInstance = new BNotificationType({});
  fieldName = 'allNotificationTypes';

  constructor(injector: Injector) {
    super(injector);
  }
}
