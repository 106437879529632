import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BHistory } from 'app/modules/data-model/interaction/interaction';
import {
  BAttachment,
  BInteraction,
  InquiriesService as InquiryService,
  SingleInteractionService,
} from 'app/modules/data-model/data-model.module';
import { DetailHistoryComponent } from 'app/modules/inquiry-detail/detail-history/detail-history.component';
import { GenericDialogComponent } from 'app/common/common/generic-dialog/generic-dialog.component';
import { LEFT_BAR_HISTORY_CATEGORIES, LEFT_BAR_HISTORY_CATEGORIES_MAP } from '@mi-tool/consts';
import { MessageHandlerService } from '../../../../common/common/message-handler/message-handler.service';

@Component({
  selector: 'app-history-visualization',
  templateUrl: './history-visualization.component.html',
  styleUrls: ['./history-visualization.component.scss'],
})
export class HistoryVisualizationComponent {
  @Input() history: BHistory;
  @Input() attachments: BAttachment[];
  @Input() loggedInUserIsSysAdmin: boolean;

  leftBarHistoryCategories = LEFT_BAR_HISTORY_CATEGORIES;
  leftBarHistoryCategoriesMap = LEFT_BAR_HISTORY_CATEGORIES_MAP;
  isInteractionClosedOrInReview: boolean = false;
  private _interaction: BInteraction;
  constructor(
    private modalService: MatDialog,
    private interactionService: SingleInteractionService,
    private inquiryService: InquiryService,
    private messageService: MessageHandlerService
  ) {}

  @Input()
  set interaction(interaction: BInteraction) {
    this._interaction = interaction;
    this.isInteractionClosedOrInReview = this._interaction?.isClosedOrInReview();
  }

  get interaction(): BInteraction {
    return this._interaction;
  }

  openHistory(): void {
    const modalRef = this.modalService.open(DetailHistoryComponent, {
      width: '820px',
      maxWidth: '100%',
      minHeight: '100px',
      maxHeight: '85vh',
    });
    modalRef.componentInstance.historyObject = this.history;
    modalRef.componentInstance.visualize = 'detail';
  }

  createQuestionFromFU(historyId: number): void {
    new GenericDialogComponent.Builder(this.modalService)
      .title('WARNING')
      .message('QUESTION_REQUIRE_ANSWER_MESSAGE')
      .additionalMessage('CONVERT_FOLLOWUP_TO_QUESTION_MESSAGE')
      .cancelLabel('CANCEL')
      .saveLabel('CONFIRM')
      .closeOnButton()
      .onSave(() => {
        this.inquiryService.createQuestionFromFU(historyId).subscribe({
          next: () => {
            this.interactionService.refreshInteraction(this.interaction.id);
          },
          error: () => {
            this.messageService.error(
              'An error occurred while creating question from follow-up response.'
            );
          },
        });
      });
  }
}
