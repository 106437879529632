<h2 mat-dialog-title>
  {{ confirmObj.title | translate }}
  <i class="far fa-times-circle medis-icon-large" (click)="dialogRef.close(false)"></i>
</h2>
<mat-dialog-content class="main-dialog-content">
  <div>{{ confirmObj.message | translate }}</div>
  <div>
    <div class="text-15-500-dark">
      <span>{{ confirmObj.secondParagraph | translate }}</span>
      <span class="text-15-500-azure">{{ confirmObj.fifthParagraph | translate }}</span>
    </div>
    <div class="text-15-500-dark" *ngIf="confirmObj.thirdParagraph">
      <span class="icon-style"
        ><i class="fa" [ngClass]="'fa-' + confirmObj.icon" aria-hidden="true"></i
      ></span>
      <span>{{ confirmObj.thirdParagraph | translate }}</span>
      <span class="text-15-700-dark">{{ confirmObj.fourthParagraph | translate }}</span>
    </div>
  </div>
  <div class="is-array">
    <mat-form-field
      *ngIf="confirmObj.array && confirmObj.array.length"
      class="mat-full-width form-field-wrapper"
    >
      <mat-label class="header-cell">{{ confirmObj.dropdownTitle | translate }}</mat-label>
      <mat-select [(ngModel)]="confirmObj.selected">
        <mat-option *ngFor="let obj of confirmObj.array" [value]="obj">{{ obj }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button class="medis-button-main" (click)="dialogRef.close(confirmObj)">
    {{ confirmObj.confirmBtn | translate }}
  </button>
  <button mat-button class="medis-button-reject" (click)="dialogRef.close(false)">
    {{ confirmObj.cancelBtn | translate }}
  </button>
</mat-dialog-actions>
