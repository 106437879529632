import { gql } from 'apollo-angular';

import * as MInquirer from '../inquirer/inquirer';
import * as MSpecialization from '../specialization/specialization';
import * as MLanguage from '../language/language';
import * as MEmail from '../email/email';
import * as MPhone from '../phone/phone';
import * as MAddress from '../address/address';
import * as MCountry from '../country/country';
import { MAlignment } from '../user/user';
import * as MGeography from '../geography/geography';

export const basicInquirerQuery = gql`
  query getInquirer($id: ID!) {
    inquirer(id: $id) {
      ...inquirerFragment
      email
      language {
        ...languageFragment
      }
      specialization {
        ...specializationFragment
      }
      mainPhone {
        ...phoneFragment
      }
      mainAddress {
        ...addressFragment
        country {
          ...countryFragment
        }
      }
    }
  }
  ${MInquirer.fragment}
  ${MLanguage.fragment}
  ${MSpecialization.fragment}
  ${MAddress.fragment}
  ${MPhone.fragment}
  ${MCountry.fragment}
`;

export const inquirerQuery = gql`
  query inquirerQuery($id: ID!) {
    inquirer(id: $id) {
      ...inquirerFragment
      email
      language {
        ...languageFragment
      }
      specialization {
        ...specializationFragment
      }
      defaultCountry {
        ...countryFragment
      }
      emails {
        ...emailConnectionFragment
      }
      phones {
        ...phoneConnectionFragment
      }
      addresses {
        edges {
          node {
            ...addressFragment
            country {
              ...countryFragment
            }
          }
        }
      }
      alignments {
        edges {
          node {
            ...alignmentFragment
            geographies {
              edges {
                node {
                  ...geographyFragment
                }
              }
            }
            users {
              edges {
                node {
                  firstName
                  lastName
                }
              }
            }
          }
        }
      }
    }
  }
  ${MInquirer.fragment}
  ${MLanguage.fragment}
  ${MSpecialization.fragment}
  ${MEmail.fragmentConnection}
  ${MPhone.fragmentConnection}
  ${MAddress.fragment}
  ${MCountry.fragment}
  ${MAlignment.fragment}
  ${MGeography.fragment}
`;

export const allInquirers = gql`
  query allInquirers($filters: String, $first: Int, $after: String, $last: Int, $before: String) {
    allInquirers(filters: $filters, first: $first, after: $after, last: $last, before: $before) {
      totalCount
      pageInfo {
        startCursor
        endCursor
      }
      edges {
        node {
          ...inquirerFragment
          email
          language {
            ...languageFragment
          }
          specialization {
            ...specializationFragment
          }
          defaultCountry {
            ...countryFragment
          }
          emails {
            ...emailConnectionFragment
          }
          phones {
            ...phoneConnectionFragment
          }
          addresses {
            edges {
              node {
                ...addressFragment
                country {
                  ...countryFragment
                }
              }
            }
          }
          alignments {
            edges {
              node {
                ...alignmentFragment
                geographies {
                  edges {
                    node {
                      ...geographyFragment
                    }
                  }
                }
                users {
                  edges {
                    node {
                      firstName
                      lastName
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${MInquirer.fragment}
  ${MLanguage.fragment}
  ${MSpecialization.fragment}
  ${MEmail.fragmentConnection}
  ${MPhone.fragmentConnection}
  ${MAddress.fragment}
  ${MCountry.fragment}
  ${MAlignment.fragment}
  ${MGeography.fragment}
`;

export const mutateInquirerQuery = gql`
  mutation updateInquirer($params: UpdateInquirerInput!) {
    updateInquirer(input: $params) {
      inquirer {
        ...inquirerFragment
        language {
          ...languageFragment
        }
        specialization {
          ...specializationFragment
        }
        defaultCountry {
          ...countryFragment
        }
        emails {
          ...emailConnectionFragment
        }
        phones {
          ...phoneConnectionFragment
        }
        addresses {
          edges {
            node {
              ...addressFragment
              country {
                ...countryFragment
              }
            }
          }
        }
      }
      newInquirer {
        ...newInquirerFragment
        language {
          ...languageFragment
        }
        specialization {
          ...specializationFragment
        }
        defaultCountry {
          ...countryFragment
        }
        emails {
          ...newEmailConnectionFragment
        }
        phones {
          ...newPhoneConnectionFragment
        }
        addresses {
          edges {
            node {
              ...newAddressFragment
              country {
                ...countryFragment
              }
            }
          }
        }
      }
    }
  }
  ${MInquirer.fragment}
  ${MLanguage.fragment}
  ${MSpecialization.fragment}
  ${MEmail.fragmentConnection}
  ${MPhone.fragmentConnection}
  ${MAddress.fragment}
  ${MCountry.fragment}
  ${MInquirer.fragmentNew}
  ${MEmail.fragmentNewConnection}
  ${MPhone.fragmentNewConnection}
  ${MAddress.fragmentNew}
`;
