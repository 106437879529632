import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { RegionService, SubRegionService } from './region/region.service';
import { TerritoryService } from './territory/territory.service';
import {
  SingleUserService,
  UserEditNotificationsService,
  UserEditService,
} from './user/user.service';
import { UsersService } from './user/users.service';
import {
  AllInquirersService,
  FilterInquirerService,
  InquirerEditService,
  InquirerService,
} from './inquirer/inquirer.service';
import { InquirersService } from './inquirer/inquirers.service';
import { InquiryEditService } from './inquiry/inquiry.service';
import { InquiriesService } from './inquiry/inquiries.service';
import { TeamService } from './team/team.service';
import { CreateTopicSuggestionService } from './topic/topic.service';
import { StatusService } from './status/status.service';
import { MedicalDocumentTypeService } from './medical-document-type/medical-document-type.service';
import { MedicalDocumentAudienceService } from './medical-document-audience/medical-document-audience.service';
import {
  MedicalDocumentService,
  SearchMedicalDocumentService,
  SingleMedicalDocumentService,
} from './medical-document/medical-document.service';
import { MedicalDocumentsService } from './medical-document/medical-documents.service';
import { AnswerTemplatesService } from './answer-template/answer-templates.service';
import { Nomenclatures, NomenclaturesService, Scopes } from './nomenclature/nomenclatures.service';
import { RepositoryBrowserService } from './repository-browser/repository-browser.service';
import {
  InteractionActionService,
  SingleInteractionService,
} from './interaction/interaction.service';
import { NewInteractionService } from './new-interaction/new-interaction.service';
import { SpamMessagesService } from './spam-message/spam-messages.service';
import { SpecializationService } from './specialization/specialization-service';
import { CountryService } from './country/country.service';
import { HistoryEditService, HistoryInquirerDataService } from './history/history.service';
import { EntityService, RelatedContentService } from 'app/modules/data-model/entity/entity.service';
import { ShareTemplateService } from 'app/modules/data-model/share-template/share-template.service';
import { AuthRequestService } from './auth-request/auth-request.service';
import { NotificationTypeService } from './notification-type/notification-type.service';
import { SingleInteractionAnswerService } from './interaction-answer/interaction-answer.service';
import { AttachmentsService } from './attachment/attachments.service';
import { EventService } from './event/event.service';
import { UserTemplatesService } from './user-template/user-templates.service';
import { UserNotificationService } from './user-notification/user-notification.service';
import { UserPreferencesService } from '../../common/common/user-preferences.service';
import { MailboxService } from './mailbox/mailbox.service';
import { DepartmentsService } from './department/departments.service';
import { EmailNotificationsTemplateService } from './email-notifications-template/email-notifications-template.service';
import { AuditTrailService } from './audit-trail/audit-trail.service';
import { CompanyService } from './company/company.service';
import { DomainService } from './domain/domain.service';
import { EnvironmentSyncService } from './env-sync/env-sync.service';

export { BLanguage } from './language/language';
export { BCountry } from './country/country';
export { UserEditService, SingleUserService } from './user/user.service';
export { UsersService };
export { TeamService } from './team/team.service';
export { BRole } from './role/role';
export { BAnswerTemplate, BAuthRequest, BUser, BUserNotification, BTeam } from './user/user';
export { BCurrentObjectState } from './workflow/current-object-state';
export { BState } from './workflow/state';
export { BInquirer } from './inquirer/inquirer';
export {
  InquirerService,
  InquirerEditService,
  AllInquirersService,
} from './inquirer/inquirer.service';
export { SpamMessagesService };
export { InquiryEditService } from './inquiry/inquiry.service';
export { InquiriesService };
export { BProduct, BRegion } from './region/region';
export {
  BMedicalDocument,
  BMedicalDocumentVersion,
  BSmpcPil,
  BTherapeuticArea,
} from './medical-document/medical-document';
export { BProductGroup } from './product/product-group';
export { BMedicalDocumentVersionFile } from './medical-document-version-file/medical-document-version-file';
export { MedicalDocumentTypeService } from './medical-document-type/medical-document-type.service';
export { MedicalDocumentAudienceService } from './medical-document-audience/medical-document-audience.service';
export { BSpamMessage } from './spam-message/spam-message';
export { BAttachment } from './attachment/attachment';
export { AttachmentsService };
export { EventService } from './event/event.service';
export { BEvent, BEventDefaultValue } from './event/event';
export { BTopic } from './topic/topic';
export { CreateTopicSuggestionService } from './topic/topic.service';
export {
  MedicalDocumentService,
  SingleMedicalDocumentService,
} from './medical-document/medical-document.service';
export { MedicalDocumentsService };
export { BInteraction, BHistory, BInquiry, BInteractionAnswer } from './interaction/interaction';
export {
  SingleInteractionService,
  InteractionActionService,
} from './interaction/interaction.service';
export { BNewInteraction } from './new-interaction/new-interaction';
export { NewInteractionService } from './new-interaction/new-interaction.service';
export { SpecializationService } from './specialization/specialization-service';
export { BSpecialization } from './specialization/specialization';
export { BCategory } from './category/category';
export { CountryService } from './country/country.service';
export { HistoryEditService, HistoryInquirerDataService } from './history/history.service';
export {
  EntityService,
  RelatedContentService,
  EntitySearchParams,
} from 'app/modules/data-model/entity/entity.service';
export { BEntity } from 'app/modules/data-model/entity/entity';
export { ShareTemplateService } from '../data-model/share-template/share-template.service';
export { AuthRequestService } from './auth-request/auth-request.service';
export { BNotificationType } from './notification-type/notification-type';
export { NotificationTypeService } from './notification-type/notification-type.service';
export { UserNotificationService } from './user-notification/user-notification.service';
export { SingleInteractionAnswerService } from './interaction-answer/interaction-answer.service';
export { AnswerTemplatesService };
export { NomenclaturesService, Scopes, Nomenclatures };
export { RepositoryBrowserService };
export { BUserTemplate } from './user-template/user-template';
export { UserTemplatesService };
export { BAddress } from './address/address';
export { BEmail } from './email/email';
export { BPhone } from './phone/phone';

@NgModule({
  imports: [CommonModule, NgbModule],
  declarations: [],
  providers: [
    RegionService,
    SubRegionService,
    TerritoryService,
    TeamService,
    StatusService,
    MedicalDocumentTypeService,
    MedicalDocumentAudienceService,
    MedicalDocumentService,
    MedicalDocumentsService,
    SingleMedicalDocumentService,
    SearchMedicalDocumentService,
    NewInteractionService,
    SingleInteractionService,
    AllInquirersService,
    InquirerService,
    InquirerEditService,
    UsersService,
    UserEditService,
    UserEditNotificationsService,
    SingleUserService,
    InquiryEditService,
    InquiriesService,
    SpecializationService,
    CountryService,
    HistoryEditService,
    HistoryInquirerDataService,
    EntityService,
    RelatedContentService,
    InteractionActionService,
    CreateTopicSuggestionService,
    ShareTemplateService,
    AuthRequestService,
    UserTemplatesService,
    SpamMessagesService,
    NotificationTypeService,
    UserNotificationService,
    SingleInteractionAnswerService,
    AttachmentsService,
    EventService,
    AnswerTemplatesService,
    NomenclaturesService,
    RepositoryBrowserService,
    InquirersService,
    FilterInquirerService,
    UserPreferencesService,
    MailboxService,
    DepartmentsService,
    EmailNotificationsTemplateService,
    AuditTrailService,
    CompanyService,
    DomainService,
    EnvironmentSyncService,
  ],
})
export class DataModelModule {}
