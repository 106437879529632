import { Injectable } from '@angular/core';
import { BCategory } from './category';
import { HttpClient } from '@angular/common/http';
import { UrlResolverService } from 'app/common/url-resolver.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SimplePage } from 'app/common/types';
import { Ajax } from 'app/common/ajax';
import { isNil, omitBy } from 'lodash';

export type TCategoryData = {
  id: string;
  name: string;
  type: string;
  isActive: boolean;
};

export type TCategoryDialogData = {
  category: BCategory;
};

export type TCategoryUpdateResponse = {
  message?: { [key: string]: string };
  hasMetadataDeactivation?: boolean;
};

export type TCategoryParams = {
  pageIndex: number;
  pageSize: number;
  orderBy?: string;
  id?: number;
  name?: string;
  type?: string;
  isActive?: string;
};

@Injectable({ providedIn: 'root' })
export class CategoryRestService {
  private readonly URL: string;

  constructor(private http: HttpClient, urlResolver: UrlResolverService) {
    this.URL = urlResolver.misApiUrlForPath('/metadata/categories');
  }

  fetchCategories(params: TCategoryParams): Observable<SimplePage<BCategory>> {
    return this.http
      .get<SimplePage<BCategory>>(this.URL, {
        ...Ajax.X_NO_LOADING_OPTIONS,
        params: omitBy(params, isNil),
      })
      .pipe(
        map((response) => {
          return { ...response, records: BCategory.fromRestArray(response.records) };
        })
      );
  }

  edit(data: TCategoryData): Observable<TCategoryUpdateResponse> {
    return this.http.put<TCategoryUpdateResponse>(this.URL, omitBy(data, isNil));
  }

  create(data: TCategoryData): Observable<void> {
    return this.http.post<void>(this.URL, omitBy(data, isNil));
  }
}
