import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { HttpClient } from '@angular/common/http';

import { UrlResolverService } from 'app/common/url-resolver.service';
import { FinalizationResponse } from './interactions.service';
import { MERGE_REASON_CHOICES } from '@mi-tool/consts';
import { BInteraction } from './interaction';

export type MergeReason =
  | 'fu_response'
  | 'newinquiry'
  | 'inquiry'
  | 'error'
  | 'internal'
  | 'thankyou'
  | 'duplicate';

export type MergeData = {
  mergeReason: MergeReason;
  targetInteraction: BInteraction;
  targetClosedOrInReview: boolean;
  targetMergedOrClosedWithoutAnswer: boolean;
  reopenAfterMerge: boolean;
  sourceInquiryIds: string[];
  targetInquiryIds: string[];
  validationMsg: string;
};

export type MergeParams = {
  action: 'close';
  reason: 'merged';
  object_id: string;
  related_to: string;
  merge_reason: MergeReason;
  reopen_after_merge?: boolean;
  source_inquiry_ids?: string[];
  target_inquiry_ids?: string[];
};

@Injectable({
  providedIn: 'root',
})
export class InteractionMergeService {
  public mergeData$ = new Subject<MergeData>();

  private readonly PERFORM_ACTIONS_URL: string;

  constructor(private http: HttpClient, private urlResolverService: UrlResolverService) {
    this.PERFORM_ACTIONS_URL = this.urlResolverService.apiUrlForPath([
      'api-rest',
      'workflow',
      'perform',
      'actions',
      '/',
    ]);
  }

  performMerge(mergeParams: MergeParams): Observable<FinalizationResponse> {
    return this.http.post<FinalizationResponse>(this.PERFORM_ACTIONS_URL, {
      params: JSON.stringify(mergeParams),
    });
  }

  getInitialMergeData(): MergeData {
    return {
      mergeReason: undefined,
      targetInteraction: undefined,
      targetClosedOrInReview: false,
      targetMergedOrClosedWithoutAnswer: false,
      reopenAfterMerge: false,
      sourceInquiryIds: [],
      targetInquiryIds: [],
      validationMsg: 'PLEASE_SELECT_INTERACTION_FOR_MERGE',
    };
  }

  getMergeReasonTranslationKeys(mergeReasonValue: MergeReason): string[] {
    return (
      MERGE_REASON_CHOICES.filter((mergeReason) => mergeReason.value === mergeReasonValue)
        .map((mergeReason) => mergeReason.historyMessages)
        .pop() || []
    );
  }
}
