<div class="d-flex flex-column align-items-center justify-content-center h-100 p-5">
  <i class="fas fa-lock text-100-azure m-4"></i>
  <p class="text-24-800-dark mb-4">{{ 'ACCESS_DENIED' | translate }}</p>
  <p class="text-16-400-dark mb-2">
    {{ 'YOU_DO_NOT_HAVE_PERMISSION_TO_ACCESS_THIS_PAGE' | translate }}
  </p>
  <p class="text-16-400-dark mb-4">
    {{ 'REDIRECT_TO_PAGE_WITH_PERMISSION_ACCESS' | translate }}
  </p>
  <button mat-button class="medis-button-main" (click)="navigateToPermittedPage()">
    {{ 'CLOSE' | translate }}
  </button>
</div>
