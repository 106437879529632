import { BaseModel, rProperty } from '../data-model';

export class BTaskLog extends BaseModel {
  @rProperty() id: string;
  @rProperty() startedOn: string;
  @rProperty() completedOn: string;
  @rProperty() successful: string;
  @rProperty() error: string;
  taskName: string;

  init() {}

  static fromRest(json: any): BTaskLog {
    return Object.assign(new BTaskLog({}), json);
  }

  static fromRestArray(json: any[]): BTaskLog[] {
    return json && json.map((v) => BTaskLog.fromRest(v));
  }
}
