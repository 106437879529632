<div *ngIf="workflowForm" class="documents-approval-workflow">
  <div *ngIf="view === viewEnum.DOCUMENT" class="document-view-btn-container top">
    <button
      mat-icon-button
      class="medis-icon-button"
      (click)="handleDialogClosing(dialogStatusesEnum.BACK)"
    >
      <i class="fas fa-chevron-left text-16-900-azure"></i>
    </button>

    <button
      (click)="handleDialogClosing(dialogStatusesEnum.ABORT_FILE_UPLOAD)"
      mat-button
      class="medis-button-reject"
    >
      {{ 'ABORT_FILE_UPLOAD' | translate }}
    </button>
  </div>
  <p class="title">
    <i class="far fa-file"></i>{{ 'DOCUMENTS_APPROVAL_WORKFLOW.PAGE_TITLE' | translate }}
  </p>

  <p class="point-1">
    <span class="number-icon">1</span>
    <ng-container *ngIf="view === viewEnum.TEAM && existingWorkflowTemplates.length > 0">{{
      'DOCUMENTS_APPROVAL_WORKFLOW.TEAM_VIEW_SELECT_EXISTING' | translate
    }}</ng-container>
    <ng-container *ngIf="view === viewEnum.DOCUMENT && existingWorkflowTemplates.length > 0">{{
      'DOCUMENTS_APPROVAL_WORKFLOW.DOC_VIEW_SELECT_EXISTING' | translate
    }}</ng-container>
    <button
      *ngIf="view === viewEnum.TEAM"
      (click)="workflowTemplateCreate()"
      mat-button
      class="medis-button-basic"
      [class.ms-2]="existingWorkflowTemplates.length > 0"
    >
      {{ 'DOCUMENTS_APPROVAL_WORKFLOW.CREATE_NEW' | translate }}
    </button>
  </p>
  <div
    class="existing-workflow-templates bg-faded p-4"
    *ngIf="existingWorkflowTemplates.length > 0"
  >
    <div
      class="workflow"
      *ngFor="let workflowTemplate of existingWorkflowTemplates"
      (click)="applyExistingWorkflowTemplate(workflowTemplate)"
      [class.selected]="workflowTemplate.id === workflowForm.value.id"
    >
      <i class="workflow-icon fas fa-project-diagram"></i> {{ workflowTemplate.name }}
    </div>
  </div>

  <p class="point-2" *ngIf="showWorkflowForm">
    <span class="number-icon">2</span>{{ 'DOCUMENTS_APPROVAL_WORKFLOW.CUSTOMIZE' | translate }}
    <span class="workflow-name">{{ workflowForm.value.name }}</span>
  </p>

  <form [formGroup]="workflowForm" *ngIf="showWorkflowForm" class="workflow-form bg-faded p-4">
    <div *ngIf="view === viewEnum.TEAM" class="workflow-name-input workflow-btn-save">
      <mat-form-field class="form-field-wrapper subscript-block" [hideRequiredMarker]="true">
        <mat-label>
          {{ 'DOCUMENTS_APPROVAL_WORKFLOW.NAME' | translate }} ({{ 'REQUIRED' | translate }})
        </mat-label>
        <input matInput formControlName="name" required [errorStateMatcher]="matcher" />
        <mat-error *ngIf="workflowForm.controls['name'].hasError('pattern')">
          {{ 'SIMPLE_TEXT' | translate }}</mat-error
        >
        <mat-error *ngIf="workflowForm.controls['name'].hasError('maxlength')">
          {{ 'PLEASE_ENTER_MAX' | translate }}
          {{ workflowForm.controls['name'].errors.maxlength.requiredLength }}
          {{ 'CHARACTERS' | translate }}.
        </mat-error>
      </mat-form-field>

      <button
        (click)="handleWorkflowTemplateCreate()"
        mat-button
        class="medis-button-basic"
        [disabled]="!workflowForm.valid || !workflowNameUnique"
      >
        {{ 'DOCUMENTS_APPROVAL_WORKFLOW.SAVE' | translate }}
      </button>

      <button
        *ngIf="workflowForm.value.id"
        (click)="handleWorkflowTemplateArchive()"
        mat-button
        class="medis-button-reject"
      >
        {{ 'DOCUMENTS_APPROVAL_WORKFLOW.DELETE' | translate }}
      </button>
    </div>
    <mat-error *ngIf="!workflowNameUnique" class="text-start mb-4">
      {{ 'DOCUMENTS_APPROVAL_WORKFLOW.ERROR_MSG_DUPLICATE_NAME' | translate }}
    </mat-error>
    <div class="stepper">
      <div
        class="step-wrapper"
        *ngFor="let item of workflowForm.value.steps; index as itemIndex; first as isFirstItem"
      >
        <div
          class="grid-container step"
          *ngFor="
            let step of workflowForm.controls['steps']['controls']
              | filterBy: 'order':itemIndex:true;
            first as isFirstStep;
            last as isLastStep;
            index as stepIndex
          "
        >
          <p *ngIf="isFirstStep" class="step-label text-16-800 mb-2">
            {{ 'DOCUMENTS_APPROVAL_WORKFLOW.STEP' | translate }} {{ itemIndex + 1 }}
          </p>

          <div class="grid-column step-dropdowns" [class.border-dashed]="!step.valid">
            <app-search-select
              [disabled]="isFirstItem || !hasUsersWithDocumentApprovalRole"
              [required]="true"
              [config]="step.value.approvalTypeConfig"
              [multiple]="false"
              [selectedValue]="step.value.approvalTypeId"
              (selectedValueChange)="handleValueChange(step, 'approvalTypeId', $event)"
              matTooltip="{{ 'DOCUMENTS_APPROVAL_WORKFLOW.APPROVAL_TYPE_TOOLTIP' | translate }}"
              [matTooltipPosition]="'above'"
              [matTooltipDisabled]="isFirstItem || hasUsersWithDocumentApprovalRole"
            ></app-search-select>

            <app-search-select
              *ngIf="
                view === viewEnum.DOCUMENT || (view === viewEnum.TEAM && !isFirstItem);
                else reviewerText
              "
              [disabled]="isFirstItem || !step.value.approvalTypeId"
              [required]="true"
              [config]="step.value.reviewerConfig"
              [multiple]="true"
              [selectedValue]="step.value.reviewerIds"
              (selectedValueChange)="handleValueChange(step, 'reviewerIds', $event)"
            ></app-search-select>

            <ng-template #reviewerText>
              <p>{{ 'DOCUMENTS_APPROVAL_WORKFLOW.ALL_USERS_WITH_DOC_AUTHOR_ROLE' | translate }}</p>
            </ng-template>

            <app-search-select
              *ngIf="!isFirstItem"
              [required]="true"
              [config]="approvalPeriodConfig"
              [selectedValue]="step.value.approvalPeriod"
              (selectedValueChange)="handleValueChange(step, 'approvalPeriod', $event)"
            ></app-search-select>

            <app-search-select
              *ngIf="!isFirstItem"
              [disabled]="!step.value.approvalTypeId"
              [required]="true"
              [config]="step.value.approveWithChangesActionConfig"
              [selectedValue]="step.value.actionOnApproveWithChangesId"
              (selectedValueChange)="
                handleValueChange(step, 'actionOnApproveWithChangesId', $event)
              "
            ></app-search-select>

            <app-search-select
              *ngIf="!isFirstItem"
              [disabled]="!step.value.approvalTypeId"
              [required]="true"
              [config]="step.value.rejectActionConfig"
              [selectedValue]="step.value.actionOnRejectId"
              (selectedValueChange)="handleValueChange(step, 'actionOnRejectId', $event)"
            ></app-search-select>
          </div>

          <div class="grid-column step-buttons flex-column">
            <i
              *ngIf="isFirstStep && maxOrder > step.value.order"
              class="fas medis-icon fa-chevron-right mx-3"
            ></i>

            <!-- Create step icon -->
            <i
              *ngIf="isFirstStep && step.value.approvalTypeId !== finalApprovalType.id"
              (click)="handleWorkflowStepCreate(step.value.order + 1, true, true)"
              class="fas medis-icon fa-plus-circle mx-3"
              [class.disabled]="!this.workflowForm.valid || maxSteps === maxOrder + 1"
            >
            </i>

            <!-- Delete step icon -->
            <i
              *ngIf="step.value.order > 0"
              (click)="handleWorkflowStepDelete(step)"
              class="fas medis-icon fa-times-circle mx-3"
            >
            </i>
          </div>

          <div class="grid-column step-buttons">
            <i *ngIf="!isLastStep" class="medis-icon fas fa-arrows-alt-v my-2 ms-0"></i>

            <!-- Create sub step icon -->
            <i
              *ngIf="
                isLastStep &&
                step.value.order > 0 &&
                step.value.approvalTypeId !== finalApprovalType.id
              "
              (click)="handleWorkflowStepCreate(step.value.order, true)"
              class="fas medis-icon fa-plus-circle mx-0 my-3"
              [class.disabled]="!this.workflowForm.valid || maxSubSteps === stepIndex + 1"
            ></i>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div *ngIf="view === viewEnum.DOCUMENT" class="document-view-btn-container bottom">
    <button
      (click)="handleDialogClosing(dialogStatusesEnum.SUBMIT_FOR_APPROVAL)"
      mat-button
      class="medis-button-main me-4"
      [disabled]="!workflowForm.valid || !workflowNameUnique"
    >
      {{ 'SUBMIT_FOR_APPROVAL' | translate }}
    </button>

    <button
      (click)="handleDialogClosing(dialogStatusesEnum.CREATE_DRAFT)"
      mat-button
      class="medis-button-basic"
      [disabled]="!workflowForm.valid || !workflowNameUnique"
    >
      {{ 'CREATE_DRAFT' | translate }}
    </button>
  </div>
</div>
