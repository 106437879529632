import { HttpErrorResponse } from '@angular/common/http';

export namespace RollbarHandler {
  export function error(error: Error | HttpErrorResponse | string): void {
    resolve((rollbar) => {
      if (error instanceof Error || typeof error === 'string') {
        rollbar.error(error);
      } else if (error instanceof HttpErrorResponse) {
        const msg = `${error.url} returned unhandled error ${error.status} ${error.statusText}`;
        rollbar.error(msg, error);
      } else {
        const msg = `Unhandled error ${error} (${typeof error})`;
        rollbar.error(msg, error);
      }
    });
  }

  export function warn(message: string): void {
    resolve((rollbar) => rollbar.warn(message));
  }

  export function setCurrentUserId(id: number): void {
    resolve((rollbar) => rollbar.configure({ payload: { person: { id: id } } }));
  }

  export function setCodeVersion(version: string): void {
    const configPatch = { payload: { client: { javascript: { code_version: version } } } };
    resolve((rollbar) => rollbar.configure(configPatch));
  }

  function resolve(callback: (rollbar: any) => void): void {
    const rollbar = (window as any).Rollbar;
    if (rollbar) {
      callback(rollbar);
    }
  }
}
