import { Component, Input } from '@angular/core';
import { MedisNavigationItem } from '@mi-tool/types';

@Component({
  selector: 'app-generic-left-bar-view',
  templateUrl: './generic-left-bar-view.component.html',
})
export class GenericLeftBarViewComponent {
  @Input() navigation: MedisNavigationItem[] = [];
}
