import { gql } from 'apollo-angular';

import * as MInquirer from '../inquirer/inquirer';
import { MInquiry } from '../interaction/interaction';
import * as MLanguage from '../language/language';
import { MTeam } from '../user/user';
import * as MSpecialization from '../specialization/specialization';

//TODO add product, status (main_status), children, parent_thread
export const filterQuery = gql`
  query AllNewInteractionQuery($userId: ID!) {
    user(id: $userId) {
      id
      newInsertedInteractions {
        edges {
          node {
            id
            createdTs
            deletePiOnClose
            outgoingChannel
            confidential
            inquirer {
              id
              firstName
              lastName
              ...newInquirerFragment
              specialization {
                ...specializationFragment
              }
              defaultCountry {
                id
                name
                code
                timezoneCode
              }
              language {
                ...languageFragment
              }
            }
            team {
              ...teamFragment
            }
            inquiries {
              edges {
                node {
                  id
                  product {
                    id
                    name
                  }
                  category {
                    id
                    name
                  }
                  topic {
                    id
                    name
                  }
                  question {
                    id
                    text
                  }
                  answer {
                    id
                    text
                  }
                  ...newInquiryFragment
                }
              }
            }
          }
        }
      }
    }
  }
  ${MLanguage.fragment}
  ${MInquirer.fragmentNew}
  ${MSpecialization.fragment}
  ${MInquiry.fragmentNew}
  ${MTeam.fragment}
`;
