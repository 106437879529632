import { gql } from 'apollo-angular';
import * as MRole from '../role/role';
import { MTeam } from './user';

export const fragment = gql`
  fragment affiliationFragment on AffiliationNode {
    id
    role {
      ...roleFragment
    }
    team {
      ...teamFragment
    }
    statusName
    user {
      id
      username
      firstName
      lastName
    }
  }
  ${MTeam ? MTeam.fragment : ''}
  ${MRole ? MRole.fragment : ''}
`;

export const fragmentConnection = gql`
  fragment affiliationConnectionFragment on AffiliationNodeConnection {
    edges {
      node {
        ...affiliationFragment
      }
    }
  }
  ${fragment}
`;
