import { BaseModel } from '../data-model';
import { BCountry } from '../country/country';
import { BTeam } from '../user/user';
import { BLanguage } from '../language/language';

export enum SubmissionCondition {
  DIRECT = 0,
  VIA_COMPANY_EMPLOYEE = 1,
}

export enum TemplateType {
  GDPR = 0,
  NOTIFICATION = 1,
  HOLIDAY_GDPR = 2,
}

export enum TemplateCondition {
  OFF_LABEL = 0,
  ON_LABEL = 1,
}

export type HolidayGDPRTemplateDataType = {
  name: string;
  subject: string;
  text: string;
  teamIds: string[];
};

export class BEmailNotificationsTemplate extends BaseModel {
  teamId: string = '';
  team: BTeam;
  subject: string = '';
  id: string | number = '';
  name: string = '';
  previewText: string = '';
  text: string = '';
  country: BCountry = null;
  countryId: string = '';
  language: BLanguage = null;
  languageId: string = '';
  isDefault: boolean = false;
  isDeleted: boolean = false;
  submissionCondition: SubmissionCondition;
  type: TemplateType;
  condition: TemplateCondition;

  init() {}

  static fromRest(json: any): BEmailNotificationsTemplate {
    return Object.assign(new BEmailNotificationsTemplate(), json);
  }

  static fromRestArray(json: any[]): BEmailNotificationsTemplate[] {
    return json.map((v) => BEmailNotificationsTemplate.fromRest(v));
  }
}
