<ng-template #textNoInquirer>
  <p class="text-15-700-dark">{{ 'NO_INQUIRER' | translate }}</p>
</ng-template>

<h2 mat-dialog-title [innerHtml]="title"></h2>

<mat-dialog-content>
  <div class="generic-dialog-main">
    <img class="gd-svg" [src]="'../../../assets/img/icons/attention.svg'" alt="attention" />
    <div [innerHtml]="message" class="gd-message"></div>
    <div *ngIf="additionalMessage" [innerHtml]="additionalMessage" class="gd-message"></div>
    <mat-checkbox *ngIf="checkboxLabel" [(ngModel)]="checkboxValue" class="gd-checkbox">
      {{ checkboxLabel }}
    </mat-checkbox>
    <div *ngIf="showInquirersComparisonContainer" class="medis-flex-container-even">
      <user-inquirer-card
        *ngIf="firstInquirer?.id; else textNoInquirer"
        [inquirer]="firstInquirer"
      ></user-inquirer-card>
      <user-inquirer-card
        *ngIf="secondInquirer?.id; else textNoInquirer"
        [inquirer]="secondInquirer"
      ></user-inquirer-card>
    </div>

    <div *ngIf="tipText" class="medis-pv-5">
      <i
        class="fa fa-lightbulb text-16-700-orange-yellow me-1"
        matTooltip="{{ 'TIP' | translate }}"
      ></i>
      {{ tipText | translate }}
    </div>
  </div>

  <div *ngIf="confirmationQuestion" [innerHtml]="confirmationQuestion" class="text-center"></div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button class="medis-button-main" (click)="saveResponse(true)">
    {{ saveButtonLabel }}
  </button>
  <button
    *ngIf="isCancelAvailable"
    mat-button
    class="medis-button-reject"
    (click)="saveResponse(false)"
  >
    {{ cancelButtonLabel }}
  </button>
</mat-dialog-actions>
