import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { parseAttr } from '../../data-model/data-model';
import { BInquiry } from '../interaction/interaction';
import { updateInquiry } from './queries';
import { createGuid } from 'app/common/uuid-generator';
import { MessageHandlerService } from 'app/common/common/message-handler/message-handler.service';

@Injectable({
  providedIn: 'root',
})
export class InquiryEditService {
  response: Subject<BInquiry> = new Subject<BInquiry>();

  constructor(private apollo: Apollo, private messageService: MessageHandlerService) {}

  performQuery(toMutate: any): void {
    const mutationData = Object.assign({}, toMutate);
    mutationData['clientMutationId'] = createGuid();
    this.apollo
      .mutate({
        mutation: updateInquiry,
        variables: { params: mutationData },
      })
      .subscribe(
        (response) => {
          if (response) {
            this.messageService.info('Inquiry updated correctly.');
            let inquiryResponseData = response.data['updateInquiry'];
            // update of an existing interaction
            var field = 'inquiry';
            if (!inquiryResponseData['inquiry']) {
              // update of a new interaction object
              field = 'newInquiry';
            }
            let changedInquiry = parseAttr<BInquiry>(inquiryResponseData, BInquiry, field);
            this.response.next(changedInquiry);
          } else {
            this.response.next(undefined);
            this.messageService.error('Inquiry Edit: empty response');
          }
        },
        (error) => {
          this.messageService.error('(Inquiry Edit) ' + error);
        }
      );
  }
}
