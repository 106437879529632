import { capitalize } from 'lodash';
import { BTherapeuticArea } from 'app/modules/data-model/medical-document/medical-document';
import {
  BCategory,
  BCountry,
  BEvent,
  BInquirer,
  BLanguage,
  BProduct,
  BRegion,
  BRole,
  BSpecialization,
  BTeam,
  BTopic,
  BUser,
} from 'app/modules/data-model/data-model.module';
import { RegisterServiceCountry } from 'app/auth/register/register.service';
import { BMailbox } from 'app/modules/data-model/mailbox/mailbox';
import { BMedicalDocumentAudience } from 'app/modules/data-model/medical-document-audience/medical-document-audience';
import { BProductGroup } from 'app/modules/data-model/product/product-group';
import { ITimezone } from 'app/common/common/date-picker/timezones';
import { FilterSelection } from 'app/modules/inquiries/inquiries-list/inquiries-filter/configs/filter-configs';
import { BAlignment } from 'app/modules/data-model/user/user';
import { IDataValue } from '@mi-tool/models/data-model';
import { BHcpTypes } from 'app/modules/data-model/hcp-types/hcp-types.model';
import { BSpecializationTypes } from 'app/modules/data-model/specialization-types/specialization-types.model';
import { BCompany } from 'app/modules/data-model/company/company';
import { BMedicalDocumentType } from 'app/modules/data-model/medical-document-type/medical-document-type';
import {
  BApprovalType,
  BChangeRequestedActionType,
} from 'app/modules/data-model/medical-documents-approval/medical-documents-approval';
import { FOLLOW_UP_FREQUENCY_MAPPER } from '@mi-tool/utils/mappers';
import { BTask } from 'app/modules/data-model/task/task';
import { NameValue } from '@mi-tool/consts';

export class Config {
  constructor(public rawItems: any, public label: string, public items: ConfigItem<any>[]) {}

  getItem(id: string): ConfigItem<any> {
    return this.items.find((i) => i.id === id);
  }
}

export class ConfigItem<T> {
  constructor(
    public id: string,
    public headerLabel: string,
    public searchLabel: string,
    public options?: ConfigItemOption<T>[]
  ) {}
}

export class SelectedOption {
  constructor(public typeId: string, public option: ConfigItemOption<any>) {}
}

export class ConfigItemOption<T> {
  constructor(public name: string, public value: T) {}
}

export function newTimezoneConfig(timezones: ITimezone[]): Config {
  return new Config(timezones, 'COUNTRY', [
    new ConfigItem(
      '',
      '',
      'Search',
      timezones.map((c) => new ConfigItemOption(c.timezone, c.name))
    ),
  ]);
}

export function newOnlyMedicalDocumentTypeConfig(
  documentTypes: {
    key: string;
    name: string;
  }[]
): Config {
  return new Config(documentTypes, '', [
    new ConfigItem(
      '',
      '',
      'Search',
      documentTypes.map((i) => new ConfigItemOption(i.name, i.key))
    ),
  ]);
}

export function newLanguageConfig(languages: BLanguage[]): Config {
  return new Config(languages, 'LANGUAGE', [
    new ConfigItem(
      '',
      '',
      'Search',
      languages.map((l) => new ConfigItemOption(l.name, l.id))
    ),
  ]);
}

export function newLanguageIdConfig(languages: BLanguage[]): Config {
  return new Config(languages, 'LANGUAGE', [
    new ConfigItem(
      '',
      '',
      'Search',
      languages.map((l) => new ConfigItemOption(l.name, l.pk()))
    ),
  ]);
}

export function newBLanguageConfig(languages: BLanguage[]): Config {
  return new Config(languages, 'LANGUAGE', [
    new ConfigItem(
      '',
      '',
      'Search',
      languages.map((l) => new ConfigItemOption(l.name, l))
    ),
  ]);
}

export function newCountryConfig(countries: BCountry[], label = 'COUNTRY'): Config {
  return new Config(countries, label, [
    new ConfigItem(
      '',
      '',
      'Search',
      countries.map((c) => new ConfigItemOption(c.name, c.id))
    ),
  ]);
}

export function newRegisterPageCountryConfig(
  countries: RegisterServiceCountry[],
  label = 'COUNTRY'
): Config {
  return new Config(countries, label, [
    new ConfigItem(
      '',
      '',
      'Search',
      countries.map((c) => new ConfigItemOption(c.name, c))
    ),
  ]);
}

export function newCountryNameConfig(countries: BCountry[], label = ''): Config {
  return new Config(countries, label, [
    new ConfigItem(
      '',
      '',
      'Search',
      countries.map((c) => new ConfigItemOption(c.name, c.name))
    ),
  ]);
}

export function newHcpTypeConfig(hcpTypes: BHcpTypes[], label = ''): Config {
  return new Config(hcpTypes, label, [
    new ConfigItem(
      '',
      '',
      'Search',
      hcpTypes.map((c) => new ConfigItemOption(c.name, c.id))
    ),
  ]);
}

export function newSpecializationTypesConfig(
  specializationTypes: BSpecializationTypes[],
  label = ''
): Config {
  return new Config(specializationTypes, label, [
    new ConfigItem(
      '',
      '',
      'Specialization Types',
      specializationTypes.map((c) => new ConfigItemOption(c.name, c.id))
    ),
  ]);
}

export function newRoleIdConfig(roles: BRole[], label = 'Role'): Config {
  return new Config(roles, label, [
    new ConfigItem(
      '',
      '',
      'Search',
      roles.map((r) => new ConfigItemOption(r.name, r.pk()))
    ),
  ]);
}

export function newRoleNameConfig(roles: BRole[], label = 'Role'): Config {
  return new Config(roles, label, [
    new ConfigItem(
      '',
      '',
      'Search',
      roles.map((r) => new ConfigItemOption(r.name, r.name))
    ),
  ]);
}

export function newSpecializationConfig(
  specializations: BSpecialization[],
  label: 'SPECIALIZATION' | 'PROFESSION' | '' = 'SPECIALIZATION'
): Config {
  return new Config(specializations, label, [
    new ConfigItem(
      '',
      '',
      'Search',
      specializations.map((s) => new ConfigItemOption(s.name, s.id))
    ),
  ]);
}

export function newCountryIdConfig(countries: BCountry[], label = 'COUNTRY'): Config {
  return new Config(countries, label, [
    new ConfigItem(
      '',
      '',
      'Search',
      countries.map((c) => new ConfigItemOption(c.name, c.pk()))
    ),
  ]);
}

export function newBCountryConfig(countries: BCountry[]): Config {
  return new Config(countries, 'COUNTRY', [
    new ConfigItem(
      '',
      '',
      'Search',
      countries.map((c) => new ConfigItemOption(c.name, c))
    ),
  ]);
}

export function newProductConfig(products: BProduct[], label = 'PRODUCTS'): Config {
  return new Config(products, label, [
    new ConfigItem(
      '',
      '',
      'Search',
      products.map((p) => new ConfigItemOption(p.name, p.id))
    ),
  ]);
}

export function newProductWithInactiveStatusLabelConfig(
  areas: BProduct[],
  label = 'PRODUCTS'
): Config {
  const labelForInactiveArea = ' (status Inactive, due to lack of selected therapeutic area)';
  return new Config(areas, label, [
    new ConfigItem(
      '',
      '',
      'Search',
      areas.map(
        (a) =>
          new ConfigItemOption(
            `${a.name}${a.isActive && a.therapeuticAreas.length !== 0 ? '' : labelForInactiveArea}`,
            a.id
          )
      )
    ),
  ]);
}

export function newBProductIdConfig(products: BProduct[], label = 'PRODUCTS'): Config {
  return new Config(products, label, [
    new ConfigItem(
      '',
      '',
      'Search',
      products.map((p) => new ConfigItemOption(p.name, p.pk()))
    ),
  ]);
}

export function newProductGroupConfig(
  productGroups: BProductGroup[],
  label = 'PRODUCT_GROUPS'
): Config {
  return new Config(productGroups, label, [
    new ConfigItem(
      '',
      '',
      'Search',
      productGroups.map((p) => new ConfigItemOption(p.name, p.id))
    ),
  ]);
}

export function newTeamNameConfig(teams: BTeam[], label = 'TEAM'): Config {
  return new Config(teams, label, [
    new ConfigItem(
      'teams',
      'teams',
      'Search_team_here',
      teams.map((t) => new ConfigItemOption(t.name, t.name))
    ),
  ]);
}

export function newTeamIdConfig(teams: BTeam[], label = ''): Config {
  return new Config(teams, label, [
    new ConfigItem(
      'teams',
      'teams',
      'Search_team_here',
      teams.map((t) => new ConfigItemOption(t.name, t.pk()))
    ),
  ]);
}

export function newTeamConfig(teams: BTeam[], label = 'TEAM'): Config {
  return new Config(teams, label, [
    new ConfigItem(
      'teams',
      'teams',
      'Search_team_here',
      teams.map((t) => new ConfigItemOption(t.name, t.id))
    ),
  ]);
}

export function newCategoryNameConfig(
  categories: BCategory[],
  label = 'CATEGORY',
  headerLabel = 'Search'
): Config {
  return new Config(categories, label, [
    new ConfigItem(
      '',
      '',
      headerLabel,
      categories.map((c) => new ConfigItemOption(c.name, c.name))
    ),
  ]);
}

export function newCategoryConfig(
  categories: BCategory[],
  label = 'CATEGORY',
  headerLabel = 'Search'
): Config {
  return new Config(categories, label, [
    new ConfigItem(
      '',
      '',
      headerLabel,
      categories.map((c) => new ConfigItemOption(c.name, c.id))
    ),
  ]);
}

export function newBCategoryIdConfig(
  categories: BCategory[],
  label = 'CATEGORY',
  headerLabel = 'Search'
): Config {
  return new Config(categories, label, [
    new ConfigItem(
      '',
      '',
      headerLabel,
      categories.map((c) => new ConfigItemOption(c.name, c.pk()))
    ),
  ]);
}

export function newTopicConfig(topics: BTopic[], label = 'TOPIC'): Config {
  return new Config(topics, label, [
    new ConfigItem(
      '',
      '',
      'Search',
      topics.map((t) => new ConfigItemOption(t.name, t.id))
    ),
  ]);
}

export function newBTopicIdConfig(topics: BTopic[], label = 'TOPIC'): Config {
  return new Config(topics, label, [
    new ConfigItem(
      '',
      '',
      'Search',
      topics.map((t) => new ConfigItemOption(t.name, t.pk()))
    ),
  ]);
}

export function newBProductConfig(products: BProduct[], label = 'PRODUCT'): Config {
  return new Config(products, label, [
    new ConfigItem(
      '',
      '',
      'Search',
      products.map((p) => new ConfigItemOption(p.name, p))
    ),
  ]);
}

export function newBTopicConfig(topics: BTopic[]): Config {
  return new Config(topics, 'TOPIC', [
    new ConfigItem(
      '',
      '',
      '',
      topics.map((t) => new ConfigItemOption(t.name, t))
    ),
  ]);
}

export function newBCategoryConfig(categories: BCategory[]): Config {
  return new Config(categories, 'CATEGORY', [
    new ConfigItem(
      '',
      '',
      '',
      categories.map((c) => new ConfigItemOption(c.name, c))
    ),
  ]);
}

export function newStatusNameConfig(
  statuses: string[] | readonly string[],
  label = 'Status'
): Config {
  return new Config(statuses, label, [
    new ConfigItem(
      '',
      '',
      'Search',
      statuses.map((s) => new ConfigItemOption(s, s))
    ),
  ]);
}

export function newBTeamConfig(teams: BTeam[], label = 'TEAM'): Config {
  return new Config(teams, label, [
    new ConfigItem(
      'teams',
      'teams',
      'Search_team_here',
      teams.map((t) => new ConfigItemOption(t.name, t))
    ),
  ]);
}

export function newBRoleConfig(roles: BRole[], label = 'Role'): Config {
  return new Config(roles, label, [
    new ConfigItem(
      '',
      '',
      'Search',
      roles.map((r) => new ConfigItemOption(r.description, r.id))
    ),
  ]);
}

export function newBUserConfig(users: BUser[], label = 'ASSIGNED_TO'): Config {
  return new Config(users, label, [
    new ConfigItem(
      'users',
      'people',
      'Search_people_here',
      users.map((u) => new ConfigItemOption(u.displayInfo(), u))
    ),
  ]);
}

export function newUserIdConfig(users: BUser[], label: string = ''): Config {
  return new Config(users, label, [
    new ConfigItem(
      '',
      '',
      'Search',
      users.map((u) => new ConfigItemOption(u.displayInfo(), u.pk()))
    ),
  ]);
}

export function newUserNameAndEmailIdConfig(users: BUser[], label: string = ''): Config {
  return new Config(users, label, [
    new ConfigItem(
      '',
      '',
      'Search',
      users.map((u) => new ConfigItemOption(u.displayFullNameAndEmail(), u.pk()))
    ),
  ]);
}

export function newUserConfig(users: BUser[], label: string = ''): Config {
  return new Config(users, label, [
    new ConfigItem(
      '',
      '',
      'Search',
      users.map((u) => new ConfigItemOption(u.displayInfo(), u.id))
    ),
  ]);
}

export function newInquirerIdConfig(inquirers: BInquirer[], label = 'INQUIRER'): Config {
  return new Config(inquirers, label, [
    new ConfigItem(
      '',
      '',
      'Enter First or Last name',
      inquirers
        .sort((a, b) => {
          const nameA = (a.firstName || '') + (a.lastName || ''),
            nameB = (b.firstName || '') + (b.lastName || '');
          if (nameA > nameB) {
            return 1;
          } else if (nameA < nameB) {
            return -1;
          }
          return 0;
        })
        .map((inquirer) => new ConfigItemOption(inquirer.displayInfo(), inquirer.pk()))
    ),
  ]);
}

export function newEventConfig(events: BEvent[], label = 'Event'): Config {
  return new Config(events, label, [
    new ConfigItem(
      '',
      '',
      'Search',
      events.map((e) => new ConfigItemOption(e.name, e.pk()))
    ),
  ]);
}

export function newBEventConfig(events: BEvent[], label = 'Event'): Config {
  return new Config(events, label, [
    new ConfigItem(
      '',
      '',
      'Search',
      events.map((e) => new ConfigItemOption(e.name, e))
    ),
  ]);
}

export function newTherapeuticAreaIdConfig(
  areas: BTherapeuticArea[],
  label = 'THERAPEUTIC_AREA'
): Config {
  return new Config(areas, label, [
    new ConfigItem(
      '',
      '',
      'Search',
      areas.map((a) => new ConfigItemOption(a.name, a.pk()))
    ),
  ]);
}

export function newTherapeuticAreaConfig(
  areas: BTherapeuticArea[],
  label = 'THERAPEUTIC_AREA'
): Config {
  return new Config(areas, label, [
    new ConfigItem(
      '',
      '',
      'Search',
      areas.map((a) => new ConfigItemOption(a.name, a.id))
    ),
  ]);
}

export function newTherapeuticAreaWithInactiveStatusLabelConfig(
  areas: BTherapeuticArea[],
  label = 'THERAPEUTIC_AREA'
): Config {
  const labelForInactiveArea = ' (status Inactive, due to lack of selected product)';
  return new Config(areas, label, [
    new ConfigItem(
      '',
      '',
      'Search',
      areas.map(
        (a) => new ConfigItemOption(`${a.name}${a.isActive ? '' : labelForInactiveArea}`, a.id)
      )
    ),
  ]);
}

export function newMedicalDocumentAudienceIdConfig(audiences: BMedicalDocumentAudience[]): Config {
  return new Config(audiences, 'TARGET_AUDIENCE', [
    new ConfigItem(
      '',
      '',
      'Search',
      audiences.map((a) => new ConfigItemOption(a.name, a.pk()))
    ),
  ]);
}

export function newMailboxConfig(mailboxes: BMailbox[]): Config {
  return new Config(mailboxes, 'MAILBOXES', [
    new ConfigItem(
      '',
      '',
      'Search',
      mailboxes.map((a) => new ConfigItemOption(a.name, a.id))
    ),
  ]);
}

export function newBUserBTeamConfig(users: BUser[], teams: BTeam[]): Config {
  return new Config([users, teams], 'Reassign_to', [
    new ConfigItem(
      'users',
      'people',
      'Search_people_here',
      users.map((u) => new ConfigItemOption(u.displayInfo(), u))
    ),
    new ConfigItem(
      'teams',
      'teams',
      'Search_team_here',
      teams.map((t) => new ConfigItemOption(t.name, t))
    ),
  ]);
}

export function newYesNoConfig(label = ''): Config {
  const options: { id: string; value: string }[] = [
    { id: 'yes', value: 'YES' },
    { id: 'no', value: 'NO' },
  ];
  return new Config(options, label, [
    new ConfigItem(
      '',
      '',
      'Search',
      options.map((a) => new ConfigItemOption(a.value, a.id))
    ),
  ]);
}

export function newSpecializationsConfig(
  specializations: BSpecialization[],
  label,
  headerLabel = 'Search'
): Config {
  return new Config(specializations, label, [
    new ConfigItem(
      '',
      '',
      headerLabel,
      specializations.map((c) => new ConfigItemOption(c.name, c.pk()))
    ),
  ]);
}

export function newChannelsConfig(
  channels: NameValue<string>[],
  label,
  headerLabel = 'Search'
): Config {
  return new Config(channels, label, [
    new ConfigItem(
      '',
      '',
      headerLabel,
      channels.map((c) => new ConfigItemOption(c.name, c.value))
    ),
  ]);
}

export function newFilterConfig(filters: FilterSelection[]): Config {
  return new Config(filters, 'SELECT_FIELDS', [
    new ConfigItem(
      '',
      '',
      'Search',
      filters.map((c) => new ConfigItemOption(c.name, c))
    ),
  ]);
}

export function newTerritoryConfig(alignments: BAlignment[], headerLabel = 'Search'): Config {
  return new Config(alignments, 'SELECT_FIELDS', [
    new ConfigItem(
      '',
      '',
      headerLabel,
      alignments.map((c) => new ConfigItemOption(c.name, c.pk()))
    ),
  ]);
}

export function newDepartmentModalCountryConfig(countries: IDataValue[]): Config {
  return new Config(countries, 'COUNTRY_PLEASE_SELECT_TEAM_FIRST', [
    new ConfigItem(
      '',
      '',
      'Search',
      countries.map((c) => new ConfigItemOption(c.name, c))
    ),
  ]);
}

export function newDepartmentModalTypeConfig(types: any[]): Config {
  return new Config(types, 'TYPE_DEPARTMENT', [
    new ConfigItem(
      '',
      '',
      'Search',
      types.map((i) => new ConfigItemOption(i.name, i.key))
    ),
  ]);
}

export function newDepartmentIdConfig(ids: any[]): Config {
  return new Config(ids, '', [
    new ConfigItem(
      '',
      '',
      'Search',
      ids.map((i) => new ConfigItemOption(i.value, i.id))
    ),
  ]);
}

export function newDepartmentNameConfig(names: string[]): Config {
  return new Config(names, '', [
    new ConfigItem(
      '',
      '',
      'Search',
      names.map((i) => new ConfigItemOption(i, i))
    ),
  ]);
}

export function newDepartmentEmailConfig(emails: string[]): Config {
  return new Config(emails, '', [
    new ConfigItem(
      '',
      '',
      'Search',
      emails.map((i) => new ConfigItemOption(i, i))
    ),
  ]);
}

export function newDepartmentCountryConfig(countries: string[]): Config {
  return new Config(countries, '', [
    new ConfigItem(
      '',
      '',
      'Search',
      countries.map((i) => new ConfigItemOption(i, i))
    ),
  ]);
}

export function newDepartmentCompanyConfig(companies: string[]): Config {
  return new Config(companies, '', [
    new ConfigItem(
      '',
      '',
      'Search',
      companies.map((i) => new ConfigItemOption(i, i))
    ),
  ]);
}

export function newDepartmentTeamConfig(teams: string[]): Config {
  return new Config(teams, '', [
    new ConfigItem(
      '',
      '',
      'Search',
      teams.map((i) => new ConfigItemOption(i, i))
    ),
  ]);
}

export function newDepartmentAreaConfig(areas: string[]): Config {
  return new Config(areas, '', [
    new ConfigItem(
      '',
      '',
      'Search',
      areas.map((i) => new ConfigItemOption(i, i))
    ),
  ]);
}

export function newDepartmentProductConfig(products: string[]): Config {
  return new Config(products, '', [
    new ConfigItem(
      '',
      '',
      'Search',
      products.map((i) => new ConfigItemOption(i, i))
    ),
  ]);
}

export function newColumnsConfig(columns: any[]): Config {
  const columnsMap = {
    id: 'ID',
    eventDatetime: 'Date',
    actionUser: 'Action by user',
    changedUser: 'Changed user',
    changedInquirer: 'Changed inquirer',
    action: 'Action',
    originalValue: 'Original value',
    newValue: 'New value',
    originalDetailValue: 'Original detail value',
    newDetailValue: 'New detail value',
  };
  return new Config(columns, '', [
    new ConfigItem(
      '',
      '',
      'Export columns',
      columns.map((c) => new ConfigItemOption(columnsMap[c.name], c.name))
    ),
  ]);
}

export function newAuditActionList(actions: string[]): Config {
  return new Config(actions, '', [
    new ConfigItem(
      '',
      '',
      'Filter by action',
      actions.map((action) => new ConfigItemOption(action, action))
    ),
  ]);
}

export function newCompanyConfig(companies: BCompany[], label: string = 'COMPANY'): Config {
  return new Config(companies, label, [
    new ConfigItem(
      '',
      '',
      'Search',
      companies.map((company) => new ConfigItemOption(company.name, company.id))
    ),
  ]);
}

export function newRegionConfig(regions: BRegion[], label: string = 'REGION'): Config {
  return new Config(regions, label, [
    new ConfigItem(
      '',
      '',
      'Search',
      regions.map((region) => new ConfigItemOption(region.name, region.id))
    ),
  ]);
}

export function newMDApprovalTypeConfig(
  mdApprovalTypes: BApprovalType[],
  label: string = ''
): Config {
  return new Config(mdApprovalTypes, label, [
    new ConfigItem(
      '',
      '',
      'Search',
      mdApprovalTypes.map(
        (mdApprovalType) => new ConfigItemOption(mdApprovalType.name, mdApprovalType.id)
      )
    ),
  ]);
}

export function newMDTypeConfig(mdTypes: BMedicalDocumentType[], label: string = ''): Config {
  return new Config(mdTypes, label, [
    new ConfigItem(
      '',
      '',
      'Search',
      mdTypes.map((mdType) => new ConfigItemOption(mdType.name, mdType.pk()))
    ),
  ]);
}

export function newMDChangeRequestedActionConfig(
  mdChangeRequestedActions: BChangeRequestedActionType[],
  label: string = ''
): Config {
  return new Config(mdChangeRequestedActions, label, [
    new ConfigItem(
      '',
      '',
      'Search',
      mdChangeRequestedActions.map(
        (mdChangeRequestedAction) =>
          new ConfigItemOption(mdChangeRequestedAction.name, mdChangeRequestedAction.id)
      )
    ),
  ]);
}

export function newMDApprovalPeriodConfig(
  mdApprovalPeriods: Array<{ id: number; name: string }>,
  label: string = ''
): Config {
  return new Config(mdApprovalPeriods, label, [
    new ConfigItem(
      '',
      '',
      'Search',
      mdApprovalPeriods.map(
        (mdApprovalPeriod) => new ConfigItemOption(mdApprovalPeriod.name, mdApprovalPeriod.id)
      )
    ),
  ]);
}

export function newFrequencyConfig(label: string = 'SELECT_FREQUENCY'): Config {
  return new Config(Object.values(FOLLOW_UP_FREQUENCY_MAPPER), label, [
    new ConfigItem(
      '',
      '',
      'Search',
      Object.keys(FOLLOW_UP_FREQUENCY_MAPPER).map(
        (frequency) =>
          new ConfigItemOption(capitalize(frequency), FOLLOW_UP_FREQUENCY_MAPPER[frequency])
      )
    ),
  ]);
}

export function newTaskConfig(tasks: BTask[], label = 'TASK', headerLabel = 'Search'): Config {
  return new Config(tasks, label, [
    new ConfigItem(
      '',
      '',
      headerLabel,
      tasks.map((c) => new ConfigItemOption(c.name, c.id))
    ),
  ]);
}
