import { base64ToPK, BaseModel, rProperty } from '../data-model';
import { BTeam } from '../user/user';

export class BSpamMessage extends BaseModel {
  @rProperty() id: string;
  @rProperty(Date) createdTs: Date;
  @rProperty() messageSubject: string;
  @rProperty() messageFromHeader: string;
  @rProperty() messageContent: string;
  @rProperty() teamName: string;

  team: BTeam;

  init() {}

  pk(): number {
    if (!this.id) {
      return null;
    }
    return base64ToPK(this.id);
  }

  static fromRest(json: any): BSpamMessage {
    return Object.assign(new BSpamMessage(), json);
  }

  static fromRestArray(json: any[]): BSpamMessage[] {
    return json && json.map((v) => BSpamMessage.fromRest(v));
  }
}
