import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FlatPickrOutputOptions } from 'angularx-flatpickr/lib/flatpickr.directive';
import { DateUtility } from 'app/common/date-utility';
import { DateRange, TimeZone } from 'app/common/types';
import { resetFocus } from 'app/common/util/dom';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
})
export class DateTimePickerComponent {
  @Input() set value(val: Date | DateRange | string) {
    // Emitted DateRange values are adjusted, in order to match day start / end in time zone.
    // If 2 way binding is used, the last emitted value is being returned and adjusted again,
    // which results in day of month changes due to the 2 adjustments.
    // To avoid this issue, the last emitted value is saved and subsequent adjustment is omitted.
    if (val !== this.lastEmittedValue) {
      this._value = val;
      this.adjustValueToTimeZone();
    }
  }
  @Input() set timeZone(val: TimeZone) {
    this._timeZone = val;
    this.adjustValueToTimeZone();
  }
  @Input() label: string;
  @Input() style: string = 'fs-10';
  @Input() altInputClass: string;
  @Input() minDate: string | Date;
  @Input() maxDate: string | Date;
  @Input() mode: 'range' | 'single' = 'range';
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() enableTime: boolean = false;
  @Input() enableReset: boolean = false;
  @Output() valueChange = new EventEmitter<Date | DateRange>();

  private _timeZone: TimeZone = 'Local';
  private _value: Date | DateRange | string;
  private lastEmittedValue: Date | DateRange;

  get value(): Date | DateRange | string {
    return this._value;
  }

  dateRangeChange(event: FlatPickrOutputOptions): void {
    if (this.mode === 'single') {
      this.emit((this._value = event.selectedDates[0]));
    } else {
      const range: DateRange = {
        from: event.selectedDates[0],
        to: event.selectedDates.length === 2 ? event.selectedDates[1] : event.selectedDates[0],
      };
      this._value = { from: range.from, to: range.to };
      if (range.from && range.to && !this.enableTime) {
        range.from = DateUtility.adjustTime(range.from, 'dayStart', this._timeZone);
        range.to = DateUtility.adjustTime(range.to, 'dayEnd', this._timeZone);
      }
      this.emit(range);
    }
    resetFocus(); // prevent date picker from reopening after any loading screen hides
  }

  private adjustValueToTimeZone(): void {
    const val: DateRange = <DateRange>this._value;
    if (val?.from instanceof Date && val?.to instanceof Date) {
      val.from = DateUtility.adjustDateToTimeZone(val.from, this._timeZone);
      val.to = DateUtility.adjustDateToTimeZone(val.to, this._timeZone);
    }
  }

  private emit(value: Date | DateRange): void {
    this.valueChange.emit((this.lastEmittedValue = value));
  }
}
