<div class="row">
  <div class="col-auto vertical-middle">{{ 'FILE' | translate }}:</div>
  <div class="col">
    <mat-form-field *ngIf="!attachment" class="mat-full-width form-field-wrapper">
      <input matInput placeholder="" [disabled]="true" />
    </mat-form-field>
    <mat-form-field
      class="mat-full-width form-field-wrapper"
      [ngClass]="attachment ? 'validField' : 'invalidField'"
    >
      <mat-label class="fileLabel">{{ 'URL' | translate }}</mat-label>
      <input
        matInput
        [(ngModel)]="externalUrl"
        (ngModelChange)="externalUrlChange.emit($event)"
        autocomplete="off"
        class="inputFile"
      />
    </mat-form-field>
    <app-link-visualization
      [onlyEventOnRemove]="true"
      [link]="attachment"
      [allowRemove]="allowRemove"
      *ngIf="attachment"
      (removeChange)="removeAttachment()"
    ></app-link-visualization>
  </div>
  <div class="col-auto vertical-middle">
    <input id="input-file" [disabled]="disabled" (change)="attachFile($event)" type="file" />
    <button mat-button class="medis-button-basic" [disabled]="disabled" (click)="simulateClick()">
      {{ 'UPLOAD' | translate }}
    </button>
  </div>
</div>
