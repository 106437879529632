<div class="documents-left-bar-view">
  <a
    [routerLink]="[managementUrl]"
    class="documents-nav-item"
    [class.isSelected]="isDocumentManagement"
  >
    <i class="fa fa-file"></i>
    {{ 'DOCUMENT_MANAGEMENT' | translate }}
  </a>
  <a
    *ngIf="shouldDisplayDocsApprovalsUrl"
    [routerLink]="[approvalsUrl]"
    class="documents-nav-item"
    [class.isSelected]="!isDocumentManagement"
  >
    <i class="fa fa-file"></i>
    {{ 'DOCUMENT_APPROVAL' | translate }}
  </a>
  <ng-container *ngIf="approvalsMenuVisible">
    <div class="side-menu-wrapper">
      <button mat-button class="btn create-document-btn" (click)="createNewDocument()">
        <i class="fa fa-file"></i>
        <span class="text-content"> {{ 'CREATE_NEW_DOCUMENT' | translate }} </span>
      </button>
      <mat-form-field
        class="search-box form-field-wrapper rounded-white w-100"
        [attr.data-testid]="'search-documents-wrapper'"
      >
        <i class="fas fa-search medis-icon-prefix" matPrefix></i>
        <mat-label class="search-label text-13">{{ 'SEARCH_DOCUMENTS' | translate }}</mat-label>
        <input
          matInput
          [(ngModel)]="filters.searchQuery"
          (keyup)="updateFilters()"
          [attr.data-testid]="'search-documents-input-field-button'"
        />
      </mat-form-field>
      <div class="approval-inbox">
        <i class="fa fa-inbox icon inbox-icon"></i>
        <span class="app-inbox-text">{{ 'APPROVAL_INBOX' | translate }}</span>
        <span class="document-count">{{ DOCUMENTS_COUNT.TOTAL }}</span>
      </div>

      <div class="filters">
        <div class="filters-header">
          <span class="aligned-right">
            <i class="fas fa-filter medis-icon filter-icon"></i>
            <span class="text-14-500-light">{{ 'FILTER_DOCUMENTS_BY' | translate }}</span>
          </span>
          <i
            class="fas fa-ban medis-icon"
            matTooltip="{{ 'RESET_FILTERS' | translate }}"
            (click)="resetFilters()"
          ></i>
        </div>
        <div class="dropdown-filters-container">
          <div class="statuses-container text-14-500-dark">
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title class="text-14-500-dark">
                  {{ 'Status' | translate }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-checkbox
                *ngFor="let status of availableStatuses"
                [checked]="filters.statuses.includes(status)"
                (change)="statusToggle($event, status)"
                ><span>{{ status | translate }}</span>
                <span class="document-count">{{ DOCUMENTS_COUNT[status] }}</span>
              </mat-checkbox>
            </mat-expansion-panel>
          </div>
          <app-search-select
            [multiple]="true"
            [config]="languageConfig"
            [(selectedValue)]="filters.languageIds"
            (selectedValueChange)="updateFilters()"
          ></app-search-select>
          <app-search-select
            [multiple]="true"
            [config]="categoryConfig"
            [(selectedValue)]="filters.categoryIds"
            (selectedValueChange)="updateFilters()"
          ></app-search-select>
          <app-search-select
            [multiple]="true"
            [config]="topicConfig"
            [(selectedValue)]="filters.topicIds"
            (selectedValueChange)="updateFilters()"
          ></app-search-select>
          <app-search-select
            [multiple]="true"
            [config]="medicalDocumentAudiencesConfig"
            [(selectedValue)]="filters.targetAudienceIds"
            (selectedValueChange)="updateFilters()"
          ></app-search-select>
        </div>
      </div>
    </div>
  </ng-container>
</div>
