import { Component, ViewEncapsulation } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MedisSidebarService } from '@mi-tool/components/sidebar/sidebar.service';
import { ColumnPreference } from 'app/modules/data-model/inquiries-table-preference/inquiries-table-preferences';
import { RightPanelService } from './right-panel.service';

@Component({
  selector: 'right-panel',
  templateUrl: './right-panel.component.html',
  styleUrls: ['./right-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RightPanelComponent {
  allColumns: ColumnPreference[];

  constructor(
    private medIsSidebarService: MedisSidebarService,
    private service: RightPanelService
  ) {
    service.screenColumns.subscribe((columns) => {
      this.allColumns = columns;
    });
  }

  modelChanged(): void {
    this.service.screenColumnsChange.next(this.allColumns);
  }

  drop(event: CdkDragDrop<ColumnPreference[]>): void {
    moveItemInArray(this.allColumns, event.previousIndex, event.currentIndex);
    this.modelChanged();
  }

  toggleRightSidebarFolded(): void {
    this.medIsSidebarService.getSidebar('rightPanel').toggleFold();
  }
}
