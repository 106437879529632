<h2 mat-dialog-title>
  {{ (isReject ? 'REASON_FOR_REJECTION' : 'INSERT_A_NOTE') | translate }}
  <app-reassign
    *ngIf="isNote"
    [label]="'ASSIGNED_TO'"
    [disabled]="interaction.isClosed() || (interaction.isInApproval() && !isCallCenter)"
    [mode]="'users'"
    [selectedItems]="[interaction]"
    [selectedUser]="interaction.assignedTo"
    [isDraft]="interaction.isNew()"
    [emitExternallyLandingAction]="true"
    (landingActionPerform)="onLandingActionPerformed($event)"
  ></app-reassign>
  <i class="far fa-times-circle medis-icon-large" mat-dialog-close></i>
</h2>
<mat-dialog-content>
  <div class="editor-container">
    <editor
      [(ngModel)]="noteText"
      [inline]="false"
      [init]="tinymceOptions"
      [id]="editorId"
      (onKeyUp)="reloadIsSaveBtnDisabled()"
    ></editor>
    <app-upload-attachment
      #uploadComponent
      [activeEditor]="activeEditor"
      (attachmentUploaded)="handleAttachmentUpload($event)"
    ></app-upload-attachment>
  </div>
  <div *ngFor="let link of attachments" class="attachment-container">
    <app-link-visualization
      [link]="link"
      [allowRemove]="true"
      (deleteAttachment)="handleAttachmentDeletion($event)"
    ></app-link-visualization>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-button
    class="medis-button-main"
    (click)="save()"
    [disabled]="isSaveBtnDisabled"
    [matTooltip]="'ENTER_TEXT_WITH_MIN_LEN_15_CHARS_OR_ATTACHMENT' | translate"
    [matTooltipDisabled]="!isSaveBtnDisabled"
  >
    {{ (isReject ? 'REJECT' : 'SAVE') | translate }}
  </button>
  <button mat-dialog-close mat-button class="medis-button-reject">
    {{ 'CANCEL' | translate }}
  </button>
</mat-dialog-actions>
