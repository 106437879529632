import { Component, HostBinding, Input } from '@angular/core';
import { MedisNavigationItem } from '@mi-tool/types';

@Component({
  selector: 'medis-nav-vertical-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss'],
})
export class MedisNavVerticalGroupComponent {
  @HostBinding('class') classes = 'nav-group nav-item';
  @Input() item: MedisNavigationItem;
  @Input() dataTestId?: string;
}
