import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Ajax } from 'app/common/ajax';
import { UrlResolverService } from 'app/common/url-resolver.service';
import { DateRange, Page } from 'app/common/types';
import { BInteraction } from 'app/modules/data-model/data-model.module';
import { Helpers } from '@mi-tool/utils/helpers';
import {
  FConfigs,
  filterConfigMapping,
  filterSelection,
  Query,
  Type,
} from '../inquiries-list/inquiries-filter/configs/filter-configs';
import { Operator, SearchOrigin } from '../../../common/common/enums/search.enum';
import * as _ from 'lodash';
import { FIELDS_TO_IGNORE } from '../../../common/common/constants/search.constants';
import { InquiriesStateService } from './inquiries-state.service';
import {
  STATUS_ALL_CLOSED,
  STATUS_ALL_OPEN,
} from '../../../common/common/constants/filter.constants';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { GenericDialogComponent } from 'app/common/common/generic-dialog/generic-dialog.component';

export type Action = 'set_status' | 'set_priority' | 'unassign' | 'assign_to' | 'change_team';
export type TResetFilters = '' | 'reset' | 'resetAndRedirect';

@Injectable({ providedIn: 'root' })
export class InquiriesService {
  resetAdvancedFilters$$ = new BehaviorSubject<boolean>(false);
  inquirySearch$$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  landingCommonData$$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  selectedRows$$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  updateResetFilters$ = new BehaviorSubject<boolean>(false);
  reloadTheDataTable$$: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(false);
  onFiltersReset$: BehaviorSubject<TResetFilters> = new BehaviorSubject<TResetFilters>('');
  onInteractionsChange$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  resultsLength$$: number = 1;

  private landingCommonCache: Ajax.CacheSubject<any>;

  private readonly URL_COMMON: string;
  private readonly URL_ACTION: string;
  private _apiSearch: boolean = false;

  constructor(
    private _http: HttpClient,
    private helperService: Helpers,
    resolver: UrlResolverService,
    private inquiriesStateService: InquiriesStateService,
    private matDialog: MatDialog
  ) {
    this.URL_COMMON = resolver.misApiUrlForPath('/landing/common/');
    this.URL_ACTION = resolver.misApiUrlForPath('/landing/action/');
  }

  getLandingCommon(): Observable<any> {
    if (!this.landingCommonCache) {
      this.landingCommonCache = new Ajax.CacheSubject<any>(this._http.get(this.URL_COMMON));
    }
    return this.landingCommonCache.get();
  }

  setLandingAction(action: Action, selection: number[], actionParams: object): Observable<any> {
    const body = { action: action, selection: selection, arguments: actionParams };
    return this._http.post(this.URL_ACTION, body).pipe(
      map((response) => {
        if (response && response['message']) {
          GenericDialogComponent.showMessage(this.matDialog, response['message']);
        }
        return response;
      })
    );
  }

  get apiSearch(): boolean {
    return this._apiSearch;
  }

  set apiSearch(value: boolean) {
    this._apiSearch = value;
  }

  getStatusDropdownTooltipText(interactions: BInteraction[]): string {
    for (const interaction of interactions) {
      if (interaction?.isClosed()) {
        return 'A_CLOSED_INTERACTION_IS_NOT_EDITABLE';
      } else if (interaction?.isInReview()) {
        return 'STATUS_DROPDOWN_TOOLTIP_TEXT_FOR_IN_REVIEW';
      } else if (interaction?.isInApproval()) {
        return 'STATUS_DROPDOWN_TOOLTIP_TEXT_FOR_IN_APPROVAL';
      }
    }
    return '';
  }

  getInlineEditingTooltipText(interactions: BInteraction[]): string {
    for (const interaction of interactions) {
      if (interaction?.isClosedOrInReview()) {
        return 'A_CLOSED_INTERACTION_IS_NOT_EDITABLE';
      }
    }
    return '';
  }
  getInlineMetadataDropdownsTooltipText(interactions: BInteraction[]): string {
    for (const interaction of interactions) {
      if (interaction?.isClosed()) {
        return 'A_CLOSED_INTERACTION_IS_NOT_EDITABLE';
      } else if (interaction?.hasMoreThanOneInquiry()) {
        return 'METADATA_DROPDOWNS_TOOLTIP_TEXT';
      }
    }
    return '';
  }

  /**
   * Parses the Advanced search object as Inbox filter
   * @returns InquiriesService.Params
   */
  getInquiryFiltersFromAdvanced(): InquiriesService.Params {
    const advancedSearchStr: string = localStorage.getItem('advancedSearch');
    const advancedSearch = advancedSearchStr
      ? JSON.parse(advancedSearchStr)
      : { advancedSearch: [] };

    const advancedSearchData = advancedSearch ? advancedSearch.advancedSearch : [];

    return this.getInquiryFiltersBasedOnAdvanced(advancedSearchData);
  }

  getInquiryFiltersBasedOnAdvanced(advancedSearch = []): InquiriesService.Params {
    let result: InquiriesService.Params = {};

    if (advancedSearch && advancedSearch.length) {
      advancedSearch.forEach((searchItem) => {
        const filter = filterSelection.find((selection) => {
          return selection.selector === searchItem.field;
        });
        if (filter && filter.type === Type.DROPDOWN) {
          result[searchItem.field] = _.isArray(result[searchItem.field])
            ? result[searchItem.field].concat(searchItem.value)
            : (result[searchItem.field] = searchItem.value);
        } else if (filter && filter.type === Type.DATE) {
          result[searchItem.field] = this.helperService.getDateObject(searchItem.value);
        } else {
          result[searchItem.field] = searchItem.value;
        }
      });
    }

    return result;
  }

  /**
   * Parses the Inbox filter as Advanced search
   * @param filters InquiriesService.Params
   */
  getInquiryFiltersToAdvanced(
    filters: InquiriesService.Params,
    filtersDisplayValues: { [name: string]: string } = {},
    forPreview = false,
    currentUrl: string = ''
  ): any {
    filters = filters || {};

    // Need the basic structure so we default to the minimum required object
    // Currently there is a dumb logic in the inquiries-filter.component.ts that messes up with the correct type
    const advancedSearchStr: string = localStorage.getItem('advancedSearch');
    let advancedSearch = advancedSearchStr
      ? JSON.parse(advancedSearchStr)
      : { advancedSearch: [], exportedColumns: {} };

    // Remove status submitted for approval if added from left menu.
    if (currentUrl != InquiriesService.LEFT_MENU_URLS.TEAM_PENDING) {
      const filterIndex = advancedSearch.advancedSearch.findIndex(
        (filter) => filter.origin && filter.origin == SearchOrigin.LeftMenu
      );
      if (filterIndex > -1) {
        const indexOfSubmittedForApproval = filters.status.indexOf('Submitted for Approval');
        filters.status.splice(indexOfSubmittedForApproval, 1);
        advancedSearch.advancedSearch.splice(filterIndex, 1);
      }
    }

    if (Object.keys(filters).length) {
      let filtersToAdvanced = [];

      Object.keys(filters).forEach((filterKey) => {
        const filter = filterSelection.find((selection) => {
          return selection.selector === filterKey;
        });

        const filterType = filter && filter.type;
        const filterConfig = filterConfigMapping[filterKey];

        const existingElemIndex = _.findLastIndex(advancedSearch.advancedSearch, {
          field: filterKey,
        });
        // The Inbox filters are using the latest filter from a type with the exclusion of the Dropdown type.
        // The dropdown type combines all the filters in one for the Inbox filters
        let advancedSearchElemInUse =
          existingElemIndex > -1 ? advancedSearch.advancedSearch[existingElemIndex] : null;

        const advancedSearchJoin = advancedSearchElemInUse
          ? advancedSearchElemInUse.join || Operator.And
          : Operator.And;
        let { value, displayTerm } = this.helperService.transformFilter(
          filters[filterKey],
          filterType,
          filterConfig
        );

        const newFilter = {
          join: advancedSearchJoin,
          field: filterKey,
          displayTerm:
            filterType === Type.DATE
              ? displayTerm || filtersDisplayValues[filterKey]
              : filtersDisplayValues[filterKey] || filters[filterKey],
          value: !forPreview && filterType === Type.DATE ? value : filters[filterKey],
        };

        if (advancedSearchElemInUse) {
          // Update the old filters
          switch (filterType) {
            case Type.DROPDOWN:
              let otherUserSelections = Array.isArray(newFilter.value)
                ? [...newFilter.value]
                : [newFilter.value];

              advancedSearch.advancedSearch.forEach((filter) => {
                if (filter.value && filter.field === filterKey) {
                  // Remove the removed by the user options from LS
                  filter.value = Array.isArray(filter.value) ? filter.value : [filter.value];
                  filter.value = filter.value.filter((filterVal, index) => {
                    const valPosition = newFilter.value.indexOf(filterVal);
                    return valPosition > -1;
                  });
                  filter.displayTerm =
                    this.inquiriesStateService.getDropdownDisplayTerm(filter.field, filter.value) ||
                    filter.displayTerm;

                  otherUserSelections = Array.isArray(otherUserSelections)
                    ? otherUserSelections
                    : [otherUserSelections];
                  otherUserSelections = otherUserSelections.filter((oVal, index) => {
                    return filter.value.indexOf(oVal) === -1;
                  });
                }
              });

              if (otherUserSelections.length) {
                // IF we still have options that are missing in LS - save it as a new item
                const inboxFilterIndex = _.findLastIndex(advancedSearch.advancedSearch, {
                  field: filterKey,
                  origin: SearchOrigin.Inbox,
                });

                const addingFilterStatusForPendingApproval =
                  filterKey == FConfigs.STATUS &&
                  otherUserSelections.includes('Submitted for Approval') &&
                  currentUrl == InquiriesService.LEFT_MENU_URLS.TEAM_PENDING;

                if (inboxFilterIndex > -1 && !addingFilterStatusForPendingApproval) {
                  advancedSearch.advancedSearch[inboxFilterIndex].value = [
                    ...advancedSearch.advancedSearch[inboxFilterIndex].value,
                    ...otherUserSelections,
                  ];
                  const filterByIndex = advancedSearch.advancedSearch[inboxFilterIndex];
                  filterByIndex.displayTerm =
                    this.inquiriesStateService.getDropdownDisplayTerm(
                      filterByIndex.field,
                      filterByIndex.value
                    ) || filterByIndex.displayTerm;
                } else {
                  const filterDisplayTerm = this.inquiriesStateService.getDropdownDisplayTerm(
                    filterKey,
                    otherUserSelections
                  );
                  filtersToAdvanced.push({
                    join: addingFilterStatusForPendingApproval ? Operator.OR : Operator.And,
                    field: filterKey,
                    displayTerm: filterDisplayTerm,
                    value: otherUserSelections,
                    origin: addingFilterStatusForPendingApproval
                      ? SearchOrigin.LeftMenu
                      : SearchOrigin.Inbox,
                  });
                }
              }
              break;
            default:
              // Date, text, enum and etc.
              advancedSearch.advancedSearch[existingElemIndex] = { ...newFilter };
              break;
          }
        } else {
          // New filters from the inbox filters
          let filterOrigin = SearchOrigin.Inbox;
          if (
            newFilter.field == FConfigs.STATUS &&
            currentUrl === InquiriesService.LEFT_MENU_URLS.TEAM_PENDING
          ) {
            filterOrigin = SearchOrigin.LeftMenu;
          }
          filtersToAdvanced.push({ ...newFilter, origin: filterOrigin });
        }
      });

      const newAdvancedFilters = [...advancedSearch.advancedSearch, ...filtersToAdvanced]
        .filter((asObject) => {
          // Filter out the fields that work in the background
          return FIELDS_TO_IGNORE.indexOf(asObject.field) === -1;
        })
        .filter((asObject) => {
          // Filter out the empty values
          return (
            Number.isInteger(asObject.value) ||
            (asObject.value && asObject.value.length) ||
            (asObject.value && Object.keys(asObject.value).length)
          );
        });

      advancedSearch.advancedSearch = [
        ...newAdvancedFilters,
        ...this.addAdditionalFilters(filters),
      ];
      advancedSearch.exportedColumns = this.getExportColumns(advancedSearch);
    }

    return advancedSearch;
  }

  private addAdditionalFilters(inquiryFilter: InquiriesService.Params): any {
    let result = [];
    inquiryFilter.status = inquiryFilter.status || [];
    const hasAssignedToMeFlag = InquiriesService.PERSONAL_MODES.includes(inquiryFilter.mode);
    const hasMyTeamsFlag = InquiriesService.TEAM_MODES.includes(inquiryFilter.mode);
    const hasOpenFlag =
      InquiriesService.OPENED_MODES.includes(inquiryFilter.mode) ||
      inquiryFilter.status.includes(STATUS_ALL_OPEN);
    const hasClosedFlag =
      InquiriesService.CLOSED_MODES.includes(inquiryFilter.mode) ||
      inquiryFilter.status.includes(STATUS_ALL_CLOSED);
    const stateId =
      hasOpenFlag && !inquiryFilter.status.includes(STATUS_ALL_CLOSED)
        ? [1, 3]
        : hasClosedFlag
        ? [2]
        : undefined;

    if (stateId) {
      result.push({
        join: Operator.And,
        field: 'state_id',
        displayTerm: '',
        value: stateId,
      });
    }

    if (hasAssignedToMeFlag) {
      result.push({
        join: Operator.And,
        field: 'assigned_to_me',
        displayTerm: '',
        value: 1,
      });
    }

    if (hasMyTeamsFlag) {
      result.push({
        join: Operator.And,
        field: 'my_teams',
        displayTerm: '',
        value: 1,
      });
    }

    return result;
  }

  getExportColumns(advancedSearchObj): { [name: string]: boolean } {
    if (
      advancedSearchObj.advancedSearch.filter(
        (filter) => FIELDS_TO_IGNORE.indexOf(filter.field) === -1
      ).length === 0 &&
      Object.keys(advancedSearchObj.exportedColumns || {}).length > 0
    ) {
      return {};
    }
    return advancedSearchObj.exportedColumns || {};
  }

  adaptExportColumnsForExport(names: { [name: string]: boolean }): { [name: string]: boolean } {
    let result = { ...names };

    if (result[FConfigs.SELECT_ALL]) {
      delete result[FConfigs.SELECT_ALL];
    }
    if (result[FConfigs.ADR]) {
      result[FConfigs.ADR_REF_ID] = true;
    }
    if (result[FConfigs.PQC]) {
      result[FConfigs.PQC_REF_ID] = true;
    }

    return result;
  }

  getAdaptedDateFilters(filters: Query[]): Query[] {
    (filters || []).forEach((filter) => {
      const filterConfig = filterSelection.find((selection) => {
        return filter.field === selection.selector;
      });

      const filterType = filterConfig && filterConfig.type;
      if (filterType === Type.DATE) {
        const { value } = this.helperService.transformFilter(filter.value, Type.DATE, null);
        filter.value = value;
      }
    });
    return filters;
  }
}

export namespace InquiriesService {
  export type Params = {
    pageSize?: number;
    page?: number;
    orderBy?: string;
    id?: number[];
    inquirer?: number[];
    assigned_to?: number[];
    inserted_by?: number[];
    edited_by?: number[];
    product?: number[];
    topic?: number[];
    category?: number[];
    priority?: string;
    status?: string[];
    team?: string[];
    event?: number[];
    due_date?: DateRange;
    created_on?: DateRange;
    edited_on?: DateRange;
    mode?: FilterMode;
  };

  export enum FilterMode {
    ASSIGNED_TO_ME,
    MY_OPEN,
    MY_CLOSED,
    TEAM_OPEN,
    TEAM_CLOSED,
    TEAM_ALL,
    TEAM_PENDING,
    ALL_OPEN,
    ALL_CLOSED,
  }

  export enum LEFT_MENU_URLS {
    ASSIGNED_TO_ME = '/inq/assigned-to-me',
    MY_OPEN = '/inq/my-open-inquiries',
    MY_CLOSED = '/inq/my-closed-inquiries',
    TEAM_OPEN = '/inq/my-teams-open-inq',
    TEAM_CLOSED = '/inq/my-teams-closed-inq',
    TEAM_ALL = '/inq/all-inq-for-my-team',
    TEAM_PENDING = '/inq/pending-approval',
    ALL_OPEN = '/inq/all-teams-open-inq',
    ALL_CLOSED = '/inq/all-teams-closed-inq',
    ALL_INQ_ALL_TEAMS = '/inq/all-inq-for-all-team',
  }

  export const LEFT_MENU_MODES = {
    '/inq/assigned-to-me': FilterMode.ASSIGNED_TO_ME,
    '/inq/my-open-inquiries': FilterMode.MY_OPEN,
    '/inq/my-closed-inquiries': FilterMode.MY_CLOSED,
    '/inq/my-teams-open-inq': FilterMode.TEAM_OPEN,
    '/inq/my-teams-closed-inq': FilterMode.TEAM_CLOSED,
    '/inq/all-inq-for-my-team': FilterMode.TEAM_ALL,
    '/inq/pending-approval': FilterMode.TEAM_PENDING,
    '/inq/all-teams-open-inq': FilterMode.ALL_OPEN,
    '/inq/all-teams-closed-inq': FilterMode.ALL_CLOSED,
  };

  export const ALL_LEFT_MENU_MODES = {
    ...LEFT_MENU_MODES,
    '/inq/all-inq-for-all-team': 9,
  };

  export const PERSONAL_MODES = [
    FilterMode.ASSIGNED_TO_ME,
    FilterMode.MY_OPEN,
    FilterMode.MY_CLOSED,
  ];
  export const TEAM_MODES = [
    FilterMode.TEAM_ALL,
    FilterMode.TEAM_CLOSED,
    FilterMode.TEAM_OPEN,
    FilterMode.TEAM_PENDING,
  ];
  export const CLOSED_MODES = [FilterMode.ALL_CLOSED, FilterMode.TEAM_CLOSED, FilterMode.MY_CLOSED];
  export const OPENED_MODES = [FilterMode.ALL_OPEN, FilterMode.TEAM_OPEN, FilterMode.MY_OPEN];

  export type Response = Page<Entry> & {
    [key: string]: any;
  };

  export type Entry = {
    _object: any;
    landingInteraction: BInteraction;
    hovered: boolean;
  };
}
