@for (aff of affiliations; track aff.id; let last = $last) {
<app-search-select
  [config]="teamConfig"
  [selectedValue]="aff.teamId"
  (selectedValueChange)="aff.teamId = $event; onChange()"
  [disabled]="disabled"
  [required]="required && !hasFullyDefinedAffiliation"
></app-search-select>
<div class="d-flex align-items-center">
  <app-search-select
    class="flex-grow-1"
    [config]="roleConfig"
    [selectedValue]="aff.roleIds"
    (selectedValueChange)="aff.roleIds = $event; onChange()"
    [multiple]="true"
    [disabled]="disabled"
    [required]="required && !hasFullyDefinedAffiliation"
  ></app-search-select>
  @if (!disabled) { @if (last) {
  <i class="fa fa-plus medis-icon" (click)="addAffiliation()"></i>
  } @else {
  <i class="fa fa-trash medis-icon-reject" (click)="removeAffiliation(aff.id)"></i>
  } }
</div>
}
