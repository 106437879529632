import { FollowUpFrequencyType } from 'app/common/follow-up-service/follow-up.service';

export const urlSearchParamsToObject = (
  urlSearchParams?: string
): Record<string, string> | null => {
  if (!urlSearchParams) {
    return {};
  }

  try {
    const result = Object.fromEntries(new URLSearchParams(window.location.search));
    return result;
  } catch (err) {
    return {};
  }
};

export const FOLLOW_UP_FREQUENCY_MAPPER: Record<
  'DAYS' | 'WEEKS' | 'MONTHS',
  FollowUpFrequencyType
> = {
  DAYS: 1,
  WEEKS: 2,
  MONTHS: 3,
};
