import { base64ToPK, BaseModel, parseArray, rProperty } from '../data-model';
import { gql } from 'apollo-angular';
import { BTopic } from '../topic/topic';

export class BCategory extends BaseModel {
  @rProperty() id: string;
  @rProperty() name: string;
  @rProperty() type: string;
  @rProperty() isActive: boolean;
  @rProperty() importedId: string;
  @rProperty() importedSource: string;
  @rProperty(Date) importedTs: string;

  topics: BTopic[];

  constructor(json: any) {
    super(json);
    this.init(json);
  }

  init(json: any) {
    this.topics = parseArray<BTopic>(json, BTopic, 'topics');
  }

  static fromRest(json: any): BCategory {
    if (json) {
      json.topics = BTopic.fromRestArray(json.topics);
      return Object.assign(new BCategory({}), json);
    }
    return json;
  }

  static fromRestArray(json: any[]): BCategory[] {
    return json && json.map((v) => BCategory.fromRest(v));
  }

  getMId() {
    return base64ToPK(this.id);
  }

  getMName() {
    return this.name;
  }
}

export const fragment = gql`
  fragment categoryFragment on CategoryNode {
    id
    name
    type
    isActive
  }
`;

export const fragmentConnection = gql`
  fragment categoryConnectionFragment on CategoryNodeConnection {
    edges {
      node {
        ...categoryFragment
      }
    }
  }
  ${fragment}
`;
