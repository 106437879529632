import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { environment } from 'app/../environments/environment';
import { RollbarHandler } from 'app/common/rollbar-handler';

@Injectable({ providedIn: 'root' })
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: Error | HttpErrorResponse) {
    if (error instanceof HttpErrorResponse && error.headers.has('skipRollbar')) {
      return;
    }

    const message = this.resolveMessage(error);
    this.showError(message);
    // Always log errors
    console.log('LoggingService: ' + message);
    console.error(error);
    RollbarHandler.error(error);
  }

  private resolveMessage(error: Error | HttpErrorResponse): string {
    if (error instanceof HttpErrorResponse) {
      return navigator.onLine ? error.message : 'No Internet Connection';
    }
    return error.message ? error.message : error.toString();
  }

  private showError(_error: string): void {
    if (['test', 'prod'].includes(environment.name)) {
      return;
    }
    const defaultMessage = 'Error occurred and has been submitted to support team.';
    const defaultAction = 'Close';
    const config = new MatSnackBarConfig();
    config.verticalPosition = 'top';
    config.duration = 0;
    config.panelClass = ['red-snackbar'];

    this.injector.get(NgZone).run(() => {
      // Had an issue with the snackbar being ran outside of angular's zone.
      const snackbar = this.injector.get(MatSnackBar).open(defaultMessage, defaultAction, config);
      snackbar.onAction().subscribe(() => {
        snackbar.dismiss();
      });
    });
  }
}
