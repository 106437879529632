<h2 mat-dialog-title>{{'SHARE_SEARCH' | translate}}</h2>

<mat-dialog-content class="filter-modal flex-column">
  <mat-chip-grid #chipList>
    <mat-chip-row
      class="ps-0"
      *ngFor="let user of selectedUsers; let i = index;"
      [selectable]="false"
      [removable]="true"
      (removed)="remove(i)"
    >
      <div class="user-chip-label">
        <i class="fa fa-user me-2 rounded-circle text-16 mat-azure"></i>
        <span class="text-15" *ngIf="user.first_name">{{user.first_name}} - {{user.email}}</span>
        <span class="text-15" *ngIf="!user.first_name">{{user.email}}</span>
      </div>
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip-row>
  </mat-chip-grid>
  <mat-form-field class="form-field-wrapper" [ngClass]="shareError ? 'invalid' : ''">
    <input
      matInput
      autocomplete="off"
      type="email"
      [placeholder]="'ENTER_RECIPIENTS' | translate"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="true"
      [(ngModel)]="shareValue.val"
      [ngbPopover]="popContent"
      triggers="manual"
      container="body"
      popoverClass="share-search-list"
      #pop="ngbPopover"
      placement="bottom"
    />
  </mat-form-field>
  <mat-error *ngIf="shareError" class="text-start">{{'USER_NOT_FOUND' | translate}}</mat-error>
</mat-dialog-content>

<mat-dialog-actions>
  <button
    mat-button
    class="medis-button-main"
    [disabled]="selectedUsers.length < 1"
    (click)="onShare()"
  >
    <i class="fa fa-share-alt"></i>
    {{ 'SHARE' | translate }}
  </button>
  <button
    mat-button
    class="medis-button-basic"
    (click)="dialogRef.close({type: filterConfig.CLOSE})"
  >
    {{ 'CANCEL' | translate }}
  </button>
</mat-dialog-actions>

<ng-template #popContent>
  <mat-list *ngIf="listUsers.length > 0">
    <mat-list-item *ngFor="let user of listUsers" (click)="setCurrentUser(user)" class="h-100 p-2">
      <div class="d-flex align-items-center">
        <i class="fa fa-user me-2 rounded-circle text-16 mat-azure" aria-hidden="true"></i>
        <span class="ms-1 text-15" *ngIf="user.first_name">
          {{user.first_name}} <br />
          {{user.email}}
        </span>
        <span class="ms-1 text-15" *ngIf="!user.first_name">{{user.email}}</span>
      </div>
    </mat-list-item>
  </mat-list>
</ng-template>
