import { IAuthModel } from '../models';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import {
  filterSelection,
  Type,
} from 'app/modules/inquiries/inquiries-list/inquiries-filter/configs/filter-configs';
import { Config } from 'app/common/common/search-select/search-select.config';
import moment from 'moment';
import { isString, zipObject } from 'lodash';
import { InquirySearch } from 'app/modules/data-model/inquiry/inquiry';
import { EAuthType } from '../enums';
import {
  ICursorPaginationData,
  TCursorPaginationParams,
} from 'app/modules/data-model/data-service';
import { DateUtility } from 'app/common/date-utility';

export namespace Helpers {
  export type View =
    | 'inquiriesView'
    | 'userProfileView'
    | 'userProfileAdminView'
    | 'inquiryDetailView'
    | 'teamsView'
    | 'authRequestsView'
    | 'userAdminView'
    | 'userSyncView'
    | 'hcpAdministration'
    | 'documentsView'
    | 'unsupportedView'
    | '';
}

@Injectable()
export class Helpers {
  layoutDependsOnRouterEvent$$ = new BehaviorSubject<Helpers.View>('');

  constructor(private translate: TranslateService, private datePipe: DatePipe, router: Router) {
    router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((route) => {
      const url = (route as NavigationEnd).urlAfterRedirects;
      if (url.includes('/inq/') || url.includes('/drafts') || url.includes('/spam/list')) {
        this.layoutDependsOnRouterEvent$$.next('inquiriesView');
      } else if (url.includes('/profile/')) {
        this.layoutDependsOnRouterEvent$$.next('userProfileView');
      } else if (url.includes('/administration/user/')) {
        this.layoutDependsOnRouterEvent$$.next('userProfileAdminView');
      } else if (url.startsWith('/new/') || url.startsWith('/detail/')) {
        this.layoutDependsOnRouterEvent$$.next('inquiryDetailView');
      } else if (url.startsWith('/administration/teams')) {
        this.layoutDependsOnRouterEvent$$.next('teamsView');
      } else if (url.startsWith('/administration/auth-requests')) {
        this.layoutDependsOnRouterEvent$$.next('authRequestsView');
      } else if (url.startsWith('/administration/syncs/users')) {
        this.layoutDependsOnRouterEvent$$.next('userSyncView');
      } else if (url.startsWith('/administration/users')) {
        this.layoutDependsOnRouterEvent$$.next('userAdminView');
      } else if (
        url.startsWith('/administration/hcps') ||
        url.startsWith('/administration/specializations')
      ) {
        this.layoutDependsOnRouterEvent$$.next('hcpAdministration');
      } else if (url.startsWith('/repository/')) {
        this.layoutDependsOnRouterEvent$$.next('documentsView');
      } else {
        this.layoutDependsOnRouterEvent$$.next('unsupportedView');
      }
    });
  }

  public typeSpecificMsg(
    request: IAuthModel,
    type: string,
    hasMoreThanOneRole: boolean = false
  ): string {
    const msg = `${this.translate.instant('requestReceivedFrom')}
                ${request.requestedBy} ${this.translate.instant('on')}
                ${this.datePipe.transform(request.date, 'd.MMM.y')},
                ${this.translate.instant('at')}
                ${this.datePipe.transform(request.date, 'hh:mm a')},
                ${this.translate.instant('to')} `;
    let additionalContent = '';
    if (request.type.includes('new') && request.type !== EAuthType.NewUser) {
      additionalContent = ` ${this.translate.instant('add')} ${this.translate.instant(
        request.type
      )}`;
    } else if (request.type === EAuthType.NewUser) {
      if (hasMoreThanOneRole) {
        let roleAndTeamPairs = '';
        for (let index = 0; index < request.role.length; index++) {
          roleAndTeamPairs += `${request.team[index]} - ${request.role[index]}${
            index === request.role.length - 1 ? '' : ', '
          }`;
        }
        additionalContent = ` ${this.translate.instant(type)}
            ${this.translate.instant('newUser')}
            ${this.translate.instant('WITH_THE_FOLLOWING_PAIRS')}
            ${roleAndTeamPairs}.`;
      } else {
        additionalContent = ` ${this.translate.instant(type)}
            ${this.translate.instant('newUser')}
            ${this.translate.instant('withTheFollowingRole')}
            ${request.role}
            ${this.translate.instant('forTheFollowingTeam')}
            ${request.team}.`;
      }
    } else {
      additionalContent = this.translate.instant(request.type);
    }
    return msg + additionalContent;
  }

  transformFilter(
    initialValue: any,
    type: Type,
    config: Config
  ): { displayTerm: string; value: any[] | string } {
    let displayTerm = '';
    let value: any[] | string = '';
    if (initialValue) {
      if (type === Type.TEXT || type === Type.NUMBER) {
        value = displayTerm = initialValue;
      } else if (type === Type.DATE) {
        let tempValue = this.getDateObject(initialValue);
        value = `${DateUtility.adjustTimeStr(tempValue.from, 'dayStart')} ${
          tempValue.to ? 'to' : ''
        } ${tempValue.to ? DateUtility.adjustTimeStr(tempValue.to, 'dayEnd') : ''}`;
        displayTerm = `${moment(tempValue.from).format('ll')} ${tempValue.to ? 'to' : ''} ${
          tempValue.to ? moment(tempValue.to).endOf('day').format('ll') : ''
        }`;
      } else {
        if (config && config.items && config.items.length) {
          const listItem = config.items[0];
          const listItems = listItem.options ? listItem.options : [];
          if (!(typeof initialValue === 'string') && !(typeof initialValue === 'number')) {
            const valueSearch: any[] = initialValue;
            if (listItems) {
              const userFilters = listItems.filter(
                (option) => valueSearch.filter((val) => option.value === val).length
              );
              const userItems = userFilters.map((list) => list.name);
              value = userFilters.map((list) => list.value);
              displayTerm = userItems.join(',');
            }
          } else if (initialValue === 'yes' || initialValue === 'no') {
            value = displayTerm = this.translate.instant(
              `${initialValue === 'yes' ? 'YES' : 'NO'}`
            );
          }
        }
      }
    }
    return { value, displayTerm };
  }

  getDateObject(val): { from?: string; to?: string } {
    let result = {};
    if (isString(val)) {
      const splittedStrVal = val.split(' to ');
      result['from'] = splittedStrVal[0];
      result['to'] = splittedStrVal[1];
    } else {
      result = { ...val };
    }

    return result;
  }

  getAllSavedFilters(listFilters: InquirySearch[]): InquirySearch[] {
    let allColumns = zipObject(
      filterSelection.map((c) => c.selector),
      filterSelection.map((c) => true)
    );

    return listFilters.map((filter) => {
      if (Object.keys(filter.exportedColumns).length == 0) {
        filter.exportedColumns = allColumns;
      }
      return filter;
    });
  }

  arrayOfItemsToText(array: string[], addPeriodAtTheEnd = true): string {
    const translatedAnd = ` ${this.translate.instant('OPTION_AND').toLowerCase()} `;
    return `${
      array.length < 3
        ? array.join(translatedAnd)
        : array.slice(0, -1).join(', ') + translatedAnd + array[array.length - 1]
    }${addPeriodAtTheEnd ? '.' : ''}`;
  }

  getCursorPaginationParamsAndPageIndex(
    cursorPaginationData: ICursorPaginationData,
    pageIndex: number,
    pageSize: number,
    paginateForward: boolean,
    resetPageIndex: boolean
  ) {
    let cursorPaginationParams: TCursorPaginationParams | {} = {};
    if (!cursorPaginationData || paginateForward || resetPageIndex) {
      if (resetPageIndex) {
        pageIndex = 0;
      }
      cursorPaginationParams['first'] = pageSize;
      cursorPaginationParams['after'] =
        paginateForward && !resetPageIndex ? cursorPaginationData.endCursor : `${pageIndex}`;
    } else {
      // Paginate backward
      cursorPaginationParams['last'] = pageSize;
      cursorPaginationParams['before'] = cursorPaginationData.startCursor;
    }
    return { cursorPaginationParams, pageIndex };
  }

  scrollToTop(): void {
    setTimeout(() => {
      document
        .getElementById('topDiv')
        ?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }, 500);
  }

  trimmedStringOrNull(str: string): string | null {
    /**
     * Trims the given string and returns it if it is not empty, otherwise returns null.
     *
     * @param str - The string to be trimmed and checked.
     * @returns The trimmed string or null if it is empty.
     */
    return str?.trim() ? str.trim() : null;
  }

  arrayToStringOrNull(array: string[] | number[], separator: string = ','): string | null {
    /**
     * Converts an array of strings or numbers to a single string separated by the specified separator, or returns null if the array is empty.
     *
     * @param array - The array to be converted to a string.
     * @param separator - The separator to be used between array elements in the resulting string. Default is ','.
     * @returns A string representation of the array elements separated by the specified separator, or null if the array is empty.
     */
    return array?.length ? array.join(separator) : null;
  }

  translateMany(keys: string[]): string[] {
    return keys.map((k) => this.translate.instant(k));
  }
}
