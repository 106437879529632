import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BTopic } from './topic';
import { HttpClient } from '@angular/common/http';
import { UrlResolverService } from 'app/common/url-resolver.service';
import { SimplePage } from 'app/common/types';
import { Ajax } from 'app/common/ajax';
import { map } from 'rxjs/operators';
import { isNil, omitBy } from 'lodash';

export type TTopicData = {
  id: string;
  name: string;
  type: string;
  isActive: boolean;
  isProductSpecific: boolean;
  categoryId: string;
  productIds: string[];
};

export type TTopicDialogData = {
  topic: BTopic;
};

export type TTopicUpdateResponse = {
  message?: { [key: string]: string };
  hasMetadataDeactivation?: boolean;
};

export type TTopicParams = {
  pageIndex: number;
  pageSize: number;
  orderBy?: string;
  id?: number;
  name?: string;
  type?: string;
  isActive?: string;
  isProductSpecific?: string;
  categoryIds?: string;
  productIds?: string;
};

@Injectable({ providedIn: 'root' })
export class TopicRestService {
  private readonly URL: string;

  constructor(private http: HttpClient, urlResolver: UrlResolverService) {
    this.URL = urlResolver.misApiUrlForPath('/metadata/topics');
  }

  fetchTopics(params: TTopicParams): Observable<SimplePage<BTopic>> {
    return this.http
      .get<SimplePage<BTopic>>(this.URL, {
        ...Ajax.X_NO_LOADING_OPTIONS,
        params: omitBy(params, isNil),
      })
      .pipe(
        map((response) => {
          return { ...response, records: BTopic.fromRestArray(response.records) };
        })
      );
  }

  edit(data: TTopicData): Observable<TTopicUpdateResponse> {
    return this.http.put<TTopicUpdateResponse>(this.URL, omitBy(data, isNil));
  }

  create(data: TTopicData): Observable<void> {
    return this.http.post<void>(this.URL, omitBy(data, isNil));
  }
}

@Injectable({
  providedIn: 'root',
})
export class CreateTopicSuggestionService {
  private readonly URL: string;

  constructor(private http: HttpClient, _urlResolverService: UrlResolverService) {
    this.URL = _urlResolverService.misApiUrlForPath('/users/category-topic/suggestion');
  }

  add(suggestion: TopicCategorySuggestion): Observable<TopicCategorySuggestionResponse> {
    return this.http.post<TopicCategorySuggestionResponse>(this.URL, suggestion);
  }
}

type TopicCategorySuggestionBase = {
  category: string;
  topic: string;
  products?: number[];
};

export type TopicCategorySuggestion = TopicCategorySuggestionBase & {
  interactionId: number;
  inquiryId: number;
  isDraft: boolean;
};

export type TopicCategorySuggestionResponse = TopicCategorySuggestionBase & {
  inquiry_id: number;
};
