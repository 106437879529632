import { Component, HostBinding, Input } from '@angular/core';
import { MedisNavigationItem } from '@mi-tool/types';
import { MedisSidebarService } from '@mi-tool/components/sidebar/sidebar.service';

@Component({
  selector: 'medis-nav-vertical-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
})
export class MedisNavVerticalItemComponent {
  @HostBinding('class') classes = 'nav-item';
  @Input() item: MedisNavigationItem;
  @Input() dataTestId?: string;

  constructor(public navigation: MedisSidebarService) {}
}
