import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NavbarVerticalStyle1Component } from './app-navbar.component';
import { CommonModule } from '@angular/common';
import { MedisNavigationModule } from '@mi-tool/components';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { NavigationService } from './navigation.service';
import { DetailLeftBarComponent } from '../../../detail-left-bar/detail-left-bar.component';
import { AppCommonModule } from 'app/common/common/common.module';
import { HistoryVisualizationComponent } from '../../../history-visualization/history-visualization.component';

@NgModule({
  declarations: [
    NavbarVerticalStyle1Component,
    DetailLeftBarComponent,
    HistoryVisualizationComponent,
  ],
  imports: [
    MatButtonModule,
    MatIconModule,
    CommonModule,
    AppCommonModule,
    MedisNavigationModule,
    RouterModule,
    MatMenuModule,
    TranslateModule,
  ],
  exports: [NavbarVerticalStyle1Component, DetailLeftBarComponent],
  providers: [NavigationService],
})
export class NavbarVerticalStyle1Module {}
