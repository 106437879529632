import { rProperty } from '../data-model';

export class BLicenseCounter {
  @rProperty() licenseCounterLimit: number;
  @rProperty() licenseCounterRoleIds: number[];
  @rProperty() licenseCounterExcludedUserIds: number[];

  constructor(json: any) {}

  static fromRest(json: any): BLicenseCounter {
    return Object.assign(new BLicenseCounter({}), json);
  }
}
