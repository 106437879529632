import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

export class DebounceSubject<T> {
  constructor(private subject = new Subject<T>(), private lastVal?: T) {}

  set val(val: T) {
    this.next(val);
  }

  get val(): T {
    return this.lastVal;
  }

  subscribe(next: (val: T) => void, delayMs: number = 500): Subscription {
    return this.subject.pipe(debounceTime(delayMs), distinctUntilChanged()).subscribe(next);
  }

  next(val: T): void {
    this.subject.next((this.lastVal = val));
  }
}
