import { BaseModel, rProperty, parseArray } from '../data-model';

export class BMailbox extends BaseModel {
  @rProperty() id: string;
  @rProperty() name: string;
  @rProperty() uri: string;
  @rProperty() from_email: string;
  @rProperty() active: string;
  @rProperty() last_polling: string;

  mailbox: BMailbox[];

  constructor(json: any) {
    super(json);
    this.init(json);
  }

  init(json: any) {
    this.mailbox = parseArray<BMailbox>(json, BMailbox, 'mailbox');
  }

  static fromRest(json: any): BMailbox {
    if (json) {
      json.mailbox = BMailbox.fromRestArray(json.mailbox);
      return Object.assign(new BMailbox({}), json);
    }
    return json;
  }

  static fromRestArray(json: any[]) {
    return json && json.map((v) => BMailbox.fromRest(v));
  }
}

export class BMailboxTeams extends BaseModel {
  @rProperty() id: string;
  @rProperty() name: string;
  @rProperty() incoming_mailbox_id: string;
  @rProperty() outgoing_mailbox_id: string;

  mailboxteams: BMailboxTeams[];

  constructor(json: any) {
    super(json);
    this.init(json);
  }

  init(json: any) {
    this.mailboxteams = parseArray<BMailboxTeams>(json, BMailboxTeams, 'mailboxteams');
  }

  static fromRest(json: any): BMailboxTeams {
    if (json) {
      json.mailboxteams = BMailboxTeams.fromRestArray(json.mailboxteams);
      return Object.assign(new BMailboxTeams({}), json);
    }
    return json;
  }

  static fromRestArray(json: any[]) {
    return json && json.map((v) => BMailboxTeams.fromRest(v));
  }
}

export class BMailboxHistory extends BaseModel {
  @rProperty() created_by: string;
  @rProperty() edited_by: string;
  @rProperty() mailbox: string;
  @rProperty() updated_fields: string;
  @rProperty() old_data: string;
  @rProperty() new_data: string;
  @rProperty() created_ts: string;
  @rProperty() log_source: string;

  mailboxhistory: BMailboxHistory[];

  constructor(json: any) {
    super(json);
    this.init(json);
  }

  init(json: any) {
    this.mailboxhistory = parseArray<BMailboxHistory>(json, BMailboxHistory, 'mailboxteams');
  }

  static fromRest(json: any): BMailboxHistory {
    if (json) {
      json.mailboxhistory = BMailboxHistory.fromRestArray(json.mailboxhistory);
      return Object.assign(new BMailboxHistory({}), json);
    }
    return json;
  }

  static fromRestArray(json: any[]) {
    return json && json.map((v) => BMailboxHistory.fromRest(v));
  }
}
