import { gql } from 'apollo-angular';

import { BaseModel, rProperty, base64ToPK } from '../data-model';

export class BLanguage extends BaseModel {
  @rProperty() id: string;
  @rProperty() name: string;
  @rProperty() code: string;

  isActive = true;

  init(json: any) {}

  static fromRest(json: any): BLanguage {
    return Object.assign(new BLanguage({}), json);
  }

  static fromRestArray(json: any[]): BLanguage[] {
    return json && json.map((v) => BLanguage.fromRest(v));
  }

  getMId() {
    return base64ToPK(this.id);
  }
  getMName() {
    return this.name;
  }
}

export const fragment = gql`
  fragment languageFragment on LanguageNode {
    id
    name
    code
  }
`;
