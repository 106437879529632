import { gql } from 'apollo-angular';

import * as MCountry from './country';

export const allCountriesQuery = gql`
  query allCountriesQuery {
    allCountries {
      ...countryConnectionFragment
    }
  }
  ${MCountry.fragmentConnection}
`;
