import { gql } from 'apollo-angular';
import { Injectable, Injector } from '@angular/core';

import * as MMedicalDocumentType from './medical-document-type';
import { BaseDataService } from '../data-service';

const getAllMedicalDocumentTypes = gql`
  query getAllMedicalDocumentTypes {
    allMedicalDocumentTypes {
      ...medicalDocumentTypeConnectionFragment
    }
  }
  ${MMedicalDocumentType.fragmentConnection}
`;

@Injectable({
  providedIn: 'root',
})
export class MedicalDocumentTypeService extends BaseDataService<MMedicalDocumentType.BMedicalDocumentType> {
  query = getAllMedicalDocumentTypes;
  objectInstance = new MMedicalDocumentType.BMedicalDocumentType({});
  fieldName = 'allMedicalDocumentTypes';

  constructor(injector: Injector) {
    super(injector);
  }
}
