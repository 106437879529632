import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export interface Locale {
  id: string;
  data: Object;
  title: string;
}

@Injectable({ providedIn: 'root' })
export class MiiToolTranslationLoaderService {
  constructor(private translateService: TranslateService) {}

  loadTranslations(locales: Locale[]): void {
    locales.forEach((locale) => {
      // use setTranslation() with the third argument set to true
      // to append translations instead of replacing them
      this.translateService.setTranslation(locale.id, locale.data, true);
    });
  }
}
