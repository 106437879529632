import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { NavigationService, NavItem } from './navigation.service';
import { MedisSidebarService } from '@mi-tool/components/sidebar/sidebar.service';
import { Helpers } from '@mi-tool/utils/helpers';

@Component({
  selector: 'app-navbar',
  templateUrl: './app-navbar.component.html',
  styleUrls: ['./app-navbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavbarVerticalStyle1Component implements OnInit, OnDestroy {
  isInquiryDetailView = false;
  navigationRoots: NavItem[];
  allRoutes: NavItem[];
  navyChildren: NavItem[] = [];
  selectedNavyItem: NavItem;
  isOpen: boolean;

  private subscription: Subscription = new Subscription();

  constructor(
    private medisSidebarService: MedisSidebarService,
    private router: Router,
    private navigationService: NavigationService,
    private helpers: Helpers
  ) {
    this.helpers.layoutDependsOnRouterEvent$$.subscribe((view) => {
      this.isInquiryDetailView = view === 'inquiryDetailView';
    });
  }

  get onlyInquiries(): boolean {
    return (
      this.navigationRoots &&
      this.navigationRoots.length === 1 &&
      this.navigationRoots[0].name === 'Enquiries'
    );
  }

  ngOnInit(): void {
    this.subscription.add(
      this.navigationService.model.subscribe((routes) => {
        this.navigationRoots = routes;
        this.allRoutes = routes
          .map((route) => (route.href ? route : route.children.flat(Infinity)))
          .flat();

        this.selectedNavyItem = routes[0];
      })
    );

    this.subscription.add(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe((arg: Event) => {
          if (this.medisSidebarService.getSidebar('navbar')) {
            this.medisSidebarService.getSidebar('navbar').close();
            this.isOpen = this.medisSidebarService.getSidebar('navbar').folded;
          }

          this.selectedNavyItem =
            this.allRoutes.find((route) => 'url' in arg && arg.url?.includes(route?.href)) ||
            this.navigationRoots[0];
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  toggleSidebarFolded(): void {
    this.medisSidebarService.getSidebar('navbar').toggleFold();
    this.isOpen = this.medisSidebarService.getSidebar('navbar').folded;
  }

  getMenuChilds(items): void {
    this.navyChildren = items;
  }

  setSelectedItem(item): void {
    this.selectedNavyItem = item;
  }
}
