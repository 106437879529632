<mat-chip-listbox class="chip-wrapper" [ngClass]="isInvalid() ? 'invalid' : style">
  <mat-basic-chip
    *ngFor="let choice of choices; let first = first; let last = last"
    [ngClass]="{
      'chip-first': first,
      'chip-last': last,
      'chip-inner': !first && !last,
      'chip-selected-disabled': value === choice.value && disabled,
      'chip-selected-basic': value === choice.value && !disabled
    }"
    [attr.data-testid]="choice.value"
    (click)="!disabled && selectValue(choice.value)"
  >
    {{ (choice.translationKey ? choice.translationKey : choice.name) | translate }}
  </mat-basic-chip>
</mat-chip-listbox>
