import { Apollo, gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { parseArray } from '../../data-model/data-model';

import * as MStatus from './status';

const getAllStatus = gql`
  query getAllStatus {
    allStatus {
      ...statusConnectionFragment
    }
  }
  ${MStatus.fragmentConnection}
`;

@Injectable()
export class StatusService {
  values: BehaviorSubject<MStatus.BStatus[]>;

  constructor(apollo: Apollo) {
    // The parameter of the query is an observable which means the query is executed every time a new value flows in the stream
    this.values = new BehaviorSubject<MStatus.BStatus[]>([]);
    let query = apollo.query({ query: getAllStatus, variables: { 'X-NO-LOADING': true } });
    query.subscribe((res) => {
      //console.log(res);
      if (res.data) {
        let status = parseArray<MStatus.BStatus>(res.data, MStatus.BStatus, 'allStatus');
        this.values.next(status);
      } else {
        console.log('Response with no data!');
      }
    });
  }
}
