import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { filter } from 'rxjs/operators';
import { firstValueFrom } from 'rxjs';
import { userRoles } from '@mi-tool/consts';
import { BUser } from 'app/modules/data-model/data-model.module';

@Injectable({
  providedIn: 'root',
})
export class RoleDocumentApproverGuard {
  allowedRoles = [...userRoles.mim, ...userRoles.documentProcessing];

  constructor(private router: Router, private authService: AuthService) {}

  async canActivate(): Promise<boolean> {
    const user = await firstValueFrom(this.authService.user.pipe(filter((user) => !!user)));

    const canActivate = this.evaluateCanActivate(user);

    if (canActivate) {
      return true;
    }

    this.router.navigate(['access-denied']);
    return false;
  }

  evaluateCanActivate(user: BUser): boolean {
    if (!user) {
      return false;
    }

    if (user?.isSysAdmin()) {
      return true;
    }

    return user?.affiliations.some(
      (affiliation) =>
        affiliation.team.documentApprovalWithinEnqmed &&
        this.allowedRoles.includes(affiliation.role.name)
    );
  }
}
