import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { UrlResolverService } from 'app/common/url-resolver.service';
import { BAttachment } from './attachment';
import { GenericDialogService } from 'app/common/common/generic-dialog/generic-dialog.service';
import { MessageHandlerService } from 'app/common/common/message-handler/message-handler.service';

@Injectable({
  providedIn: 'root',
})
export class AttachmentsService {
  private readonly URL_ATTACHMENTS: string;

  constructor(
    private http: HttpClient,
    urlResolver: UrlResolverService,
    private genericDialog: GenericDialogService,
    private messageService: MessageHandlerService
  ) {
    this.URL_ATTACHMENTS = urlResolver.apiUrl('attachments');
  }

  upload(fileBase64: string): Observable<BAttachment> {
    return this.http
      .post<BAttachment>(this.URL_ATTACHMENTS, { file: fileBase64 })
      .pipe(map((r) => BAttachment.fromRest(r)));
  }

  delete(...attachmentIds: number[]): Observable<void> {
    return this.http.delete<void>(this.URL_ATTACHMENTS, { body: { attachmentIds } });
  }

  uploadToHistory(historyId: number, isDraft: boolean, fileBase64: string): Observable<string> {
    return this.http.post<string>(this.buildURL(historyId), { isDraft: isDraft, file: fileBase64 });
  }

  copyToAnswer(attachmentId: number, answerDraftHistoryId: number): Observable<string> {
    return this.http.put<string>(this.buildURL(answerDraftHistoryId), {
      attachmentId: attachmentId,
    });
  }

  private deleteFromHistory(
    attachment: BAttachment,
    isDraft: boolean,
    deleteFromAll: boolean
  ): Observable<void> {
    return this.http
      .delete<string | void>(this.buildURL(attachment.historyObjectPk), {
        params: { attachmentId: attachment.pk(), isDraft, deleteFromAll },
      })
      .pipe(
        map((message) => {
          if (message) {
            this.genericDialog.showMessage(message);
          }
        })
      );
  }

  private getInteractionIdsWhereAttachmentIsPresent(att: BAttachment): Observable<number[]> {
    const url = `${this.buildURL(att.historyObjectPk)}?attachmentId=${att.pk()}`;
    return this.http.get<number[]>(url);
  }

  deleteAttachment(attachment: BAttachment, isDraft: boolean): Observable<void> {
    let deleteFromAllObservable: Observable<boolean> = new BehaviorSubject(false);
    if (!isDraft) {
      const ERR_MSG = 'An error occurred while getting the interaction ids for the attachment';
      deleteFromAllObservable = this.getInteractionIdsWhereAttachmentIsPresent(attachment).pipe(
        switchMap((interIds) => this.genericDialog.confirmDeleteAttachment(interIds)),
        this.messageService.displayErrorAndStopChain(ERR_MSG)
      );
    }
    const ERR_MSG = 'An error occurred while deleting the attachment';
    return deleteFromAllObservable
      .pipe(
        switchMap((deleteFromAll) => this.deleteFromHistory(attachment, isDraft, deleteFromAll))
      )
      .pipe(this.messageService.displayErrorAndStopChain(ERR_MSG));
  }

  private buildURL(historyId: number): string {
    return `${this.URL_ATTACHMENTS}/${historyId}`;
  }
}
