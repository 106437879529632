import { gql } from 'apollo-angular';

import * as MNotificationType from './notification-type';
import * as MNotificationFrequency from '../../data-model/notification-frequency/notification-frequency';
import * as MNotificationOption from '../../data-model/notification-option/notification-option';

export const allNotificationTypesQuery = gql`
  query allNotificationTypesQuery {
    allNotificationTypes {
      edges {
        node {
          ...notificationTypeFragment
          notificationFrequencies {
            edges {
              node {
                ...notificationFrequencyFragment
              }
            }
          }
          notificationOptions {
            edges {
              node {
                ...notificationOptionFragment
              }
            }
          }
        }
      }
    }
  }
  ${MNotificationType.fragment}
  ${MNotificationFrequency.fragment}
  ${MNotificationOption.fragment}
`;
