<div *ngFor="let event of historyData; let last = last">
  <div>
    <span class="medis-clickable text-14-800-dark" (click)="previewUser(event.createdBy?.pk())">{{
      event.createdBy | fullName
    }}</span>
    {{ (event.changes && !last ? 'UPDATED_INQUIRER_DETAILS' : 'CREATED_INQUIRER') | translate }}
    {{ 'on' | translate }} {{ event.createdTs | dateChecker }}
  </div>
  <div *ngIf="event.changes && !last" class="medis-frame-4-azure medis-pa-5">
    <div *ngFor="let change of event.changes">
      <div
        *ngIf="change.preparedText; else withoutPrepared"
        [innerHTML]="change.preparedText"
      ></div>
      <ng-template #withoutPrepared>
        <b>{{ change.field | translate }}</b
        >: {{ 'From' | translate }} <b>{{ change.from | translate }}</b> {{ 'To' | translate }}
        <b>{{ change.to | translate }}</b>
      </ng-template>
    </div>
  </div>
  <div *ngIf="event.changes && last" class="medis-frame-4-azure medis-pa-5">
    <div *ngFor="let change of event.changes">
      <div
        *ngIf="change.preparedText; else withoutPrepared"
        [innerHTML]="change.preparedText"
      ></div>
      <ng-template #withoutPrepared>
        <b>{{ change.field | translate }}</b
        >:
        <b>{{ change.to | translate }}</b>
      </ng-template>
    </div>
  </div>
</div>
<app-loading-indicator [empty]="!historyData?.length"></app-loading-indicator>
