<nav class="navbar navbar-toggleable-xl navbar-light top-toolbar" id="medis-nav">
  <div class="top-group">
    <app-inquiries-toolbar
      *ngIf="isInquiriesView && !isSpamOrDraftsView"
      [exportEnabled]="enableExportButton"
    ></app-inquiries-toolbar>
    <app-auth-request-toolbar *ngIf="isAuthRequestsView"></app-auth-request-toolbar>
  </div>
  <div>
    <i
      *ngIf="loggedUser?.isSysAdmin()"
      class="fas fa-bell medis-icon-toolbar"
      [matMenuTriggerFor]="notification"
    ></i>
    <i class="fas fa-flag medis-icon-toolbar" [matMenuTriggerFor]="language"></i>
    <i
      *ngIf="!hideWorkInProgress"
      class="fas fa-question-circle medis-icon-toolbar"
      [matMenuTriggerFor]="helpMenu"
    ></i>
    <i
      *ngIf="(isInquiriesView && !isSpamOrDraftsView) || isAuthRequestsView"
      class="fas fa-cog medis-icon-toolbar"
      (click)="toggleRightSidebarFolded()"
    ></i>
    <i class="fas fa-user-circle medis-icon-toolbar" [matMenuTriggerFor]="userMenu"></i>
  </div>
</nav>

<mat-menu #notification="matMenu">
  <div class="text-18-500-dark px-4 py-2">
    {{ 'SYSTEM' | translate }} {{ 'NOTIFICATIONS' | translate | titlecase }}
  </div>
  <mat-divider></mat-divider>
  @for(msg of latestMessages.value; track msg.time) {
  <div mat-menu-item class="py-2 d-block">
    <div class="medis-flex-container-between">
      <span [ngClass]="msg.type === 'ERROR' ? 'text-13-800-coral' : 'text-13-800-green'">
        {{ msg.type | translate }}
      </span>
      <span class="text-13-800-dark">
        <i class="far fa-clock"></i> {{ msg.time | date: 'hh:mm:ss' }}
      </span>
    </div>
    <div class="text-16-700-dark">{{ msg.text }}</div>
  </div>
  } @empty {
  <div mat-menu-item>
    <span class="text-16-700-dark">{{ 'NO_RECORDS' | translate }}</span>
  </div>
  }
</mat-menu>

<mat-menu #language="matMenu">
  <ng-container *ngFor="let lang of languageList">
    <button mat-menu-item (click)="setLanguage(lang.id)" class="menu-item-h-30">
      <img [src]="'../../../assets/img/flags/' + lang.id + '.svg'" alt="flag" class="lang-flag" />
      <span class="lang-title">{{ lang.title }}</span>
    </button>
  </ng-container>
</mat-menu>

<mat-menu #helpMenu="matMenu">
  <span class="user-manual-title">{{ 'USER_MANUAL' | translate }}</span>
  <div class="user-manual-buttons">
    <ng-container *ngFor="let manual of manuals">
      <a mat-menu-item [href]="manual.url" target="_blank">
        <mat-icon>{{ manual.icon }}</mat-icon>
        <span>{{ manual.title | translate }}</span>
      </a>
    </ng-container>

    <mat-divider></mat-divider>

    <button mat-menu-item [matMenuTriggerFor]="tutorials">
      <mat-icon>videocam</mat-icon>
      <span>{{ 'VIDEO_TUTORIALS' | translate }}</span>
    </button>
  </div>
  <a mat-menu-item class="user-manual-support" href="mailto:{{ supportEmail }}">
    <mat-icon>email</mat-icon>
    <span>{{ 'CONTACT_SUPPORT' | translate }}</span>
  </a>
</mat-menu>

<mat-menu #tutorials="matMenu">
  <ng-container *ngFor="let video of videoTutorials">
    <a mat-menu-item [href]="video.url" target="_blank" class="menu-item-h-30">
      <span>{{ video.title | translate }}</span>
    </a>
  </ng-container>
</mat-menu>

<mat-menu #userMenu="matMenu" class="medis-menu">
  <div class="full-name">{{ loggedUser | fullName }}</div>
  <div class="email">{{ loggedUser?.email }}</div>
  <div class="user-manual-buttons">
    <button mat-menu-item *ngFor="let item of toolbarNavigation" (click)="navigateUser(item)">
      <i class="fa {{ item.icon }}"></i>
      {{ item.translate | translate }}
    </button>
    <button mat-menu-item (click)="logoutClicked()">
      <i class="fas fa-sign-out-alt"></i>
      {{ 'LOGOUT' | translate }}
    </button>
  </div>
</mat-menu>
