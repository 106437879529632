<div *ngIf="user">
  <div *ngTemplateOutlet="personalInfo; context: {person: user}"></div>
  <div *ngTemplateOutlet="contacts; context: {entity: user}"></div>
  <div *ngIf="allTeams.length > 0" class="medis-pt-10">
    <div class="text-16-900-azure"><i class="fa fa-users"></i> {{'TEAMS' | translate}}</div>
    <div *ngFor="let teamToRoles of visibleTeams" class="text-14-500-dark">
      <span class="text-14-800-dark">{{ teamToRoles.name }}</span> - {{ teamToRoles.value }}
    </div>
    <div
      *ngIf="visibleTeams.length != allTeams.length"
      (click)="showAllTeams()"
      class="medis-clickable text-13-500-azure"
    >
      {{'Show_all' | translate}} {{allTeams.length}}
    </div>
  </div>
</div>
<div *ngIf="inquirer">
  <div *ngTemplateOutlet="personalInfo; context: {person: inquirer}"></div>
  <div *ngTemplateOutlet="contacts; context: {entity: inquirer}"></div>
</div>
<div *ngIf="department">
  <div *ngIf="department.value" class="text-14-500-dark">{{ department.value }}</div>
  <div *ngTemplateOutlet="contacts; context: {entity: department}"></div>
  <div *ngIf="department.team">
    <div class="text-16-900-azure"><i class="fa fa-users"></i> {{'TEAM' | translate}}</div>
    <div>{{ department.team }}</div>
  </div>
</div>

<ng-template #personalInfo let-person="person">
  <div [ngClass]="minimizedVisualization ? 'text-14-700-dark' : 'text-16-800-dark'">
    {{ person.genderToText()}} {{ person.titleToText() }} {{ person | fullName }}
  </div>
  <div *ngIf="person.jobDescription || person.specialization?.name" class="text-14-500-dark">
    {{ person.specialization?.name }}<span
      *ngIf="person.jobDescription && person.specialization?.name"
    >
      / </span
    >{{ person.jobDescription }}
  </div>
  <div *ngIf="person.institution || person.department" class="text-14-500-dark">
    {{ person.institution }}<span *ngIf="person.institution && person.department"> / </span>{{
    person.department }}
  </div>
</ng-template>

<ng-template #contacts let-entity="entity">
  <div *ngIf="entity.email" class="text-14-500-dark">
    <i class="fas fa-envelope medis-mr-10"></i>
    <span class="text-14-800-azure">{{entity.email}}</span>
  </div>
  <div *ngIf="entity.mainPhone?.val" class="text-14-500-dark">
    <i class="fas fa-phone medis-mr-10"></i> {{entity.mainPhone.val}}
  </div>
  <div *ngIf="entity.mainAddress?.shortRepresentation()" class="text-14-500-dark">
    <i class="fas fa-map-marked-alt medis-mr-10"></i> {{entity.mainAddress.shortRepresentation()}}
  </div>
  <div *ngIf="entity.language?.name" class="text-14-500-dark">
    <i class="fas fa-language medis-mr-10"></i> {{entity.language.name}}
  </div>
</ng-template>
