import { Locale } from '@mi-tool/services/translation-loader.service';
import { englishLangData } from './english';
import { japaneseLangData } from './japanese';
import { bulgarianLangData } from './bulgarian';
import { italianLangData } from './italian';
import { spanishLangData } from './spanish';
import { frenchLangData } from './french';
import { germanLangData } from './german';
import { chineseLangData } from './chinese';
import { dutchLangData } from './dutch';
import { portugueseLangData } from './portuguese';
import { turkishLangData } from './turkish';
import { koreanLangData } from './korean';
import { russianLangData } from './russian';
import { arabicLangData } from './arabic';
import { hindiLangData } from './hindi';
import { malayLangData } from './malay';
import { bengaliLangData } from './bengali';

// country flags
// https://hjnilsson.github.io/country-flags/

export const miToolLanguages: Locale[] = [
  { id: 'en', title: 'English', data: englishLangData },
  { id: 'ja', title: 'Japanese', data: japaneseLangData },
  { id: 'bg', title: 'Bulgarian', data: bulgarianLangData },
  { id: 'it', title: 'Italian', data: italianLangData },
  { id: 'es', title: 'Spanish', data: spanishLangData },
  { id: 'fr', title: 'French', data: frenchLangData },
  { id: 'de', title: 'German', data: germanLangData },
  { id: 'zh', title: 'Chinese (Mandarin)', data: chineseLangData },
  { id: 'nl', title: 'Dutch', data: dutchLangData },
  { id: 'ko', title: 'Korean', data: koreanLangData },
  { id: 'pt', title: 'Portuguese', data: portugueseLangData },
  { id: 'tr', title: 'Turkish', data: turkishLangData },
  { id: 'ru', title: 'Russian', data: russianLangData },
  { id: 'ae', title: 'Arabic', data: arabicLangData },
  { id: 'hi', title: 'Hindi', data: hindiLangData },
  { id: 'ms', title: 'Malay', data: malayLangData },
  { id: 'bn', title: 'Bengali', data: bengaliLangData },
].sort((a, b) => (a.title > b.title ? 1 : -1));
