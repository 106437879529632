import { BCompany } from '../company/company';
import { BCountry } from '../country/country';
import { BTeam } from '../user/user';
import { base64ToPK } from '../data-model';

export type TDomainDialogData = {
  domain: BDomain;
  companies: BCompany[];
  teams: BTeam[];
  countries: BCountry[];
};

export type TDomainData = {
  id: string;
  name: string;
  companies: string[];
  teams: string[];
  countries: string[];
  isActive: boolean;
};

export type TDomainParams = {
  pageIndex: number;
  pageSize: number;
  orderBy?: string;
  id?: number;
  name?: string;
  companyIds?: string;
  teamIds?: string;
  countryIds?: string;
  isActive?: string;
};

export class BDomain {
  id: string;
  name: string;
  companies: BCompany[];
  teams: BTeam[];
  countries: BCountry[];
  isActive: boolean;

  static fromRest(json: any): BDomain {
    if (json) {
      json.companies = BCompany.fromRestArray(json.companies);
      json.teams = BTeam.fromRestArray(json.teams);
      json.countries = BCountry.fromRestArray(json.countries);
    }
    return Object.assign(new BDomain(), json);
  }

  static fromRestArray(json: any[]): BDomain[] {
    return json && json.map((v) => BDomain.fromRest(v));
  }

  pk(): number | undefined {
    if (this.id) {
      return base64ToPK(this.id);
    }
    return undefined;
  }
}
