import { Component } from '@angular/core';
import { RollbarHandler } from 'app/common/rollbar-handler';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
})
export class PageNotFoundComponent {
  constructor() {
    RollbarHandler.warn('Page Not Found shown for: ' + window.location.href);
  }
}
