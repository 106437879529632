import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlResolverService } from 'app/common/url-resolver.service';
import { BAuditTrail, TAuditTrailDetailData } from './audit-trail';
import { isNil, omitBy, uniq, without } from 'lodash';
import { Ajax } from 'app/common/ajax';
import { AuditTrailComponent } from 'app/common/common/audit-trail/audit-trail.component';
import {
  NonUserAuditData,
  NonUserSingleAuditData,
  UserAuditData,
} from 'app/common/common/audit-trail/audit-trail-data';
import { MatDialog } from '@angular/material/dialog';

@Injectable({ providedIn: 'root' })
export class AuditTrailService {
  private readonly URL: string;
  private readonly URL_DETAILS: string;

  constructor(
    private http: HttpClient,
    private urlResolver: UrlResolverService,
    private modalService: MatDialog
  ) {
    this.URL = this.urlResolver.misApiUrlForPath('/audit');
    this.URL_DETAILS = this.urlResolver.misApiUrlForPath('/audit/details/');
  }

  get(options: { params: Record<string, any>; responseType?: 'json' }): Observable<BAuditTrail> {
    return this.http.get<BAuditTrail>(this.URL, {
      ...Ajax.X_NO_LOADING_OPTIONS,
      ...options,
      params: omitBy<Record<string, any>>(options.params, isNil),
    });
  }

  getDetailsFor(recordId: string): Observable<TAuditTrailDetailData> {
    return this.http.get<TAuditTrailDetailData>(this.URL_DETAILS + recordId);
  }

  openAuditTrailDialog(
    auditTrailData: UserAuditData | NonUserAuditData | NonUserSingleAuditData,
    event: MouseEvent = undefined
  ): void {
    if (event) {
      event.stopPropagation();
    }
    this.modalService.open(AuditTrailComponent, {
      maxWidth: '95vw',
      data: auditTrailData,
    });
  }

  modifyDisplayedColumnsIfSysAdmin(userIsSysAdmin: boolean, displayedColumns: string[]): string[] {
    /**
     * Updates the displayed columns based on the user's role.
     * If the user is a system administrator, the "auditTrail" column is added to the displayed columns.
     * If the user is not a system administrator, the "auditTrail" column is removed from the displayed columns.
     * @param isSysAdmin - A boolean indicating whether the user is a system administrator.
     * @param displayedColumns - An array of strings representing the columns to be displayed.
     * @returns An updated array of strings representing the modified displayed columns.
     */
    return userIsSysAdmin
      ? uniq([...displayedColumns, 'auditTrail'])
      : without(displayedColumns, 'auditTrail');
  }
}
