import { gql } from 'apollo-angular';

import { BaseModel, rProperty } from '../data-model';

export class BMedicalDocumentVersionFile extends BaseModel {
  @rProperty() id: string;
  @rProperty() name: string;
  @rProperty() mimeType: string;

  allMimetypes = {
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
    'application/vnd.ms-powerpoint': 'pptx',
    'application/pdf': 'pdf',
  };

  init(json: any) {}

  static fromRest(json: any): BMedicalDocumentVersionFile {
    return Object.assign(new BMedicalDocumentVersionFile({}), json);
  }

  static fromRestArray(json: any[]): BMedicalDocumentVersionFile[] {
    return json && json.map((v) => BMedicalDocumentVersionFile.fromRest(v));
  }

  extension(): string {
    return this.allMimetypes[this.mimeType];
  }
}

export const fragment = gql`
  fragment medicalDocumentVersionFileFragment on MedicalDocumentVersionFileNode {
    id
    name
    mimeType
  }
`;

export const fragmentConnection = gql`
  fragment medicalDocumentVersionFileConnectionFragment on MedicalDocumentVersionFileNodeConnection {
    edges {
      node {
        ...medicalDocumentVersionFileFragment
      }
    }
  }
  ${fragment}
`;
