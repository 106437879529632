import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { CdkDropList, CdkDrag } from '@angular/cdk/drag-drop';

import { RightPanelComponent } from './right-panel.component';
import { RightPanelService } from './right-panel.service';

@NgModule({
  declarations: [RightPanelComponent],
  providers: [RightPanelService],
  exports: [RightPanelComponent],
  imports: [
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    FormsModule,
    MatListModule,
    MatCardModule,
    CommonModule,
    CdkDrag,
    CdkDropList,
  ],
})
export class RightPanelModule {}
