import { Apollo, gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { parseArray } from '../../data-model/data-model';

import { BRegion, MProduct, MRegion } from './region';

const getAllRegions = gql`
  query getAllRegions {
    allRegions {
      edges {
        node {
          ...regionFragment
          products {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
  }
  ${MRegion.fragment}
`;

const getUserSubRegionsQuery = gql`
  query getUserSubRegions {
    userSubRegions {
      edges {
        node {
          ...regionFragment
          products {
            ...productConnectionFragment
          }
        }
      }
    }
  }
  ${MRegion.fragment}
  ${MProduct.fragmentConnection}
`;

@Injectable()
export class RegionService {
  values: BehaviorSubject<BRegion[]>;

  constructor(apollo: Apollo) {
    // The parameter of the query is an observable which means the query is executed every time a new value flows in the stream
    this.values = new BehaviorSubject<BRegion[]>([]);
    let query = apollo.query({ query: getAllRegions, variables: { 'X-NO-LOADING': true } });
    query.subscribe((res) => {
      //console.log(res);
      if (res.data) {
        let regions = parseArray<BRegion>(res.data, BRegion, 'allRegions');
        this.values.next(regions);
      } else {
        console.log('Response with no data!');
      }
    });
  }
}

@Injectable()
export class SubRegionService {
  values: BehaviorSubject<BRegion[]>;

  constructor(private apollo: Apollo) {
    this.values = new BehaviorSubject<BRegion[]>([]);
    let query = this.apollo.query({
      query: getUserSubRegionsQuery,
    });
    query.subscribe((response) => {
      if (response.data) {
        let regions = parseArray<BRegion>(response.data, BRegion, 'userSubRegions');
        this.values.next(regions);
      }
    });
  }
}
