import { Observable, Subject } from 'rxjs';

export namespace Ajax {
  export const X_NO_LOADING_HEADERS = { 'X-NO-LOADING': 'true' };
  export const X_NO_LOADING_OPTIONS = { headers: X_NO_LOADING_HEADERS };

  export class CacheSubject<T> {
    private data: string;
    private pendingSubjects: Subject<T>[] = [];

    public constructor(dataSource: Observable<T>) {
      dataSource.subscribe((data) => {
        this.data = JSON.stringify(data);
        for (const subject of this.pendingSubjects) {
          this.notifySubject(subject);
        }
        this.pendingSubjects = [];
      });
    }

    public get(): Observable<T> {
      const subject = new Subject<T>();
      if (this.data) {
        setTimeout(() => this.notifySubject(subject));
      } else {
        this.pendingSubjects.push(subject);
      }
      return subject;
    }

    private notifySubject(subject: Subject<T>): void {
      subject.next(JSON.parse(this.data));
      subject.complete();
    }
  }

  export class QueryParams {
    private query: string = '';

    addIfPresent(
      name: string,
      value: string | number | string[] | number[],
      extraCondition = true
    ): QueryParams {
      if (extraCondition && !Array.isArray(value) && (value || typeof value === 'number')) {
        this.add(name, value);
      } else if (extraCondition && Array.isArray(value) && value.length) {
        this.add(name, value.join());
      }
      return this;
    }

    add(name: string, value: string | number): QueryParams {
      this.query += `&${name}=${value}`;
      return this;
    }

    toString(): string {
      return this.query.length > 0 ? `?${this.query.substr(1)}` : '';
    }
  }
}
