export enum ErrorCode {
  BAD_REQUEST = 'Bad Request',
}

export enum ErrorMessages {
  PermissionDenied = 'Permission Denied',
}

export enum ErrorTitles {
  ErrorWhileSettingStatus = 'An error occurred while setting the status',
  ErrorWhileMerging = 'An error occurred while merging interaction',
  ErrorWhileFetchingLastMergeData = 'An error occurred while fetching the last merge data',
}
