import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'app/../environments/environment';
import { AuthService } from 'app/auth/auth.service';
import { userRoles } from '@mi-tool/consts';

function navMenu(): NavItem[] {
  return [
    {
      name: 'INQUIRIES',
      icon: 'fa fa-clipboard-list',
      href: 'inq/my-teams-open-inq',
      reqRole: [...userRoles.inquiryProcessing, ...userRoles.sysAdmin],
    },
    {
      name: 'REPOSITORY',
      icon: 'far fa-folder-open',
      href: 'repository/management',
      reqRole: [...userRoles.mimAndMa, ...userRoles.documentProcessing, ...userRoles.sysAdmin],
    },
    {
      name: 'REPOSITORY',
      icon: 'far fa-folder-open',
      href: 'repository/approvals',
      isHidden: true,
      reqRole: [...userRoles.mimAndMa, ...userRoles.documentProcessing, ...userRoles.sysAdmin],
    },
    {
      name: 'REPORTS',
      icon: 'fa fa-chart-bar',
      href: 'reports',
      reqRole: [...userRoles.mimAndMa, ...userRoles.sysAdmin],
      isWorkInProgress: true,
    },
    {
      name: 'ADMINISTRATION',
      icon: 'fas fa-lock',
      children: [
        {
          name: 'Therapeutic_Areas',
          href: 'administration/therapeutic-areas',
          reqRole: userRoles.sysAdmin,
        },
        {
          name: 'USER_ADMIN',
          href: 'administration/users',
          reqRole: userRoles.admin,
        },
        {
          name: 'HCP_ADMINISTRATION',
          href: 'administration/hcps',
          reqRole: userRoles.admin,
        },
        {
          name: 'HCP_ADMINISTRATION',
          href: 'administration/specializations',
          reqRole: userRoles.admin,
          isHidden: true,
        },
        {
          name: 'EVENTS',
          href: 'administration/events',
          reqRole: [...userRoles.mimAndMa, ...userRoles.eventManager, ...userRoles.admin],
        },
        {
          name: 'MAILBOXES',
          href: 'administration/mailbox',
          reqRole: userRoles.sysAdmin,
        },
        {
          name: 'TASKS',
          href: 'administration/tasks',
          reqRole: userRoles.sysAdmin,
        },
        {
          name: 'MIGRATION_ERRORS',
          href: 'administration/migration-errors',
          reqRole: userRoles.sysAdmin,
        },
        {
          name: 'METADATA',
          href: 'administration/metadata',
          reqRole: userRoles.sysAdmin,
        },
        {
          name: 'TEAMS',
          href: 'administration/teams',
          reqRole: [...userRoles.mim, ...userRoles.sysAdmin],
        },
        {
          name: 'AUTHORIZATION_REQUESTS',
          href: 'administration/auth-requests',
          reqRole: [...userRoles.mim, ...userRoles.admin],
        },
      ],
    },
  ];
}

function filterRights(userRoles: string[], navItems: NavItem[]): NavItem[] {
  return navItems.filter((i) => {
    i.children = i.children && filterRights(userRoles, i.children);
    if (environment.hideWorkInProgress && i.isWorkInProgress) {
      return false;
    }

    return (
      (i.href || (i.children && i.children.length)) &&
      (!i.reqRole || i.reqRole.find((r) => userRoles.includes(r)))
    );
  });
}

export type NavItem = {
  name: string;
  icon?: string;
  href?: string;
  queryParams?: Record<string, any>;
  reqRole?: string[];
  children?: NavItem[];
  isWorkInProgress?: boolean;
  isHidden?: boolean;
};

@Injectable()
export class NavigationService {
  public model: BehaviorSubject<NavItem[]>;

  constructor(private authenticationService: AuthService) {
    this.model = new BehaviorSubject<NavItem[]>([]);
    this.authenticationService.user.subscribe((user) => {
      if (user) {
        const userRoles = this.authenticationService.getCurrentUserRoles();
        const menuItems = filterRights(userRoles, navMenu());
        this.model.next(menuItems);
      } else {
        this.model.next([]);
      }
    });
  }
}
