import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';

import { TranslateModule } from '@ngx-translate/core';

import { MedisNavigationComponent } from './navigation.component';
import { MedisNavVerticalItemComponent } from './vertical/item/item.component';
import { MedisNavVerticalGroupComponent } from './vertical/group/group.component';
import { MatInputModule } from '@angular/material/input';
import { InquiriesLeftBarViewComponent } from './inquiries-left-bar-view/inquiries-left-bar-view.component';
import { GenericLeftBarViewComponent } from './generic-left-bar-view/generic-left-bar-view.component';
import { Helpers } from '../../utils/helpers';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DocumentsLeftBarViewComponent } from './documents-left-bar-view/documents-left-bar-view.component';
import { AppCommonModule } from 'app/common/common/common.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatRippleModule,
    TranslateModule.forChild(),
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    NgbPopoverModule,
    MatTooltipModule,
    AppCommonModule,
  ],
  exports: [MedisNavigationComponent],
  declarations: [
    MedisNavigationComponent,
    MedisNavVerticalGroupComponent,
    MedisNavVerticalItemComponent,
    InquiriesLeftBarViewComponent,
    GenericLeftBarViewComponent,
    DocumentsLeftBarViewComponent,
  ],
  providers: [Helpers],
})
export class MedisNavigationModule {}
