export interface MedisNavigationItem {
  id: string;
  type: 'item' | 'group';
  translate: string;
  icon?: string;
  svgIcon?: string;
  hidden?: boolean;
  url?: string;
  queryParams?: Record<string, any>;
  classes?: string;
  exactMatch?: boolean;
  children?: MedisNavigationItem[];
  userRoles?: string[];
}

export function newItem(
  id: string,
  translate: string,
  icon: string,
  url?: string,
  hidden?: boolean,
  svgIcon?: string,
  userRoles?: string[],
  queryParams?: Record<string, any>
): MedisNavigationItem {
  return {
    id: id,
    type: 'item',
    translate: translate,
    icon: icon,
    url: url,
    hidden: hidden,
    svgIcon: svgIcon,
    userRoles: userRoles,
    queryParams,
  };
}

export function newGroup(
  id: string,
  translate: string,
  icon: string,
  children: MedisNavigationItem[],
  userRoles?: string[]
): MedisNavigationItem {
  return {
    id: id,
    type: 'group',
    translate: translate,
    icon: icon,
    children: children,
    userRoles: userRoles,
  };
}
