import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { catchError, EMPTY, Observable, OperatorFunction } from 'rxjs';
import { RollbarHandler } from 'app/common/rollbar-handler';
import { ValueHolder } from 'app/common/value-holder';

type Panel = '' | 'red-snackbar';
type Type = 'ERROR' | 'INFO';

class Message {
  public time: Date = new Date();
  constructor(public text: string, public panelClass: Panel, public type: Type) {}
}

export const LATEST_MESSAGES: ValueHolder<Message[]> = { value: [] };

/** Simple class to present a message via SnackBar */
@Injectable()
export class MessageHandlerService {
  constructor(private snackBar: MatSnackBar, private translate: TranslateService) {}

  info(msg: string, params?: Object): void {
    const translatedMsg = this.translate.instant(msg, params);
    PendingMessagesHandler.add(this.snackBar, translatedMsg, 'INFO', '');
  }

  error(errorMsg: string, panelClass: Panel = ''): void {
    console.error(errorMsg);
    const hasMsg = errorMsg !== undefined && errorMsg !== '';
    const errorDisplay = hasMsg ? errorMsg : 'Something went wrong, please contact the support.';
    const translatedError = this.translate.instant(errorDisplay);
    RollbarHandler.error(translatedError);
    PendingMessagesHandler.add(this.snackBar, translatedError, 'ERROR', panelClass);
  }

  httpError(operation: string, resp: HttpErrorResponse): void {
    const text = (resp.error && resp.error.message) || resp.statusText;
    this.error(`(${operation}): ${text}`);
  }

  displayErrorAndStopChain(operation: string): OperatorFunction<any, any> {
    return catchError((err: any) => {
      if (err instanceof HttpErrorResponse) {
        this.httpError(operation, err);
      } else {
        this.error(`(${operation}): ${JSON.stringify(err)}`);
      }
      return EMPTY;
    });
  }
}

namespace PendingMessagesHandler {
  const PENDING_MESSAGES: Message[] = [];
  let snackBarRef: MatSnackBarRef<TextOnlySnackBar>;

  export function add(snackBar: MatSnackBar, text: string, type: Type, panelClass: Panel): void {
    const msg = new Message(text, panelClass, type);
    PENDING_MESSAGES.push(msg);
    LATEST_MESSAGES.value.unshift(msg);
    if (LATEST_MESSAGES.value.length > 20) {
      LATEST_MESSAGES.value.pop();
    }
    openNextPendingMessage(snackBar);
  }

  function openNextPendingMessage(snackBar: MatSnackBar): void {
    if (!snackBarRef && PENDING_MESSAGES.length > 0) {
      const msg = PENDING_MESSAGES.shift();
      open(snackBar, msg);
    }
  }

  function open(snackBar: MatSnackBar, msg: Message): void {
    snackBarRef = snackBar.open(msg.text, '', {
      duration: 5000,
      verticalPosition: 'bottom',
      panelClass: msg.panelClass,
    });
    snackBarRef.afterDismissed().subscribe(() => {
      snackBarRef = undefined;
      openNextPendingMessage(snackBar);
    });
  }
}
