import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import { filterQuery } from './queries';
import { BNewInteraction } from './new-interaction';
import { BUser } from '../user/user';
import { parseArray } from '../data-model';

@Injectable({
  providedIn: 'root',
})
export class NewInteractionService {
  //db handling
  private interactionQuerySubscription: Subscription;

  //resources
  values: BehaviorSubject<BNewInteraction[]>;
  user: BehaviorSubject<BUser> = new BehaviorSubject<BUser>(null);

  constructor(private apollo: Apollo) {
    this.values = new BehaviorSubject<BNewInteraction[]>([]);
    this.user.subscribe((value) => {
      if (this.user.getValue() != null) {
        this.performQuery();
      }
    });
  }

  performQuery() {
    if (this.interactionQuerySubscription) {
      this.interactionQuerySubscription.unsubscribe();
      this.interactionQuerySubscription = undefined;
    }
    //console.log("user: ",this.user.getValue())
    this.interactionQuerySubscription = this.apollo
      .query({
        query: filterQuery,
        variables: {
          userId: this.user.getValue().id,
        },
        fetchPolicy: 'network-only',
      })
      .subscribe((res: any) => {
        if (res.data) {
          let interactions = parseArray<BNewInteraction>(
            res.data['user'],
            BNewInteraction,
            'newInsertedInteractions'
          );
          this.values.next(interactions);
        } else {
          console.log('Response with no data!');
        }
      });
  }
}
