import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BAttachment } from '../../../data-model/attachment/attachment';

@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss'],
})
export class DocumentUploadComponent {
  @Input() attachment: BAttachment;
  @Output() attachmentChange: EventEmitter<BAttachment> = new EventEmitter<BAttachment>();
  fileUnderUpload: File;
  @Output() externalUrlChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() fileContent: any;
  @Output() fileContentChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() allowRemove: boolean = true;
  @Input() disabled: boolean = false;

  externalUrl: string;

  simulateClick() {
    let inputEl = document.getElementById('input-file') as HTMLElement;
    inputEl.click();
  }

  attachFile(fileInput: any): void {
    if (!fileInput.target.files[0]) {
      return;
    }
    this.fileUnderUpload = fileInput.target.files[0];
    let reader: FileReader = new FileReader();
    reader.onloadend = (e) => {
      this.fileContent = 'name:' + this.fileUnderUpload.name + reader.result;
      this.fileContentChange.emit(this.fileContent);
      this.attachment = new BAttachment({ name: this.fileUnderUpload.name });
      this.attachmentChange.emit(this.attachment);
    };
    reader.readAsDataURL(this.fileUnderUpload);
  }

  removeAttachment() {
    // We are not deleting the image. The images can only be archived
    this.fileContentChange.emit(null);
    this.attachment = null;
    this.attachmentChange.emit(this.attachment);
  }
}
