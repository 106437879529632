import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { BHistory, BInquiry, BInteraction } from '../../data-model/interaction/interaction';
import { DetailHistoryEditComponent } from './detail-history-edit/detail-history-edit.component';
import {
  BInquirer,
  HistoryInquirerDataService,
  SingleInteractionService,
} from 'app/modules/data-model/data-model.module';
import { InteractionsService } from 'app/modules/data-model/interaction/interactions.service';
import {
  Config,
  ConfigItem,
  ConfigItemOption,
} from 'app/common/common/search-select/search-select.config';
import { HistoryFilters } from './detail-history-filters';
import { SelectInquiryComponent } from './detail-history-interaction/select-inquiry/select-inquiry.component';
import { MessageHandlerService } from 'app/common/common/message-handler/message-handler.service';
import { AuthService } from 'app/auth/auth.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BDMMessage } from '../../data-model/message/message';
import { LEFT_BAR_HISTORY_CATEGORIES, LEFT_BAR_HISTORY_CATEGORIES_MAP } from '@mi-tool/consts';
import { HISTORY_DIALOG_MODES } from 'app/common/types';

@Component({
  selector: 'app-detail-history',
  templateUrl: './detail-history.component.html',
  styleUrls: ['./detail-history.component.scss'],
})
export class DetailHistoryComponent implements OnInit, OnDestroy {
  interaction: BInteraction;
  inquiry: BInquiry;

  visualize: string = 'interaction';
  interactionConfig: Config;

  selectedInteractionFilter: string[] = [];
  leftBarHistoryCategories = LEFT_BAR_HISTORY_CATEGORIES;
  leftBarHistoryCategoriesMap = LEFT_BAR_HISTORY_CATEGORIES_MAP;
  historyObject: BHistory;
  historyMessage: BDMMessage;
  interactionHistory: InteractionsService.History = { history: [], inquirers: [] };
  isInteractionHistoryLoading: boolean = false;
  inquirerHistory: BInquirer[] = [];
  isInquirerHistoryLoading: boolean = false;
  loggedInUserIsSysAdmin: boolean = false;

  private _mode: HISTORY_DIALOG_MODES = 'history';
  private subs: Subscription = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<DetailHistoryComponent>,
    translate: TranslateService,
    private modalService: MatDialog,
    private singleInteractionService: SingleInteractionService,
    private interactionsService: InteractionsService,
    private historyInquirerDataService: HistoryInquirerDataService,
    private messageService: MessageHandlerService,
    private authService: AuthService
  ) {
    const all = HistoryFilters.getAllFilters();
    this.interactionConfig = new Config(all, 'FILTER_BY', [
      new ConfigItem(
        '',
        '',
        'Search',
        all.map((f) => new ConfigItemOption(translate.instant(f.name), f.value))
      ),
    ]);
  }

  ngOnInit(): void {
    this.subs.add(
      this.authService.user.subscribe((user) => {
        this.loggedInUserIsSysAdmin = user?.isSysAdmin();
      })
    );
    if (this.historyObject && this.visualize === 'detail') {
      this.getHistoryMessage();
      return;
    }

    this.reloadInteractionHistory();
    this.loadInquirerHistory();
    this.subs.add(
      this.singleInteractionService.interaction.subscribe((interaction) => {
        this.interaction = interaction;
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  set mode(val: HISTORY_DIALOG_MODES) {
    this._mode = val;
    if (val === 'internal_comm') {
      this.selectedInteractionFilter = [val];
    }
  }

  get mode(): HISTORY_DIALOG_MODES {
    return this._mode;
  }

  openAddNote(): void {
    if (this.interaction.inquiries.length > 1) {
      const modalRef = this.modalService.open(SelectInquiryComponent);
      modalRef.componentInstance.interaction = this.interaction;
      modalRef.componentInstance.titleTranslationKey = 'SELECT_QUESTION_FOR_NOTE';
      modalRef.componentInstance.noSelectedEnquiryTranslationKey = 'ADD_NOTE_FOR_WHOLE_ENQUIRY';
      modalRef.afterClosed().subscribe((inquiry) => {
        this.addNote(inquiry);
      });
    } else {
      this.addNote();
    }
  }

  refreshInteraction(): void {
    this.singleInteractionService.refreshInteraction(this.interaction.id, true);
  }

  private addNote(inquiry?: BInquiry): void {
    const modalRef = this.modalService.open(DetailHistoryEditComponent);
    modalRef.componentInstance.interaction = this.interaction;
    modalRef.componentInstance.inquiry = inquiry;
    modalRef.componentInstance.isNote = true;
    this.subs.add(
      modalRef.componentInstance.historyChanged.subscribe(() => {
        this.reloadInteractionHistory();
        this.refreshInteraction();
      })
    );
  }

  private reloadInteractionHistory(): void {
    this.isInteractionHistoryLoading = true;
    this.subs.add(
      this.interactionsService
        .getHistory(this.interaction.pk(), this.mode === 'internal_comm')
        .subscribe({
          next: (resp) => {
            this.isInteractionHistoryLoading = false;
            this.interactionHistory = resp;
          },
          error: (error) => {
            this.isInteractionHistoryLoading = false;
            this.messageService.httpError('Load History', error);
          },
        })
    );
  }

  private loadInquirerHistory(): void {
    if (this.mode === 'internal_comm' || !this.interaction.inquirer?.id) {
      return;
    }
    this.isInquirerHistoryLoading = true;
    this.subs.add(
      this.historyInquirerDataService
        .getHistory(this.interaction.inquirer)
        .subscribe((historyData) => {
          this.isInquirerHistoryLoading = false;
          this.inquirerHistory = historyData;
        })
    );
  }

  private getHistoryMessage(): void {
    if (this.historyObject.mergedFromInteractionId || this.historyObject.category === 'error') {
      return;
    }

    this.interactionsService.getHistoryMessage(this.historyObject.id).subscribe({
      next: (message) => {
        this.historyMessage = message;
      },
      error: (error) => {
        this.messageService.httpError(
          'An error occurred while fetching the history message',
          error
        );
      },
    });
  }
}
