import { Component, Input, Optional } from '@angular/core';
import { BTeam, BUser } from 'app/modules/data-model/user/user';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  newMDApprovalPeriodConfig,
  newMDApprovalTypeConfig,
  newMDChangeRequestedActionConfig,
  newUserIdConfig,
} from 'app/common/common/search-select/search-select.config';
import {
  BApprovalType,
  BChangeRequestedActionType,
  BDocumentWorkflowStep,
  BDocumentWorkflowTemplate,
  TDocumentsApprovalWorkflowDialogResult,
  TWorkflowData,
} from 'app/modules/data-model/medical-documents-approval/medical-documents-approval';
import {
  NomenclaturesService,
  Scopes,
} from 'app/modules/data-model/nomenclature/nomenclatures.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CustomValidators } from '@mi-tool/utils/custom-validators';
import { GenericDialogComponent } from 'app/common/common/generic-dialog/generic-dialog.component';
import { debounceTime } from 'rxjs/operators';
import { isEqual, isNil, omit, omitBy, sortBy, zipObject } from 'lodash';
import { MedicalDocumentsApprovalService } from 'app/modules/data-model/medical-documents-approval/medical-documents-approval.service';
import { MessageHandlerService } from 'app/common/common/message-handler/message-handler.service';
import { simpleText } from '@mi-tool/consts';
import { EDialogStatuses } from 'app/common/common/enums/dialog.enum';
import { EView } from '@mi-tool/enums';
import { DIALOG_STATUSES_DRAFT_AND_SUBMIT_FOR_APPROVAL } from 'app/common/common/constants/statuses';
import { DirtyTouchedMatcher } from 'app/common/error-state-matchers';

@Component({
  selector: 'app-documents-approval-workflow',
  templateUrl: './documents-approval-workflow.component.html',
  styleUrls: ['./documents-approval-workflow.component.scss'],
})
export class DocumentsApprovalWorkflowComponent {
  _team: BTeam;
  documentAuthor: BUser = null; // Only for document view we will have user, so we can populate author in the first step of the workflow.
  viewEnum = EView;
  view: EView.TEAM | EView.DOCUMENT = EView.TEAM;

  workflowForm: UntypedFormGroup = null;
  matcher = new DirtyTouchedMatcher();
  minSteps = 2;
  maxSteps = 6;
  maxSubSteps = 6;
  maxOrder = 0;

  medicalDocumentApprovalPeriods = [
    { id: 1, name: '1 day' },
    { id: 2, name: '2 days' },
    { id: 3, name: '3 days' },
    { id: 4, name: '4 days' },
    { id: 5, name: '5 days' },
    { id: 6, name: '6 days' },
    { id: 7, name: '7 days' },
  ];
  approvalPeriodConfig = newMDApprovalPeriodConfig(
    this.medicalDocumentApprovalPeriods,
    'DOCUMENTS_APPROVAL_WORKFLOW.APPROVAL_DEADLINE'
  );
  medicalDocumentChangeRequestedActionTypes: BChangeRequestedActionType[] = [];
  actionIdsToExcludeForFinalApprovalType = [1]; // Exclude option 'Continue' for final approval type.
  stepPropertiesOnlyForFE = [
    'approvalTypeConfig',
    'reviewerConfig',
    'approveWithChangesActionConfig',
    'rejectActionConfig',
  ] as const;

  allApprovalTypes: BApprovalType[] = [];
  allUsers: BUser[] = [];

  approvalTypes: BApprovalType[] = null;
  initialApprovalType: BApprovalType = null;
  finalApprovalType: BApprovalType = null;
  approvalTypeIdToReviewers: { number: BUser[] } = null;
  approvalTypeIdsWithoutReviewers: number[] = [];
  usedApprovalTypeIds: number[] = [];
  showWorkflowForm = false;
  hasNomenclatureData = false;
  workflowNameUnique = true;
  hasUsersWithDocumentApprovalRole = true;
  dialogStatusesEnum = EDialogStatuses;

  existingWorkflowTemplates: BDocumentWorkflowTemplate[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private nomenclatures: NomenclaturesService,
    private matDialog: MatDialog,
    private mdApprovalService: MedicalDocumentsApprovalService,
    private messageService: MessageHandlerService,
    @Optional() public dialogRef: MatDialogRef<DocumentsApprovalWorkflowComponent>
  ) {}

  @Input() set team(team: BTeam) {
    this._team = team;

    if (this.hasNomenclatureData) {
      this.handleTeamChange();
      return;
    }
    this.nomenclatureDataFetch();
  }

  get team(): BTeam {
    return this._team;
  }

  get stepsArray(): BDocumentWorkflowStep[] {
    return this.workflowForm.value.steps;
  }

  get stepsAsFormArray(): UntypedFormArray {
    return this.workflowForm.get('steps') as UntypedFormArray;
  }

  workflowFormCleanedValue(): TWorkflowData {
    return omitBy(
      {
        ...this.workflowForm.value,
        steps: sortBy(
          this.workflowForm.value.steps.map((step) =>
            omitBy(omit(step, this.stepPropertiesOnlyForFE), isNil)
          ),
          'order'
        ),
      },
      isNil
    ) as any;
  }

  private handleTeamChange(): void {
    this.workflowTemplatesFetch();
    this.prepDataForCurrentTeam();

    if (!this.workflowForm) {
      this.createFormAndSubsForChanges();
      return;
    }
    this.resetWorkflowForm();
  }

  private createFormAndSubsForChanges(): void {
    this.workflowForm = this.formBuilder.group({
      id: new UntypedFormControl(null),
      name: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(simpleText),
        Validators.maxLength(100),
      ]),
      teamId: new UntypedFormControl(this.team.pk()),
      documentId: new UntypedFormControl(), // Remove if not needed.
      steps: new UntypedFormArray([], [Validators.minLength(this.minSteps)]),
    });

    this.workflowForm.controls['name'].valueChanges
      .pipe(debounceTime(600))
      .subscribe((workflowName) => {
        this.workflowForm.controls['name'].setValue(workflowName.trim(), { emitEvent: false });
        this.workflowNameValidate();
      });

    this.initialStepsCreate();
  }

  workflowTemplateCreate(): void {
    this.resetWorkflowForm();
    this.showWorkflowForm = true;
  }

  resetWorkflowForm(initialStepsCreate = true): void {
    this.workflowNameUnique = true;
    this.stepsAsFormArray.clear();
    if (!initialStepsCreate) {
      return;
    }
    this.workflowForm.reset({ teamId: this.team.pk() }, { emitEvent: false });
    this.initialStepsCreate();
  }

  private initialStepsCreate(): void {
    this.handleWorkflowStepCreate(0);
    this.handleWorkflowStepCreate(1);
  }

  private canAddStep(stepOrder: number, addingMainStep: boolean): boolean {
    if (!this.workflowForm.valid) {
      this.messageService.info('DOCUMENTS_APPROVAL_WORKFLOW.FILL_ALL_REQUIRED_FIELDS');
      return false;
    }

    if (addingMainStep) {
      if (this.maxSteps === this.maxOrder + 1) {
        this.messageService.info('DOCUMENTS_APPROVAL_WORKFLOW.STEP_LIMIT_REACHED', {
          MAX_STEPS: this.maxSteps,
        });
        return false;
      }
      return true;
    }

    const subStepsCount = this.stepsArray.filter((step) => step.order === stepOrder).length;
    if (this.maxSubSteps === subStepsCount) {
      this.messageService.info('DOCUMENTS_APPROVAL_WORKFLOW.STEP_LIMIT_REACHED', {
        MAX_STEPS: this.maxSubSteps,
      });
      return false;
    }

    this.updateUsedApprovalTypeIds(stepOrder);
    if (this.approvalTypes.length === this.usedApprovalTypeIds.length) {
      this.messageService.info('DOCUMENTS_APPROVAL_WORKFLOW.ALL_APPROVAL_TYPES_USED');
      return false;
    }
    return true;
  }

  handleWorkflowStepCreate(
    stepOrder: number,
    manuallyCreatingStep = false,
    addingMainStep = false
  ): void {
    const stepInsertedBetweenSteps = addingMainStep && this.maxOrder >= stepOrder;

    if (manuallyCreatingStep && !this.canAddStep(stepOrder, addingMainStep)) {
      return;
    }

    if (stepInsertedBetweenSteps) {
      // Increment steps order
      this.workflowForm.controls['steps']['controls'].forEach((step) => {
        if (step.value.order < stepOrder) {
          return;
        }
        step.controls['order'].setValue(++step.value.order);
      });
    }
    // Create new step
    this.stepsAsFormArray.push(this.workflowStepCreate(stepOrder));
    this.maxOrder = Math.max(...this.stepsArray.map((step) => step.order));
    this.updateApprovalTypeDropdowns();
  }

  private workflowStepCreate(stepOrder: number): UntypedFormGroup {
    let initialApprovalTypeId = null;
    let approvalTypes = [];
    let reviewers = [];
    let reviewerIds = [];
    let validatorsArray = [Validators.required];

    // First step always should be submit for approval.
    if (stepOrder === 0) {
      initialApprovalTypeId = this.initialApprovalType.id;
      approvalTypes = [this.initialApprovalType];
      reviewerIds = this.approvalTypeIdToReviewers[initialApprovalTypeId].map((reviewer) =>
        reviewer.pk()
      );
      validatorsArray = [];

      if (this.view === this.viewEnum.DOCUMENT) {
        reviewers = [this.documentAuthor];
        reviewerIds = [this.documentAuthor.pk()];
      }
    }

    return this.formBuilder.group({
      approvalTypeId: new UntypedFormControl(initialApprovalTypeId, [Validators.required]),
      reviewerIds: new UntypedFormControl(reviewerIds, [
        Validators.required,
        CustomValidators.truthyArrayValues,
      ]),
      approvalPeriod: new UntypedFormControl(null, validatorsArray),
      actionOnRejectId: new UntypedFormControl(null, validatorsArray),
      actionOnApproveWithChangesId: new UntypedFormControl(null, validatorsArray),
      order: new UntypedFormControl(stepOrder),
      approvalTypeConfig: new UntypedFormControl(
        newMDApprovalTypeConfig(approvalTypes, 'DOCUMENTS_APPROVAL_WORKFLOW.SELECT_APPROVAL_TYPE')
      ),
      reviewerConfig: new UntypedFormControl(
        newUserIdConfig(
          reviewers,
          reviewers.length
            ? 'DOCUMENTS_APPROVAL_WORKFLOW.SELECT_REVIEWERS'
            : 'DOCUMENTS_APPROVAL_WORKFLOW.SELECT_APPROVAL_TYPE_FIRST'
        )
      ),
      approveWithChangesActionConfig: new UntypedFormControl(
        newMDChangeRequestedActionConfig(
          [],
          'DOCUMENTS_APPROVAL_WORKFLOW.SELECT_APPROVAL_TYPE_FIRST'
        )
      ),
      rejectActionConfig: new UntypedFormControl(
        newMDChangeRequestedActionConfig(
          [],
          'DOCUMENTS_APPROVAL_WORKFLOW.SELECT_APPROVAL_TYPE_FIRST'
        )
      ),
    });
  }

  handleWorkflowStepDelete(stepGroup: UntypedFormGroup): void {
    let stepsArray = this.stepsAsFormArray;
    const stepIndex = stepsArray.controls.findIndex((step) => isEqual(step, stepGroup));
    if (this.minSteps === stepsArray.length || stepIndex === -1) {
      return;
    }
    // Delete step
    stepsArray.removeAt(stepIndex);

    const hasStepWithSameOrder = this.stepsArray.some(
      (step) => step.order === stepGroup.value.order
    );

    if (!hasStepWithSameOrder) {
      // Decrement steps order
      this.workflowForm.controls['steps']['controls'].forEach((step) => {
        if (step.value.order > stepGroup.value.order) {
          step.controls['order'].setValue(--step.value.order, { emitEvent: false });
        }
      });
    }
    this.maxOrder = Math.max(...this.stepsArray.map((step) => step.order));
    this.updateApprovalTypeDropdowns();
  }

  handleValueChange(
    step: UntypedFormGroup,
    formControlName: string,
    formControlValue: number | number[] | boolean
  ): void {
    step.controls[formControlName].setValue(formControlValue, { emitEvent: false });

    if (formControlName === 'approvalTypeId') {
      // On approval type change remove selected users, actions and filter related dropdowns.
      step.controls['reviewerIds'].setValue([], { emitEvent: false });
      step.controls['actionOnApproveWithChangesId'].setValue(null, { emitEvent: false });
      step.controls['actionOnRejectId'].setValue(null, { emitEvent: false });
      // @ts-ignore
      this.filterOutDropdowns(step, formControlValue);
      this.updateApprovalTypeDropdowns();
    }
  }

  filterOutDropdowns(step: UntypedFormGroup, selectedApprovalTypeId: number | undefined): void {
    step.controls['reviewerConfig'].setValue(
      newUserIdConfig(
        selectedApprovalTypeId ? this.approvalTypeIdToReviewers[selectedApprovalTypeId] : [],
        selectedApprovalTypeId
          ? 'DOCUMENTS_APPROVAL_WORKFLOW.SELECT_REVIEWERS'
          : 'DOCUMENTS_APPROVAL_WORKFLOW.SELECT_APPROVAL_TYPE_FIRST'
      ),
      { emitEvent: false }
    );

    const actions = !selectedApprovalTypeId
      ? []
      : selectedApprovalTypeId === this.finalApprovalType.id
      ? this.medicalDocumentChangeRequestedActionTypes.filter(
          (action) => !this.actionIdsToExcludeForFinalApprovalType.includes(action.id)
        )
      : this.medicalDocumentChangeRequestedActionTypes;

    step.controls['approveWithChangesActionConfig'].setValue(
      newMDChangeRequestedActionConfig(
        actions,
        selectedApprovalTypeId
          ? 'DOCUMENTS_APPROVAL_WORKFLOW.ACTION_ON_APPROVE_WITH_CHANGES'
          : 'DOCUMENTS_APPROVAL_WORKFLOW.SELECT_APPROVAL_TYPE_FIRST'
      ),
      { emitEvent: false }
    );

    step.controls['rejectActionConfig'].setValue(
      newMDChangeRequestedActionConfig(
        actions,
        selectedApprovalTypeId
          ? 'DOCUMENTS_APPROVAL_WORKFLOW.ACTION_ON_REJECT'
          : 'DOCUMENTS_APPROVAL_WORKFLOW.SELECT_APPROVAL_TYPE_FIRST'
      ),
      { emitEvent: false }
    );
  }

  private workflowNameValidate(): void {
    this.workflowNameUnique = !this.existingWorkflowTemplates.some(
      (template) =>
        template.id != this.workflowForm.value.id &&
        template.name.trim() === this.workflowForm.value.name
    );
  }

  private updateApprovalTypeDropdowns(): void {
    this.workflowForm.controls['steps']['controls'].forEach((step) => {
      if (step.value.order === 0) {
        return;
      }

      this.updateUsedApprovalTypeIds(step.value.order);
      let availableApprovalTypes = this.approvalTypes.filter(
        (approvalType) => !this.usedApprovalTypeIds.includes(approvalType.id)
      );
      if (step.value.approvalTypeId) {
        availableApprovalTypes.unshift(
          this.approvalTypes.find((approvalType) => approvalType.id === step.value.approvalTypeId)
        );
      }

      step.controls['approvalTypeConfig'].setValue(
        newMDApprovalTypeConfig(
          [...new Set(availableApprovalTypes)],
          'DOCUMENTS_APPROVAL_WORKFLOW.SELECT_APPROVAL_TYPE'
        ),
        { emitEvent: false }
      );
    });
  }

  private updateUsedApprovalTypeIds(stepOrder: number): void {
    this.usedApprovalTypeIds = [...this.approvalTypeIdsWithoutReviewers];
    const stepsArray = this.stepsArray;
    const hasOneStepForOrder = stepsArray.filter((step) => step.order === stepOrder).length === 1;
    if (this.maxOrder > stepOrder || !hasOneStepForOrder) {
      this.usedApprovalTypeIds.push(this.finalApprovalType.id);
    }
    stepsArray.forEach((step) => {
      if (
        (step.approvalTypeId && step.order === stepOrder && !hasOneStepForOrder) ||
        this.initialApprovalType.id === step.approvalTypeId
      ) {
        this.usedApprovalTypeIds.push(step.approvalTypeId);
      }
    });
  }

  applyExistingWorkflowTemplate(workflowTemplate): void {
    this.resetWorkflowForm(false);
    this.workflowForm.reset(
      {
        id: workflowTemplate.id,
        name: workflowTemplate.name,
        teamId: workflowTemplate.teamId,
        documentId: workflowTemplate?.documentId,
      },
      { emitEvent: false }
    );

    const stepProperties = [
      'approvalTypeId',
      'reviewerIds',
      'approvalPeriod',
      'actionOnApproveWithChangesId',
      'actionOnRejectId',
      'order',
    ];
    workflowTemplate.steps.forEach((step, stepIndex) => {
      this.handleWorkflowStepCreate(step.order);
      if (step.order === 0) {
        return;
      }
      const stepGroup = this.stepsAsFormArray.controls[stepIndex] as UntypedFormGroup;
      stepProperties.forEach((stepProperty) => {
        this.handleValueChange(stepGroup, stepProperty, step[stepProperty]);
      });
    });
    this.showWorkflowForm = true;
  }

  private workflowTemplatesFetch(): void {
    this.mdApprovalService.workflowTemplatesFetch(this.team.pk()).subscribe({
      next: (existingWorkflowTemplates) => {
        this.existingWorkflowTemplates = existingWorkflowTemplates;

        if (this.view === this.viewEnum.DOCUMENT && this.existingWorkflowTemplates.length === 0) {
          this.openApprovalWorkflowsMsg();
        }
      },
      error: () => {
        this.messageService.error('An error occurred while fetching the workflow templates.');
      },
    });
  }

  isWorkflowFormValid(): boolean {
    if (!this.workflowForm.valid || !this.workflowNameUnique) {
      this.messageService.error('Invalid form submission attempt!');
      return false;
    }

    const lastStepContainsSingleApprovalType =
      this.stepsArray.filter((step) => step.order === this.maxOrder).length === 1;
    if (!lastStepContainsSingleApprovalType) {
      this.messageService.info(
        'DOCUMENTS_APPROVAL_WORKFLOW.ONLY_SINGLE_APPROVAL_STEP_CAN_BE_LISTED_AS_FINAL'
      );
      return false;
    }
    return true;
  }

  handleWorkflowTemplateCreate(): void {
    if (!this.isWorkflowFormValid()) {
      return;
    }

    const workflowData = this.workflowFormCleanedValue();
    const isTemplateUpdate =
      workflowData.id &&
      this.existingWorkflowTemplates.some(
        (template) =>
          template.id === workflowData.id && template.name.trim() === workflowData.name.trim()
      );
    if (isTemplateUpdate) {
      this.mdApprovalService.workflowTemplateUpdate(workflowData).subscribe({
        next: () => {
          this.resetWorkflowForm();
          this.workflowTemplatesFetch();
          this.messageService.info('The workflow template has been successfully updated!');
        },
        error: () => {
          this.messageService.error('An error occurred while updating the workflow template.');
        },
      });
      return;
    }
    this.mdApprovalService.workflowTemplateCreate(workflowData).subscribe({
      next: () => {
        this.resetWorkflowForm();
        this.workflowTemplatesFetch();
        this.messageService.info('The workflow template has been successfully created!');
      },
      error: () => {
        this.messageService.error('An error occurred while creating the workflow template.');
      },
    });
  }

  handleWorkflowTemplateArchive(): void {
    new GenericDialogComponent.Builder(this.matDialog, { autoFocus: 'dialog' })
      .title('DOCUMENTS_APPROVAL_WORKFLOW.CONFIRMATION_TITLE')
      .message('DOCUMENTS_APPROVAL_WORKFLOW.CONFIRMATION_ROW_1', { NAME: this.team.name })
      .additionalMessage('DOCUMENTS_APPROVAL_WORKFLOW.CONFIRMATION_ROW_2')
      .cancelLabel('NO')
      .saveLabel('YES')
      .closeOnButton()
      .onSave(() => {
        this.mdApprovalService.workflowTemplateArchive(this.workflowForm.value.id).subscribe({
          next: () => {
            this.resetWorkflowForm();
            this.workflowTemplatesFetch();
            this.messageService.info('The workflow template has been successfully deleted!');
          },
          error: () => {
            this.messageService.error('An error occurred while deleting the workflow template.');
          },
        });
      });
  }

  private nomenclatureDataFetch(): void {
    this.nomenclatures
      .get(
        new Scopes()
          .activeDocProcessingUsers()
          .medicalDocumentApprovalStepTypes()
          .medicalDocumentChangeRequestedActionTypes()
      )
      .subscribe((data) => {
        this.allUsers = data.users;
        this.allApprovalTypes = data.medicalDocumentApprovalStepTypes;
        this.medicalDocumentChangeRequestedActionTypes =
          data.medicalDocumentChangeRequestedActionTypes;

        this.hasNomenclatureData = true;
        this.handleTeamChange();
      });
  }

  private prepDataForCurrentTeam(): void {
    this.approvalTypes = BApprovalType.filterApprovalTypesForTeam(
      this.team.code,
      this.allApprovalTypes
    );

    const { initialApprovalType, finalApprovalType } = BApprovalType.getInitialAndFinalApprovalType(
      this.approvalTypes
    );
    this.initialApprovalType = initialApprovalType;
    this.finalApprovalType = finalApprovalType;

    this.approvalTypeIdToReviewers = zipObject(
      this.approvalTypes.map((approvalType) => approvalType.id),
      this.approvalTypes.map((approvalType) => {
        const approvalTypeRoleIds = approvalType.reviewerRoles.map((role) => role.id);

        return this.allUsers.filter((user) =>
          user.affiliations.some(
            (affiliation) =>
              affiliation.team.id === this.team.id &&
              approvalTypeRoleIds.includes(affiliation.role.id)
          )
        );
      })
    ) as any;
    this.approvalTypeIdsWithoutReviewers = Object.keys(this.approvalTypeIdToReviewers)
      .filter((objectKey) => this.approvalTypeIdToReviewers[objectKey].length === 0)
      .map(Number);

    this.hasUsersWithDocumentApprovalRole =
      this.approvalTypes.length != this.approvalTypeIdsWithoutReviewers.length;
  }

  handleDialogClosing(status: EDialogStatuses): void {
    if (
      DIALOG_STATUSES_DRAFT_AND_SUBMIT_FOR_APPROVAL.includes(status) &&
      !this.isWorkflowFormValid()
    ) {
      return;
    }
    const dialogResultData: TDocumentsApprovalWorkflowDialogResult = {
      status,
      ...(DIALOG_STATUSES_DRAFT_AND_SUBMIT_FOR_APPROVAL.includes(status) && {
        approvalWorkflow: this.workflowFormCleanedValue(),
      }),
    };
    this.dialogRef.close(dialogResultData);
  }

  private openApprovalWorkflowsMsg(): void {
    const dialogRef = GenericDialogComponent.openApprovalWorkflowsMsg(this.matDialog);
    dialogRef.componentInstance.response.subscribe(() => {
      dialogRef.close();
      this.dialogRef && this.handleDialogClosing(this.dialogStatusesEnum.BACK);
    });
  }
}
