import { MedisNavigationItem, newGroup, newItem } from '../types';
import { userRoles as roles } from '../consts';

export function userProfileNavigation(userRoles: string[]): MedisNavigationItem[] {
  const profileNavigation: MedisNavigationItem[] = [
    newGroup('User_profile', 'User_profile', 'account_circle', [
      newItem('Profile_settings', 'CONTACT_INFORMATION', 'fa-user-circle'),
      newItem('Preferences', 'MedIS_Out_Of_Office', 'fa-plane-departure'),
      newItem('Auto_assign', 'AUTO_ASSIGNMENTS', 'fa-share'),
      newItem('Password_reset', 'PASSWORD_RESET', 'fa-sync-alt'),
      newItem('Role_settings', 'Role_settings', 'fa-user-graduate', null, null, null, [
        ...roles.sysAdmin,
      ]),
      newItem('Notification_settings', 'Notification_settings', 'fa-bell'),
      newItem('Template_settings', 'TEMPLATES', 'fa-file-signature'),
    ]),
  ];

  return filterNavigationItemsByRoles(profileNavigation, userRoles);
}

export function teamNavigation(userRoles: string[]): MedisNavigationItem[] {
  const teamNavigation: MedisNavigationItem[] = [
    newGroup('Team_profile', 'TEAM_SETTINGS', 'account_circle', [
      newGroup(
        'TEAM_SET_UP',
        '',
        '',
        [
          newItem('Team_edit', 'TEAM_SET_UP', 'fa-edit'),
          newItem('List_teams', 'LIST_TEAMS', 'fa-file-signature ms-4'),
          newItem('Companies', 'COMPANIES', 'fa-users ms-4'),
          newItem('Domains', 'DOMAINS', 'fa-user-tag ms-4'),
        ],
        [...roles.sysAdmin]
      ),
      newItem('Team_template_settings', 'TEMPLATES', 'fa-file-signature'),
      newItem('Team_members', 'TEAM_MEMBERS', 'fa-users'),
      newItem('User_templates', 'USER_TEMPLATES', 'fa-user-tag'),
      newItem(
        'External_comm',
        'RECEIPT_AND_SALES_NOTIFICATIONS',
        'fa-satellite-dish',
        null,
        null,
        null,
        [...roles.sysAdmin]
      ),
      newItem('Departments', 'DEPARTMENTS', 'fa-landmark', null, null, null, [...roles.sysAdmin]),
      newItem('Documents_approval', 'DOCUMENTS_APPROVAL', 'fa-file-signature', null, null, null, [
        ...roles.sysAdmin,
      ]),
      newItem('Follow_up', 'FOLLOW_UP', 'fa-reply', null, null, null, [...roles.sysAdmin]),
    ]),
  ];

  return filterNavigationItemsByRoles(teamNavigation, userRoles);
}

export function toolbarNavigation(userRoles: string[]): MedisNavigationItem[] {
  return userProfileNavigation(userRoles).find(
    (navigationGroup) => navigationGroup.id === 'User_profile'
  ).children;
}

export function isEligible(navigationItem: MedisNavigationItem, userRoles: string[]): boolean {
  return Boolean(
    !userRoles ||
      !navigationItem.userRoles ||
      navigationItem.userRoles.filter((neededRole) => userRoles.includes(neededRole)).length
  );
}

export function filterNavigationItemsByRoles(
  navigationItems: MedisNavigationItem[],
  userRoles: string[]
): MedisNavigationItem[] {
  return navigationItems.filter((navigationGroup) => {
    navigationGroup.children = navigationGroup.children.filter((navigationItem) =>
      isEligible(navigationItem, userRoles)
    );
    return isEligible(navigationGroup, userRoles) && navigationGroup.children.length;
  });
}
