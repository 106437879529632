import { Injectable } from '@angular/core';
import urlJoin from 'url-join';
import { environment } from '../../environments/environment';

@Injectable()
export class UrlResolverService {
  apiUrlForPath(urlComponents: string[] = []): string {
    return urlJoin(environment.apiBaseUrl, ...urlComponents);
  }

  apiUrlForHost(host: string, ...urlComponents: (string | number)[]): string {
    return urlJoin(host, 'server', 'api', ...urlComponents.map((c) => c + ''));
  }

  /**
   * @deprecated - uses string concatenation and no actual URL escpaing and normalization is done
   *
   * @use UrlResolverService#apiUrl
   */
  misApiUrlForPath(urlComponents: string): string {
    return urlJoin(environment.apiBaseUrl).concat('api').concat(urlComponents);
  }

  apiUrl(...urlComponents: (string | number)[]): string {
    return urlJoin(environment.apiBaseUrl, 'api', ...urlComponents.map((c) => c + ''));
  }

  localUrlForPath(urlComponents: string[] = []): string {
    return urlJoin(environment.localBaseUrl, ...urlComponents);
  }
}
