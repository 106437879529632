import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';
import { parseAttr, parseArray, pkToBase64 } from '../data-model';
import {
  mutateUpdateMedicalDocument,
  searchAllMedicalDocuments,
  retrieveMedDocInformation,
  createDocumentWithApproval,
  executeDocumentAction,
  createMedicalDocumentVersion,
} from './queries';
import { createGuid } from '../../../common/uuid-generator';
import { BMedicalDocument } from './medical-document';
import { MessageHandlerService } from 'app/common/common/message-handler/message-handler.service';

@Injectable({
  providedIn: 'root',
})
export class MedicalDocumentService {
  updates: Subject<Object> = new Subject<Object>();
  response: Subject<BMedicalDocument> = new Subject<BMedicalDocument>();
  createResponse: Subject<BMedicalDocument> = new Subject<BMedicalDocument>();
  actionResponse: Subject<any> = new Subject<any>();

  constructor(private apollo: Apollo, private messageService: MessageHandlerService) {
    this.updates.subscribe((update) => {
      this.mutateMedicalDocument(update);
    });
  }

  private _genericQueryWithParams(data: any, query: any) {
    return this.apollo.mutate({
      mutation: query,
      variables: { params: data },
    });
  }

  private _parseAttrResponse(response: any, mutationField: string): BMedicalDocument {
    if (response && response['data']) {
      let responseData = response['data'][mutationField];
      return parseAttr<BMedicalDocument>(responseData, BMedicalDocument, 'medicalDocument');
    } else {
      this.messageService.error('Document Edit: empty response');
      return undefined;
    }
  }

  private handleMutationError(error: any) {
    this.messageService.error('An error occurred');
    console.error('error: ', error);
    this.createResponse.next(undefined);
  }

  mutateMedicalDocument(update: any) {
    console.warn('mutateMedicalDocument is depracated, editDocumentWithApproval');
    // DEPRECATED
    let docUpd = Object.assign({}, update);
    docUpd['clientMutationId'] = createGuid();
    //Access with [0] as Server expects only one value!
    if (update['typeId']) {
      docUpd['typeId'] = update['typeId'][0];
    }
    if (update['ownerRegionId']) {
      docUpd['ownerRegionId'] = update['ownerRegionId'][0];
    }
    if (update['languageId']) {
      docUpd['languageId'] = update['languageId'][0];
    }
    if (update['targetAudienceId']) {
      docUpd['targetAudienceId'] = update['targetAudienceId'];
    }
    let mutation = this.apollo.mutate({
      mutation: mutateUpdateMedicalDocument,
      variables: { params: docUpd },
    });
    mutation.subscribe(
      (response) => {
        //console.log(response);
        if (response) {
          let mdData = response.data['updateMedicaldocument'];
          let md = parseAttr<BMedicalDocument>(mdData, BMedicalDocument, 'medicalDocument');
          this.response.next(md);
        }
      },
      (error) => {
        console.error('medical-document.service: ', error);
      }
    );
  }

  createDocumentWithApproval(data: Object) {
    let mutateData = Object.assign({}, data);
    mutateData['clientMutationId'] = createGuid();
    this._genericQueryWithParams(mutateData, createDocumentWithApproval).subscribe(
      (response) => {
        this.createResponse.next(this._parseAttrResponse(response, 'createApprovalProcess'));
      },
      (error) => {
        this.handleMutationError(error);
      }
    );
  }

  createVersion(data: Object) {
    let mutateData = Object.assign({}, data);
    mutateData['clientMutationId'] = createGuid();
    this._genericQueryWithParams(mutateData, createMedicalDocumentVersion).subscribe(
      (response) => {
        this.createResponse.next(this._parseAttrResponse(response, 'createMedicalDocumentVersion'));
      },
      (error) => {
        this.handleMutationError(error);
      }
    );
  }

  executeActionForVersion(data: Object) {
    let mutateData = Object.assign({}, data);
    mutateData['clientMutationId'] = createGuid();

    let mutation = this.apollo.mutate({
      mutation: executeDocumentAction,
      variables: { params: mutateData },
    });
    mutation.subscribe((response) => {
      this.actionResponse.next(response);
    });
  }
}

@Injectable()
export class SearchMedicalDocumentService {
  filters: Subject<Object> = new Subject<Object>();
  response: Subject<BMedicalDocument[]> = new Subject<BMedicalDocument[]>();

  constructor(private apollo: Apollo) {
    this.filters.subscribe((jsonFilters) => {
      this.searchWithFilters(jsonFilters);
    });
  }

  searchWithFilters(jsonFilters: Object) {
    let search = this.apollo.query({
      query: searchAllMedicalDocuments,
      variables: { filters: JSON.stringify(jsonFilters) },
    });
    search.subscribe(
      (response) => {
        if (response) {
          let medDocs = parseArray<BMedicalDocument>(
            response.data,
            BMedicalDocument,
            'allMedicalDocument'
          );
          this.response.next(medDocs);
        }
      },
      (error) => {
        console.error('medical-document.service: ', error);
      }
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class SingleMedicalDocumentService {
  response: Subject<BMedicalDocument> = new Subject<BMedicalDocument>();

  constructor(private apollo: Apollo, private messageService: MessageHandlerService) {}

  performQueryWithID(realId: number) {
    let pk = pkToBase64('MedicalDocumentNode', realId);
    this.performQuery(pk);
  }

  performQuery(pk: string) {
    this.apollo
      .query({
        query: retrieveMedDocInformation,
        variables: {
          id: pk,
        },
        fetchPolicy: 'network-only',
      })
      .subscribe({
        next: (response) => {
          if (response) {
            let medDoc = parseAttr<BMedicalDocument>(
              response.data,
              BMedicalDocument,
              'medicaldocument'
            );
            this.response.next(medDoc);
          } else {
            this.messageService.error('Empty response for Medical Document');
          }
        },
        error: (error) => {
          this.messageService.error('(SingleMedicalDocumentService) ' + error);
        },
      });
  }
}
