import { gql } from 'apollo-angular';

import { base64ToPK, BaseModel, parseArray, parseAttr, rProperty } from '../data-model';
import { BProduct } from '../region/region';
import { BCategory } from '../category/category';

export class BTopic extends BaseModel {
  @rProperty() id: string;
  @rProperty() name: string;
  @rProperty() type: string;
  @rProperty() isActive: boolean;
  @rProperty() isProductSpecific: boolean;
  @rProperty() categoryId: string;
  @rProperty() importedId: string;
  @rProperty() importedSource: string;
  @rProperty(Date) importedTs: string;

  products: BProduct[];
  category: BCategory;

  constructor(json: any) {
    super(json);
    this.init(json);
  }

  init(json: any) {
    this.products = parseArray<BProduct>(json, BProduct, 'products');
    this.category = parseAttr<BCategory>(json, BCategory, 'category');
  }

  static fromRest(json: any): BTopic {
    if (json) {
      json.products = BProduct.fromRestArray(json.products);
      return Object.assign(new BTopic({}), json);
    }
    return json;
  }

  static fromRestArray(json: any[]): BTopic[] {
    return json && json.map((v) => BTopic.fromRest(v));
  }

  getMId() {
    return base64ToPK(this.id);
  }

  getMName() {
    return this.name;
  }
}

// fragments will contain only actual values of the object, no references to other tables
export const fragment = gql`
  fragment topicFragment on TopicNode {
    id
    name
    type
    isActive
    isProductSpecific
    categoryId
    products {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const fragmentConnection = gql`
  fragment topicConnectionFragment on TopicNodeConnection {
    edges {
      node {
        ...topicFragment
      }
    }
  }
  ${fragment}
`;
