import { gql } from 'apollo-angular';

import { base64ToPK, BaseModel, rProperty } from '../data-model';

export class BStatus extends BaseModel {
  @rProperty() id: string;
  @rProperty() stateId: string;
  @rProperty() name: string;
  @rProperty() description: string;
  @rProperty() workflow: string;
  @rProperty() isClosed: boolean;
  isActive = true;

  init(json: any) {}

  getMId() {
    return base64ToPK(this.id);
  }

  getMName() {
    return this.name;
  }
}

export const fragment = gql`
  fragment statusFragment on StatusNode {
    id
    stateId
    name
    description
    workflow
    isClosed
  }
`;

export const fragmentConnection = gql`
  fragment statusConnectionFragment on StatusNodeConnection {
    edges {
      node {
        ...statusFragment
      }
    }
  }
  ${fragment}
`;
