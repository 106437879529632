import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UrlResolverService } from 'app/common/url-resolver.service';
import {
  Department,
  ICreateDepartment,
  IDepartmentsDropdownsData,
  IQParams,
  TDepartmentsResult,
} from './departments';

@Injectable()
export class DepartmentsService {
  private readonly URL: string;

  constructor(private http: HttpClient, urlResolverService: UrlResolverService) {
    this.URL = urlResolverService.misApiUrlForPath('/departments/');
  }

  get(data: IQParams): Observable<TDepartmentsResult> {
    let params = new HttpParams();
    for (const key in data) {
      if (data[key] != '') {
        params = params.set(key, data[key]);
      }
    }
    return this.http.get<TDepartmentsResult>(this.URL, { params }).pipe(
      map((res) => {
        res.records = Department.fromRestArray(res.records);
        return res;
      })
    );
  }

  create(data: ICreateDepartment): Observable<ICreateDepartment> {
    return this.http.post<ICreateDepartment>(this.URL, data);
  }

  edit(data: ICreateDepartment): Observable<ICreateDepartment> {
    return this.http.put<ICreateDepartment>(this.URL, data);
  }

  delete(departmentId: string): Observable<string> {
    return this.http.delete<string>(this.URL + '?depId=' + departmentId);
  }

  getDepartmentsDropdownsData(): Observable<IDepartmentsDropdownsData> {
    return this.http.get<IDepartmentsDropdownsData>(this.URL + 'dropdowns/');
  }
}
