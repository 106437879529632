import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Ajax } from 'app/common/ajax';
import { UrlResolverService } from 'app/common/url-resolver.service';
import { BAnswerTemplate } from 'app/modules/data-model/user/user';

@Injectable({
  providedIn: 'root',
})
export class AnswerTemplatesService {
  private readonly URL: string;
  private readonly INTERACTION_URL: string;

  constructor(private http: HttpClient, private urlResolverService: UrlResolverService) {
    this.URL = urlResolverService.misApiUrlForPath('/templates/answers');
    this.INTERACTION_URL = urlResolverService.misApiUrlForPath('/templates/interactions');
  }

  getForInteraction(
    interactionId: number,
    isDraft: boolean,
    type: string
  ): Observable<AnswerTemplatesService.InteractionTemplates> {
    const url =
      this.INTERACTION_URL + '?id=' + interactionId + '&isDraft=' + isDraft + '&type=' + type;
    return this.http
      .get<AnswerTemplatesService.InteractionTemplates>(url, Ajax.X_NO_LOADING_OPTIONS)
      .pipe(
        map((resp) => {
          resp.interaction = BAnswerTemplate.fromRestArray(resp.interaction);
          resp.user = BAnswerTemplate.fromRestArray(resp.user);
          resp.assignee = BAnswerTemplate.fromRestArray(resp.assignee);
          resp.team = BAnswerTemplate.fromRestArray(resp.team);
          return resp;
        })
      );
  }

  replaceInInteraction(templateId: number, sourceId: number): Observable<BAnswerTemplate> {
    return this.http
      .put<string>(this.INTERACTION_URL, { id: templateId, sourceId: sourceId })
      .pipe(map((resp) => BAnswerTemplate.fromRest(resp)));
  }

  updateInInteraction(templateId: number, text: string): Observable<string> {
    return this.http.post<string>(
      this.INTERACTION_URL,
      { id: templateId, text: text },
      Ajax.X_NO_LOADING_OPTIONS
    );
  }

  allowAutomaticUpdates(templateId: number): Observable<string> {
    return this.http.delete<string>(
      this.INTERACTION_URL + '?id=' + templateId,
      Ajax.X_NO_LOADING_OPTIONS
    );
  }

  getForUser(userId: number): Observable<BAnswerTemplate[]> {
    return this.get(this.URL + '?userId=' + userId);
  }

  getForTeam(teamId: number): Observable<BAnswerTemplate[]> {
    return this.get(this.URL + '?teamId=' + teamId);
  }

  delete(id: number): Observable<string> {
    return this.http.delete<string>(this.URL + '?id=' + id, Ajax.X_NO_LOADING_OPTIONS);
  }

  copy(id: number): Observable<string> {
    return this.http.put<string>(this.URL, { id: id }, Ajax.X_NO_LOADING_OPTIONS);
  }

  createOrUpdate(template: BAnswerTemplate, userId: number = null): Observable<string> {
    return this.http.post<string>(this.URL, {
      id: template.id && template.pk(),
      teamId: template.team && template.team.pk(),
      userId: userId,
      type: template.type,
      name: template.name,
      text: template.text,
      languageId: template.language && template.language.pk(),
      countryIds: (template.countries && template.countries.map((c) => c.pk())) || [],
      productIds: template.products && template.products.map((p) => p.pk()),
      hcp: template.hcp,
      offLabel: template.offLabel,
      position: template.position,
    });
  }

  getPdfSrc(id: string): string {
    return this.urlResolverService.apiUrlForPath([
      'api-rest',
      'repository',
      'browser',
      'export-answer-pdf',
      `?teamId=${id}`,
    ]);
  }

  private get(url: string): Observable<BAnswerTemplate[]> {
    return this.http
      .get<BAnswerTemplate[]>(url, Ajax.X_NO_LOADING_OPTIONS)
      .pipe(map((rawTemplates) => BAnswerTemplate.fromRestArray(rawTemplates)));
  }
}

export namespace AnswerTemplatesService {
  export type InteractionTemplates = {
    interaction: BAnswerTemplate[];
    user: BAnswerTemplate[];
    assignee: BAnswerTemplate[];
    team: BAnswerTemplate[];
  };
}
