import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BRelatedContent } from 'app/modules/data-model/entity/entity';
import {
  BInteraction,
  EntitySearchParams,
  RelatedContentService,
  SingleInteractionService,
} from 'app/modules/data-model/data-model.module';
import { Subscription } from 'rxjs';
import { DebounceSubject } from 'app/common/util/rx';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'app/auth/auth.service';
import {
  InteractionMergeService,
  MergeData,
  MergeReason,
} from 'app/modules/data-model/interaction/interaction-merge.service';

import {
  MERGE_REASON_CHOICES,
  MERGE_REASONS,
  MERGE_REASONS_DEFAULT_REOPEN_MAP,
  MERGE_REASONS_WITHOUT_REOPEN,
} from '@mi-tool/consts';

@Component({
  selector: 'app-merge-selection',
  templateUrl: './merge-selection.component.html',
  styleUrls: ['./merge-selection.component.scss'],
})
export class MergeSelectionComponent implements OnInit, OnDestroy {
  inquiryView: boolean = false;
  showReopenCheckbox: boolean = true;
  isReopenDisabled: boolean = false;
  isMergeSuggestion: boolean = false;
  hasClarificationQuestionWithoutAnswer: boolean = false;
  textSearch = new DebounceSubject<string>();
  displayedColumns = ['title', 'team'];
  dataSource: MatTableDataSource<BRelatedContent>;
  mergeReasons = MERGE_REASONS;
  mergeReasonChoices: typeof MERGE_REASON_CHOICES;
  mergeData: MergeData;

  private _interaction: BInteraction;
  private _mergeReason: MergeReason = undefined;
  private _reopenAfterMerge: boolean = false;
  private subs: Subscription = new Subscription();
  private loggedInUserIsSysAdmin: boolean = false;

  constructor(
    private relatedContentService: RelatedContentService,
    private singleInteractionService: SingleInteractionService,
    private authService: AuthService,
    private interactionMergeService: InteractionMergeService
  ) {
    this.mergeData = this.interactionMergeService.getInitialMergeData();
  }

  ngOnInit(): void {
    this.textSearch.subscribe((changes) => {
      if (changes != undefined) {
        this.search();
      }
    });
    this.subs.add(
      this.relatedContentService.values.subscribe((results) => {
        this.dataSource = new MatTableDataSource<BRelatedContent>(results);
      })
    );
    this.subs.add(
      this.singleInteractionService.relatedContentInteractionResult.subscribe((result) => {
        this.linkInteraction(result);
      })
    );
    this.subs.add(
      this.authService.user.subscribe((loggedInUser) => {
        this.loggedInUserIsSysAdmin = loggedInUser?.isSysAdmin();
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  @Input()
  set interaction(interaction: BInteraction) {
    this._interaction = interaction;
    if (interaction?.hasMergeSuggestion()) {
      this.isMergeSuggestion = true;
      this.singleInteractionService.performRelatedInteractionQueryWithId(
        this.interaction.suggestions['merge_to_interaction']['value']
      );
    } else {
      this.search();
      this.buildValidationMessage();
    }
  }

  get interaction(): BInteraction {
    return this._interaction;
  }

  search(): void {
    if (!this.interaction) {
      return;
    }
    let params = new EntitySearchParams();
    params.excludeInteractionId = this.interaction.pk();
    params.searchOnlyClosed = false;
    params.limit = 20;
    params.terms = '';
    if (this.textSearch.val) {
      params.terms = params.terms.concat(this.textSearch.val.trim());
    } else {
      let relatedInquiry = this.interaction.inquiries[0];
      if (relatedInquiry.question && relatedInquiry.question.plainText) {
        params.terms = relatedInquiry.question.plainText.split(' ').slice(0, 100).join(' ');
        if (this.interaction.mailSubject) {
          params.terms += ' ' + this.interaction.mailSubject;
        }
        params.inquiryPk = relatedInquiry.id;
      }
    }
    params.entityType = 'similar';

    this.relatedContentService.search(params);
  }

  selectRow(row: BRelatedContent): void {
    this.singleInteractionService.performRelatedInteractionQueryWithId(parseInt(row.id));
  }

  private linkInteraction(interaction: BInteraction): void {
    this.mergeData.targetInteraction = interaction;
    this.mergeData.targetClosedOrInReview = interaction?.isClosedOrInReview();
    this.mergeData.targetMergedOrClosedWithoutAnswer = interaction?.isMergedOrClosedWithoutAnswer();
    this.updateMergeDataSubject();
    this.mergeReason = undefined;
    this.resolveMergeReasonChoices();
    this.inquiryView = true;
  }

  cancelInquiryViewMode(): void {
    this.inquiryView = false;
    this.mergeData = this.interactionMergeService.getInitialMergeData();
    this.updateMergeDataSubject();
    this.mergeReason = undefined;
  }

  set mergeReason(reason: MergeReason) {
    this._mergeReason = reason;
    if (
      MERGE_REASONS_WITHOUT_REOPEN.includes(reason) ||
      (this.mergeData.targetMergedOrClosedWithoutAnswer &&
        reason !== MERGE_REASONS.followUpResponse) ||
      !this.mergeData.targetClosedOrInReview
    ) {
      this.showReopenCheckbox = false;
      this.reopenAfterMerge = false;
    } else {
      this.isReopenDisabled =
        reason == MERGE_REASONS.followUpResponse &&
        this.mergeData.targetInteraction.isClosedOrInReview() &&
        !this.mergeData.targetInteraction.hasAnswer();
      this.showReopenCheckbox = true;
      this.reopenAfterMerge = MERGE_REASONS_DEFAULT_REOPEN_MAP[this.mergeReason];
    }
    this.mergeData.mergeReason = reason;
    this.mergeData.reopenAfterMerge = this.reopenAfterMerge;
    this.mergeData.sourceInquiryIds = [];
    this.mergeData.targetInquiryIds = [];
    this.buildValidationMessage();
    this.updateMergeDataSubject();
    this.detectClarificationQuestionWithoutAnswer();
  }

  get mergeReason(): MergeReason {
    return this._mergeReason;
  }

  set reopenAfterMerge(reopen: boolean) {
    this._reopenAfterMerge = reopen;
    this.mergeData.reopenAfterMerge = reopen;
    this.updateMergeDataSubject();
    this.detectClarificationQuestionWithoutAnswer();
  }

  get reopenAfterMerge(): boolean {
    return this._reopenAfterMerge;
  }

  handleSelectedInquiryIdsChange(
    selectedInquiryIds: string[],
    isSelectedSourceInquiry: boolean = true
  ): void {
    setTimeout(() => {
      if (isSelectedSourceInquiry) {
        this.mergeData.sourceInquiryIds = selectedInquiryIds;
      } else {
        this.mergeData.targetInquiryIds = selectedInquiryIds;
      }
      this.buildValidationMessage();
      this.updateMergeDataSubject();
      this.detectClarificationQuestionWithoutAnswer();
    });
  }

  private updateMergeDataSubject(): void {
    this.interactionMergeService.mergeData$.next(this.mergeData);
  }

  private resolveMergeReasonChoices(): void {
    this.mergeReasonChoices = MERGE_REASON_CHOICES.filter((choice) => {
      if (choice.value === MERGE_REASONS.followUpResponse) {
        return this.mergeData.targetInteraction.hasFollowUpStatus();
      } else if (choice.value === MERGE_REASONS.duplicate) {
        return this.loggedInUserIsSysAdmin;
      }
      return true;
    }).map((choice) => {
      if (choice.value == MERGE_REASONS.newInquiry) {
        choice.description = this.mergeData.targetClosedOrInReview
          ? 'MERGE_AS_NEW_INQUIRY.DESCRIPTION_FOR_CLOSED_INTERACTION'
          : 'MERGE_AS_NEW_INQUIRY.DESCRIPTION_FOR_OPEN_INTERACTION';
      }
      return choice;
    });
  }

  private buildValidationMessage(): void {
    switch (true) {
      case !this.mergeData.targetInteraction?.id:
        this.mergeData.validationMsg = 'PLEASE_SELECT_INTERACTION_FOR_MERGE';
        break;
      case !this.mergeData.mergeReason:
        this.mergeData.validationMsg = 'PLEASE_SELECT_MERGE_REASON';
        break;
      case this.mergeReason === MERGE_REASONS.newInquiry &&
        this.mergeData.sourceInquiryIds.length === 0 &&
        !this.mergeData.targetClosedOrInReview:
        this.mergeData.validationMsg = 'MERGE_AS_NEW_INQUIRY.VALIDATION_MSG';
        break;
      case this.mergeReason === MERGE_REASONS.clarificationQuestion &&
        this.mergeData.targetInquiryIds.length === 0:
        this.mergeData.validationMsg = 'MERGE_AS_INQUIRY.VALIDATION_MSG';
        break;
      default:
        this.mergeData.validationMsg = undefined;
        break;
    }
  }

  private detectClarificationQuestionWithoutAnswer(): void {
    this.hasClarificationQuestionWithoutAnswer = false;
    if (
      this.mergeReason !== MERGE_REASONS.clarificationQuestion ||
      !this.mergeData.targetInquiryIds.length
    ) {
      return;
    }
    const targetInteractionOpen = !this.mergeData.targetClosedOrInReview;
    const targetInteractionClosedAndReopenSelected =
      this.mergeData.targetClosedOrInReview && this.mergeData.reopenAfterMerge;
    if (targetInteractionOpen || targetInteractionClosedAndReopenSelected) {
      this.hasClarificationQuestionWithoutAnswer =
        this.mergeData.targetInteraction.hasClarificationQuestionWithoutAnswer(
          this.mergeData.targetInquiryIds
        );
    }
  }
}
