import { priorityLevel } from '@mi-tool/consts/const';
import { base64ToPK, BaseModel, parseArray, parseAttr, rProperty } from '../data-model';
import { BLanguage } from '../language/language';
import { BTeam, BUser } from 'app/modules/data-model/user/user';
import { BInquirer } from 'app/modules/data-model/inquirer/inquirer';
import { BInquiry } from 'app/modules/data-model/interaction/interaction';
import { BEmail } from 'app/modules/data-model/email/email';
import { BPhone } from 'app/modules/data-model/phone/phone';
import { BAddress } from 'app/modules/data-model/address/address';

export class BNewInteraction extends BaseModel {
  @rProperty() id: string;
  @rProperty() title: string;
  @rProperty(Date) createdTs: Date;
  @rProperty(Date) editedTs: Date;
  @rProperty() outgoingChannel: string;
  @rProperty() outgoingBccList: string; //comma separated values
  @rProperty() productList: string;
  @rProperty() categoryList: string;
  @rProperty() topicList: string;
  @rProperty() statusLabel: string;
  @rProperty() confidential: boolean;
  @rProperty() answerUnavailable: boolean;
  @rProperty() needSrd: boolean;
  @rProperty() answerPreview: string;

  //relations
  inquirer: BInquirer;
  insertedBy: BUser;
  team: BTeam;
  language: BLanguage;
  editedBy: BUser;
  inquiries: BInquiry[];

  constructor(json: any) {
    super(json);
    this.init(json);
  }

  init(json: any) {
    this.language = parseAttr<BLanguage>(json, BLanguage, 'language');
    this.team = parseAttr<BTeam>(json, BTeam, 'team');
    this.editedBy = parseAttr<BUser>(json, BUser, 'editedBy');
    this.insertedBy = parseAttr<BUser>(json, BUser, 'insertedBy');
    this.inquirer = parseAttr<BInquirer>(json, BInquirer, 'inquirer');
    if (!this.inquirer) {
      this.inquirer = new BInquirer({
        emails: [new BEmail('')],
        phones: [new BPhone('')],
        addresses: [new BAddress('')],
      });
    }
    this.inquiries = parseArray<BInquiry>(json, BInquiry, 'inquiries');
  }

  pk(): number {
    if (!this.id) {
      return null;
    }
    return base64ToPK(this.id);
  }

  getMaxPriority(): string {
    if (!this.inquiries.length) {
      console.error('Interaction does not have inquiries', this.id);
      return undefined;
    }
    let max = 0;
    for (let i = 0; i < this.inquiries.length; ++i) {
      if (priorityLevel[this.inquiries[i].priority] > priorityLevel[this.inquiries[max].priority]) {
        max = i;
      }
    }
    return this.inquiries[max].priority;
  }

  inquirerIsValid(): boolean {
    return !!this.inquirer && !!this.inquirer.id;
  }

  detailsIsValid(): boolean {
    for (let inq of this.inquiries) {
      if (!inq.detailsIsValid()) {
        return false;
      }
    }
    return true;
  }
}
