export class InquirySearch {
  id?: string;
  name: string;
  advancedSearch: any;
  exportedColumns: any;
  favourite: boolean = false;

  constructor(json: any) {
    this.exportedColumns =
      (json.exported_columns &&
        typeof json.exported_columns === 'string' &&
        JSON.parse(json.exported_columns)) ||
      json.exported_columns ||
      null;
    this.advancedSearch =
      (json.advanced_search &&
        typeof json.advanced_search === 'string' &&
        JSON.parse(json.advanced_search)) ||
      json.advanced_search ||
      null;
    this.favourite = json.favourite;
    this.id = json.id || null;
    this.name = json.name || null;
  }

  static fromRest(json: any): InquirySearch {
    return json && Object.assign(new InquirySearch({}), new InquirySearch(json));
  }

  static fromRestArray(json: any[]): InquirySearch[] {
    return json && json.map((v) => InquirySearch.fromRest(v));
  }

  static toRest(json: InquirySearch): any {
    return (
      json && {
        id: json.id,
        exported_columns: json.exportedColumns,
        name: json.name,
        advanced_search: json.advancedSearch,
        favourite: json.favourite || false,
      }
    );
  }
}
