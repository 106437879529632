import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlResolverService } from 'app/common/url-resolver.service';
import { PartiallyRequired } from 'app/common/types';

/** 1 = days; 2 = weeks; 3 = months */
export type FollowUpFrequencyType = 1 | 2 | 3;

export type FollowUpTemplateType = {
  id: string; // Read only
  name: string; // Read only
  isActive: boolean;
  frequency: number;
  frequencyType: FollowUpFrequencyType;
  autoTranslate: boolean;
  text: string;
  previewText: string; // Read only
  languageId: string; // Read only
  originalTemplateId: string; // Read only
  isManuallyModified: boolean; // Read only
};

export const FREQUENCY_TYPE_TO_MAX_ALLOWED_NUM: Record<FollowUpFrequencyType, number> = {
  1: 365,
  2: 52,
  3: 12,
};

@Injectable({ providedIn: 'root' })
export class FollowUpService {
  private readonly URL: string;

  constructor(private http: HttpClient, private urlResolver: UrlResolverService) {
    this.URL = this.urlResolver.misApiUrlForPath('/templates/follow-up-reminder-templates');
  }

  getFollowUpTemplatesData(): Observable<FollowUpTemplateType[]> {
    return this.http.get<FollowUpTemplateType[]>(this.URL);
  }

  resetToDefaultTemplates(): Observable<void> {
    return this.http.post<void>(this.URL, {});
  }

  updateTemplateData(data: PartiallyRequired<FollowUpTemplateType, 'id'>): Observable<void> {
    return this.http.put<void>(this.URL, data);
  }
}
