import { gql } from 'apollo-angular';

import * as MAttachment from '../attachment/attachment';
import { MMedicalDocument } from '../medical-document/medical-document';
import * as MMedicalDocumentVersionFile from '../medical-document-version-file/medical-document-version-file';
import * as MMedicalDocumentType from '../medical-document-type/medical-document-type';
import { MUser } from '../user/user';
import { MHistory } from '../interaction/interaction';
import * as MInquirer from '../inquirer/inquirer';
import * as MSpecialization from '../specialization/specialization';
import * as MEmail from '../email/email';
import * as MPhone from '../phone/phone';
import * as MAddress from '../address/address';
import * as MCountry from '../country/country';
import * as MLanguage from '../language/language';

export const mutateHistoryQuery = gql`
  mutation updateHistory($params: UpdateHistoryInput!) {
    updateHistory(input: $params) {
      history {
        ...historyFragment
        interaction {
          id
          editedTs
        }
        plainText
        attachments {
          ...attachmentConnectionFragment
        }
        medicalDocuments {
          edges {
            node {
              ...medicalDocumentFragment
              type {
                ...medicalDocumentTypeFragment
              }
              currentVersion {
                id
                files {
                  ...medicalDocumentVersionFileConnectionFragment
                }
              }
            }
          }
        }
      }
      newHistory {
        ...newHistoryFragment
        plainText
        attachments {
          ...attachmentConnectionFragment
        }
        medicalDocuments {
          edges {
            node {
              ...medicalDocumentFragment
              type {
                ...medicalDocumentTypeFragment
              }
              currentVersion {
                id
                files {
                  ...medicalDocumentVersionFileConnectionFragment
                }
              }
            }
          }
        }
      }
    }
  }
  ${MHistory.fragment}
  ${MAttachment.fragmentConnection}
  ${MHistory.fragmentNew}
  ${MMedicalDocument.fragment}
  ${MMedicalDocumentVersionFile.fragmentConnection}
  ${MMedicalDocumentType.fragment}
`;

export const inquirerDataHistory = gql`
  query interactionHistory($inquirerId: String) {
    inquirerDataHistory(inquirerId: $inquirerId) {
      edges {
        node {
          ...inquirerRevisionFragment
          editedBy {
            ...userFragment
          }
          language {
            ...languageFragment
          }
          specialization {
            ...specializationFragment
          }
          defaultCountry {
            ...countryFragment
          }
          emails {
            ...emailRevisionConnectionFragment
          }
          phones {
            ...phoneRevisionConnectionFragment
          }
          addresses {
            edges {
              node {
                ...addressRevisionFragment
                country {
                  ...countryFragment
                }
              }
            }
          }
        }
      }
    }
  }
  ${MInquirer.fragmentRevision}
  ${MUser.fragment}
  ${MLanguage.fragment}
  ${MSpecialization.fragment}
  ${MEmail.fragmentRevisionConnection}
  ${MPhone.fragmentRevisionConnection}
  ${MAddress.fragmentRevision}
  ${MCountry.fragment}
`;
