<div class="navbar-top">
  <div class="logo">
    <img
      [routerLink]="['/inq/my-teams-open-inq']"
      alt="logo"
      class="logo-icon"
      src="/assets/img/icon.png"
    />
    <div
      *ngIf="onlyInquiries"
      [routerLink]="['/inq/my-teams-open-inq']"
      class="simple-inq-link text-16-900-white"
    >
      {{ 'INQUIRIES' | translate }}
    </div>
    <button *ngIf="!onlyInquiries" mat-menu-item [matMenuTriggerFor]="topMenu" class="top-menu-btn">
      <i class="fas fa-bars top-menu-btn-icon"></i>
      <span class="selected-nav truncated-text">{{ selectedNavyItem?.name | translate }}</span>
    </button>
  </div>
  <button
    mat-icon-button
    class="toggle-sidebar-folded medis-icon-button no-border"
    (click)="toggleSidebarFolded()"
  >
    <i
      class="text-11-800-azure"
      [ngClass]="isOpen ? 'fas fa-chevron-right' : 'fas fa-chevron-left'"
    ></i>
  </button>
</div>

<div class="navbar-scroll-container">
  <div class="navbar-content">
    <medis-navigation *ngIf="!isInquiryDetailView" class="material2"></medis-navigation>
    <app-detail-left-bar *ngIf="isInquiryDetailView"></app-detail-left-bar>
  </div>
</div>

<mat-menu #topMenu="matMenu" class="medis-menu">
  <ng-container *ngFor="let item of navigationRoots">
    <button
      *ngIf="!item.children && !item.isHidden"
      mat-menu-item
      [routerLink]="[item.href]"
      [queryParams]="item.queryParams"
      (click)="setSelectedItem(item)"
    >
      <i class="{{ item.icon }}"></i>
      <span>{{ item.name | translate }}</span>
    </button>
    <button
      *ngIf="item.children"
      mat-menu-item
      [matMenuTriggerFor]="childrenMenu"
      (mouseover)="getMenuChilds(item.children)"
    >
      <i class="{{ item.icon }}"></i>
      <span>{{ item.name | translate }}</span>
    </button>
  </ng-container>
</mat-menu>

<mat-menu #childrenMenu="matMenu" class="medis-menu">
  <ng-container *ngFor="let item of navyChildren">
    <button
      *ngIf="!item.isHidden"
      mat-menu-item
      [routerLink]="[item.href]"
      [queryParams]="item.queryParams"
      (click)="setSelectedItem(item)"
    >
      <span>{{ item.name | translate }}</span>
    </button>
  </ng-container>
</mat-menu>
