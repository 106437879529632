<mat-form-field
  class="form-field-wrapper w-100"
  [ngClass]="style"
  (click)="$event.stopPropagation()"
>
  <mat-label *ngIf="label">
    {{ label | translate }}
    @if (required) { ({{ 'REQUIRED' | translate }}) }
  </mat-label>
  <input
    matInput
    mwlFlatpickr
    [mode]="mode"
    [convertModelValue]="true"
    [altFormat]="enableTime ? 'd M y H:i' : 'd M y'"
    [altInput]="true"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [disabled]="disabled"
    [enableTime]="enableTime"
    [altInputClass]="altInputClass"
    [ngModel]="value"
    (flatpickrClose)="dateRangeChange($event)"
  />
  <i
    *ngIf="enableReset && value"
    matTooltip="{{ 'Reset' | translate }}"
    class="fas fa-ban medis-icon-reject ms-1 me-2"
    matSuffix
    (click)="dateRangeChange({ selectedDates: [] }); value = value === null ? undefined : null"
  ></i>
</mat-form-field>
