import { BTeam, BUser } from 'app/modules/data-model/user/user';
import { BTopic } from 'app/modules/data-model/topic/topic';
import { BProduct } from 'app/modules/data-model/region/region';
import { BCategory } from 'app/modules/data-model/category/category';
import { BTherapeuticArea } from 'app/modules/data-model/medical-document/medical-document';
import { BCompany } from 'app/modules/data-model/company/company';
import { BDomain } from 'app/modules/data-model/domain/domain';
import { Department } from 'app/modules/data-model/department/departments';
import { BSpecialization } from 'app/modules/data-model/specialization/specialization';
import { BTask } from 'app/modules/data-model/task/task';

export class UserAuditData {
  constructor(
    public user: BUser,
    public allUsers: BUser[],
    public role?: string,
    public team?: BTeam
  ) {}
}

export class NonUserAuditData {
  constructor(
    public user: BUser,
    public dataType: typeof AUDIT_TRAIL_DATA_TYPES[number],
    public dataValue:
      | BProduct
      | BCategory
      | BTopic
      | BSpecialization
      | BTherapeuticArea
      | BCompany
      | BDomain
      | Department
      | BTask
      | null
  ) {}
}

export class NonUserSingleAuditData {
  constructor(public user: BUser, public dataType: typeof AUDIT_TRAIL_DATA_TYPES[number]) {}
}

export const AUDIT_TRAIL_DATA_TYPES = [
  'user',
  'product',
  'category',
  'topic',
  'specialization',
  'therapeutic_area',
  'event',
  'company',
  'domain',
  'department',
  'task',
  'license_counter',
  'fu_templates',
] as const;

export const DATA_TYPE_TRANSLATIONS_MAPPER: Record<typeof AUDIT_TRAIL_DATA_TYPES[number], string> =
  {
    user: 'USERS',
    product: 'PRODUCTS',
    category: 'CATEGORIES',
    topic: 'TOPICS',
    specialization: 'SPECIALIZATIONS',
    therapeutic_area: 'THERAPEUTIC_AREAS',
    event: 'EVENTS',
    company: 'COMPANIES',
    domain: 'DOMAINS',
    department: 'DEPARTMENTS',
    task: 'TASKS',
    license_counter: 'LICENSE_COUNTER',
    fu_templates: 'FOLLOW_UP_REMINDER_TEMPLATES',
  } as const;
