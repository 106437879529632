<div class="medis-flex-container-between medis-pb-20">
  <span class="text-18-800-dark">
    {{ (interactionHasMergeSuggestion ? 'MERGE_SUGGESTION' : 'CLOSE_INTERACTION') | translate }}
  </span>
  <i class="far fa-times-circle medis-icon-large" (click)="handleCloseDialog()"></i>
</div>
<mat-dialog-content>
  <div *ngIf="interactionHasMergeSuggestion" class="medis-flex-container-start">
    <i class="fa fa-info-circle me-1 text-18-azure" aria-hidden="true"></i>
    <p class="text-16 m-0">
      {{ 'PLEASE_REVIEW_MERGE_SUGGESTION_BELOW' | translate }}
    </p>
  </div>
  <app-merge-selection [interaction]="interaction"></app-merge-selection>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="medis-flex-container-even medis-pt-25 medis-flex-grow">
    <button
      *ngIf="showMergeButton"
      mat-button
      class="medis-button-main"
      (click)="handleMerge()"
      [disabled]="
        mergeInProcess ||
        !!mergeData.validationMsg ||
        !mergeData.mergeReason ||
        !mergeData.targetInteraction
      "
      [matTooltip]="mergeData.validationMsg | translate"
      [matTooltipDisabled]="!mergeData.validationMsg"
    >
      {{ 'MERGE' | translate }}
    </button>
    <button mat-button class="medis-button-reject" (click)="handleCloseDialog()">
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</mat-dialog-actions>
