import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import {
  interactionAnswerPreview,
  interactionNewAnswerPreview,
  singleInteractionQuery,
  singleNewInteractionQuery,
  updateInteraction,
  updateInteractionForAnswer,
} from './queries';
import { base64ToModel, parseAttr, pkToBase64 } from '../data-model';
import * as MInteraction from './interaction';
import { BInteraction } from './interaction';

import { UrlResolverService } from 'app/common/url-resolver.service';
import { MessageHandlerService } from 'app/common/common/message-handler/message-handler.service';
import { createGuid } from 'app/common/uuid-generator';
import { PeriodicalExecutor } from 'app/common/common/periodical-service/periodical-service.service';
import { environment } from 'app/../environments/environment';
import { InteractionsService } from './interactions.service';
import { DateUtility } from 'app/common/date-utility';
import { Ajax } from 'app/common/ajax';
import { Config, newStatusNameConfig } from 'app/common/common/search-select/search-select.config';
import { TranslateService } from '@ngx-translate/core';
import { Helpers } from '@mi-tool/utils/helpers';
import { ErrorMessages } from '@mi-tool/enums/error.enum';
import { ApolloQueryResult } from '@apollo/client';
import { GenericDialogService } from 'app/common/common/generic-dialog/generic-dialog.service';
import { toNumber } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class SingleInteractionService {
  interaction: Subject<MInteraction.BInteraction> = new Subject<MInteraction.BInteraction>();
  relatedContentInteractionResult: Subject<MInteraction.BInteraction> =
    new Subject<MInteraction.BInteraction>();
  interactionAnswerText: Subject<MInteraction.BInteraction> =
    new Subject<MInteraction.BInteraction>();
  permissionDenied: Subject<boolean> = new Subject<boolean>();
  permissionForRelatedInteractionDenied$: Subject<boolean> = new Subject<boolean>();
  interactionStatuses: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  interactionStatusesConfig: BehaviorSubject<Config> = new BehaviorSubject<Config>(
    newStatusNameConfig([])
  );

  private refreshLockTime: Date;
  private currentInteraction: BInteraction;

  constructor(
    private helpers: Helpers,
    private apollo: Apollo,
    private messageService: MessageHandlerService,
    private urlResolverService: UrlResolverService,
    private translateService: TranslateService
  ) {
    this.refreshLockTime = DateUtility.newDate(-5000);
    this.interaction.subscribe((interaction) => {
      this.currentInteraction = interaction;
      this.loadStatusConfig(interaction, this.interactionStatuses.getValue());
    });
    this.interactionStatuses.subscribe((statuses) => {
      this.loadStatusConfig(this.currentInteraction, statuses);
    });
  }

  /**
   * Lock the possibility to refresh the interaction data, this must be used when interactive processes are on going, otherwise data can change and consistency is not guaranteed.
   */
  lock() {
    this.refreshLockTime = new Date();
  }

  unlock() {
    this.refreshLockTime = new Date(-5000);
  }

  private isRefreshLocked(): boolean {
    return DateUtility.hasElapsed(this.refreshLockTime, 3000);
  }

  private _genericQueryWithId(query: any, pk: string, background?: boolean): any {
    if (background == undefined) {
      background = false;
    }
    return this.apollo.query({
      query: query,
      variables: {
        id: pk,
        'X-NO-LOADING': background,
      },
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    });
  }

  private _singleQuery(pk: string, background?: boolean): any {
    return this._genericQueryWithId(singleInteractionQuery, pk, background);
  }

  private _parseInteractionOrNew(response: any): BInteraction {
    if (response.data['interaction']) {
      return new MInteraction.BInteraction(response.data['interaction']);
    } else {
      return new MInteraction.BInteraction(response.data['newInteraction']);
    }
  }

  performQuery(pk: string, background?: boolean) {
    console.log('refreshing lock', this.isRefreshLocked());
    if (this.isRefreshLocked()) {
      return;
    }
    this._singleQuery(pk, background).subscribe(
      (response) => {
        if (this.isPermissionDenied(response)) {
          this.permissionDenied.next(true);
          const pendingChanges = this.getPendingChangesData(response);
          this.interaction.next(
            new MInteraction.BInteraction(pendingChanges ? { pendingChanges } : '')
          );
        } else {
          this.interaction.next(new MInteraction.BInteraction(response.data['interaction']));
        }
      },
      (response, operation) => {
        console.error('Error', response, operation);
        // this.exceptionService.errorStream.next("(Interaction New) " + error);
      }
    );
  }

  private getPendingChangesData(response: ApolloQueryResult<BInteraction>): string {
    if (response.errors[0].message.includes(' | ')) {
      const messageParts = response.errors[0].message.split(' | ');
      let pendingChangesObject = JSON.parse(messageParts[1]);
      pendingChangesObject.interactionId = toNumber(messageParts[2]);
      return JSON.stringify(pendingChangesObject);
    }
    return '';
  }

  performQueryNew(pk: string, background?: boolean) {
    if (this.isRefreshLocked()) {
      return;
    }
    let queryNew = this._genericQueryWithId(singleNewInteractionQuery, pk, background);
    queryNew.subscribe(
      (response) => {
        this.interaction.next(new MInteraction.BInteraction(response.data['newInteraction']));
      },
      (error) => {
        this.messageService.error('(Interaction New) ' + error);
      }
    );
  }

  performQueryWithID(id: number, background?: boolean) {
    const pk = this.formatInteractionId(id, false);
    this.performQuery(pk, background);
  }

  performQueryNewWithID(id: number, background?: boolean) {
    const pk = this.formatInteractionId(id, true);
    this.performQueryNew(pk, background);
  }

  formatInteractionId(id: number, isDraft: boolean): string {
    const nodeType = isDraft ? 'NewInteractionNode' : 'InteractionNode';
    return pkToBase64(nodeType, id);
  }

  refreshInteraction(pk: string, background?: boolean) {
    if (base64ToModel(pk) == 'NewInteractionNode') {
      this.performQueryNew(pk, background);
    } else {
      this.performQuery(pk, background);
    }
  }

  performRelatedInteractionQueryWithId(id: number, background?: boolean) {
    if (this.isRefreshLocked()) {
      return;
    }
    const pk = this.formatInteractionId(id, false);
    this._singleQuery(pk, background).subscribe(
      (response) => {
        if (this.isPermissionDenied(response)) {
          this.permissionForRelatedInteractionDenied$.next(true);
        }
        this.relatedContentInteractionResult.next(
          new MInteraction.BInteraction(response.data['interaction'])
        );
      },
      (error) => {
        this.messageService.error('(Interaction performRelatedInteractionQuery) ' + error);
      }
    );
  }

  performInteractionAnswerPreview(pk: string, background?: boolean) {
    if (this.isRefreshLocked()) {
      return;
    }
    let queryToApply = interactionAnswerPreview;
    if (base64ToModel(pk) == 'NewInteractionNode') {
      queryToApply = interactionNewAnswerPreview;
    }
    let queryAnswer = this._genericQueryWithId(queryToApply, pk, background);
    queryAnswer.subscribe(
      (response) => {
        this.interactionAnswerText.next(this._parseInteractionOrNew(response));
      },
      (error) => {
        this.messageService.error('(performInteractionAnswerPreview) ' + error);
      }
    );
  }

  getPdfSrc(id: string): string {
    return this.urlResolverService.apiUrlForPath([
      'api-rest',
      'repository',
      'browser',
      'export-answer-pdf',
      `?interactionId=${id}`,
    ]);
  }

  loadStatusConfig(interaction: BInteraction, statuses: string[]): void {
    if (!interaction) {
      this.interactionStatusesConfig.next(newStatusNameConfig([]));
      return;
    }
    const interactionStatues = [interaction?.isNew() ? 'Draft' : interaction.statusLabel];
    const statusesList = [...statuses, 'Merged'] || [];
    const missingFields = interaction.resolveMissingFields(this.translateService);
    if (missingFields.length) {
      const index = statusesList.indexOf('Answered', 0);
      if (index > -1) {
        statusesList.splice(index, 1);
      }
    }
    this.interactionStatusesConfig.next(
      newStatusNameConfig(interactionStatues.concat(statusesList))
    );
  }

  getMetadataValidationMessage(interaction: BInteraction): string {
    let inquiriesMessage = '';
    let inactiveItemsCount = 0;

    interaction.inquiries.forEach((inquiry, questionIndex) => {
      const inactiveTopic = inquiry.topic && !inquiry.topic.isActive;
      const inactiveCategory = inquiry.category && !inquiry.category.isActive;
      const inactiveProduct = inquiry.product && !inquiry.product.isActive;

      const inactiveTopicValue = inactiveTopic && 'Topic "' + inquiry.topic.name + '"';
      const inactiveCategoryValue = inactiveCategory && 'Category "' + inquiry.category.name + '"';
      const inactiveProductValue = inactiveProduct && 'Product "' + inquiry.product.name + '"';

      const deactivatedValuesArray = [
        inactiveTopicValue,
        inactiveCategoryValue,
        inactiveProductValue,
      ].filter(Boolean);
      if (deactivatedValuesArray.length === 0) {
        return;
      }
      inactiveItemsCount += deactivatedValuesArray.length;

      let deactivatedMetadataItemsArray = [];
      let isTopicProductSpecific = () =>
        inquiry.topic?.isProductSpecific && inquiry.product ? ['Topic', 'Category', 'Product'] : [];

      if (inactiveTopic || inactiveCategory) {
        deactivatedMetadataItemsArray.push(...['Topic', 'Category'], ...isTopicProductSpecific());
      }
      if (inactiveProduct) {
        deactivatedMetadataItemsArray.push(...['Product'], ...isTopicProductSpecific());
      }

      const deactivatedValuesString = this.helpers.arrayOfItemsToText(
        deactivatedValuesArray,
        false
      );
      const beginInquiriesMessage = `${
        interaction.inquiries.length > 1 ? 'Question ' + (questionIndex + 1) + ' - ' : ''
      }<strong>${deactivatedValuesString}</strong>`;
      const endInquiriesMessage =
        'Please select a new ' +
        this.helpers.arrayOfItemsToText([...new Set(deactivatedMetadataItemsArray)]);

      inquiriesMessage += `${beginInquiriesMessage} - ${endInquiriesMessage}<br>`;
    });
    if (inquiriesMessage) {
      const infoMessageText = `The following item${
        inactiveItemsCount === 1 ? ' is' : 's are'
      } deactivated and no longer available for selection, therefore deselected.<br>`;
      return infoMessageText + inquiriesMessage;
    }
    return '';
  }

  private isPermissionDenied(responseData: ApolloQueryResult<BInteraction>): boolean {
    return Boolean(
      responseData?.errors &&
        Array.isArray(responseData.errors) &&
        responseData.errors.some((error) =>
          error?.message?.startsWith(ErrorMessages.PermissionDenied)
        )
    );
  }
}

@Injectable({ providedIn: 'root' })
export class EditInteractionService {
  response: Subject<BInteraction> = new Subject<BInteraction>();
  // to post and retrieve just the minimum data needed for interaction, and to avoid refresh of the entrie object in all components
  minimalInteractionResponse: Subject<BInteraction> = new Subject<BInteraction>();

  constructor(private apollo: Apollo, private messageService: MessageHandlerService) {}

  editInteractionMinimal(toMutate: Object, background?: boolean) {
    this.mutateWithQuery(toMutate, updateInteractionForAnswer, background).subscribe(
      (response) => {
        if (response) {
          this.minimalInteractionResponse.next(this.parseInteraction(response, background));
        } else {
          this.minimalInteractionResponse.next(undefined);
          this.messageService.error('Interaction Edit: empty response');
        }
      },
      (error) => {
        this.messageService.error('(Interaction Edit) ' + error);
      }
    );
  }

  editInteraction(toMutate: Object, background?: boolean): Subject<BInteraction> {
    const result = new Subject<BInteraction>();
    this.mutateWithQuery(toMutate, updateInteraction, background).subscribe(
      (response) => {
        if (response) {
          const interaction = this.parseInteraction(response, background);
          this.response.next(interaction);
          result.next(interaction);
        } else {
          this.response.next(undefined);
          this.messageService.error('Interaction Edit: empty response');
        }
      },
      (error) => {
        this.messageService.error('(Interaction Edit) ' + error);
      }
    );
    return result;
  }

  private mutateWithQuery(toMutate: Object, query: any, background?: boolean) {
    if (background == undefined) {
      background = false;
    }
    let mutationData = {};
    mutationData = Object.assign({}, toMutate);
    mutationData['clientMutationId'] = createGuid();
    return this.apollo.mutate({
      mutation: query,
      variables: { params: mutationData, 'X-NO-LOADING': background },
    });
  }

  private parseInteraction(response: any, background: boolean): BInteraction {
    if (!background) {
      this.messageService.info('Interaction updated correctly.');
    }
    const data = response.data.updateInteraction;
    const field = data.interaction ? 'interaction' : 'newInteraction';
    return parseAttr<BInteraction>(data, BInteraction, field);
  }
}

@Injectable({ providedIn: 'root' })
export class InteractionActionService {
  availableActions: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private availableActionsUrl: string = this.urlResolverService.apiUrlForPath([
    'api-rest',
    'workflow',
    'get',
    'actions',
    '/',
  ]);
  private performActionsUrl: string = this.urlResolverService.apiUrlForPath([
    'api-rest',
    'workflow',
    'perform',
    'actions',
    '/',
  ]);

  constructor(
    private http: HttpClient,
    private urlResolverService: UrlResolverService,
    private singleInteractionService: SingleInteractionService,
    private messageService: MessageHandlerService,
    private genericDialog: GenericDialogService
  ) {}

  getAvailableActionsForObj(objId: string) {
    if (!objId) {
      console.warn('No object id passed for fetching actions, no sense to cause ISE on the server');
      return;
    }
    this.http
      .post<string[]>(
        this.availableActionsUrl,
        { params: JSON.stringify({ object_id: objId }) },
        Ajax.X_NO_LOADING_OPTIONS
      )
      .subscribe((response) => {
        this.availableActions.next(response);
      });
  }

  performAction(data: any, retrieveInteraction: boolean = true): Observable<any> {
    const actionResponse = new Subject<any>();
    this.http.post(this.performActionsUrl, { params: JSON.stringify(data) }).subscribe(
      (response) => {
        if (response['success']) {
          actionResponse.next(response);
          if (retrieveInteraction) {
            // if the response is correct, retrieve the updated interaction
            this.singleInteractionService.unlock();
            this.singleInteractionService.performQuery(data.object_id);
          }
        } else {
          this.genericDialog.openDialogError('Error', response['error']);
        }
      },
      (error: HttpErrorResponse) => {
        this.messageService.error('An error occurred during the execution of the action.');
        const displayError = (error.status === 409 && error.error['detail']) || error.error;
        this.genericDialog.openDialogError('Error', displayError);
        actionResponse.next(error);
      }
    );
    return actionResponse;
  }
}

export class KeepAliveLockExecutor extends PeriodicalExecutor {
  interactionId: string;

  constructor(private inquiryDetail: InteractionsService) {
    super();
    this.interval = environment.keepAliveInterval;
  }

  execute() {
    if (this.executeData()) {
      this.inquiryDetail.keepAlive(this.executeData()).subscribe();
    }
  }
}
