import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { ColumnPreference } from 'app/modules/data-model/inquiries-table-preference/inquiries-table-preferences';

@Injectable({ providedIn: 'root' })
export class RightPanelService {
  screenColumns = new BehaviorSubject<ColumnPreference[]>([]);
  screenColumnsChange = new Subject<ColumnPreference[]>();
}
