import { gql } from 'apollo-angular';

import {
  base64ToModel,
  base64ToPK,
  BaseModel,
  parseArray,
  parseAttr,
  rProperty,
} from '../data-model';
import { BSpecialization } from '../specialization/specialization';
import { BEmail } from '../email/email';
import { BAddress } from '../address/address';
import { BPhone } from '../phone/phone';
import { BUser } from 'app/modules/data-model/user/user';
import { BCountry } from 'app/modules/data-model/country/country';
import { BLanguage } from 'app/modules/data-model/language/language';
import { BAlignment } from '../user/user';
import { Genders } from '@mi-tool/consts/const';

export class BInquirer extends BaseModel {
  saveOriginal = true;

  @rProperty() id: string;
  @rProperty() title: string;
  @rProperty() firstName: string;
  @rProperty() lastName: string;
  @rProperty() gender: string;
  @rProperty() typeOfInquirer: string;
  @rProperty() hcpVerified: boolean;
  @rProperty() keyOpinionLeader: boolean;
  @rProperty() hospitalBased: boolean;
  @rProperty() preferred_channel: string;
  @rProperty() institution: string;
  @rProperty() certification: string;
  @rProperty() department: string;
  @rProperty(Date) createdTs: Date;
  @rProperty(Date) editedTs: Date;
  @rProperty() importedId: string;
  @rProperty() importedSource: string;
  @rProperty(Date) importedTs: Date;
  @rProperty() realInquirerId: string;
  @rProperty(Date) firstImportTs: Date;
  @rProperty() city: string;
  @rProperty() deletePii: boolean;

  suggestedSpecializationId: string;
  suggestedDefaultCountryId: string;
  territory?: string;

  editedBy: BUser;
  specialization: BSpecialization;
  defaultCountry: BCountry;
  language: BLanguage;
  // should be used only for visualization (caching and same presentation of user)
  @rProperty() email: string;
  mainAddress: BAddress;
  mainPhone: BPhone;
  emails: BEmail[];
  phones: BPhone[];
  addresses: BAddress[];
  alignments: BAlignment[];

  constructor(json: any) {
    super(json);
    this.init(json);
  }

  init(json: any) {
    this.specialization = parseAttr<BSpecialization>(json, BSpecialization, 'specialization');
    this.defaultCountry = parseAttr<BCountry>(json, BCountry, 'defaultCountry');
    this.editedBy = parseAttr<BUser>(json, BUser, 'editedBy');
    this.mainAddress = parseAttr<BAddress>(json, BAddress, 'mainAddress');
    this.mainPhone = parseAttr<BPhone>(json, BPhone, 'mainPhone');
    this.emails = parseArray<BEmail>(json, BEmail, 'emails');
    this.phones = parseArray<BPhone>(json, BPhone, 'phones');
    this.addresses = parseArray<BAddress>(json, BAddress, 'addresses');
    this.language = parseAttr<BLanguage>(json, BLanguage, 'language');
    // First check suggestion, then check if contact should be filled to insert a new one
    this._checkSuggestions();
    if (this.phones.length === 0) {
      this.phones = [new BPhone('')];
    }
    if (this.emails.length === 0) {
      this.emails = [new BEmail('')];
    } else {
      const email = this.emails.find((e) => e.isMain) || this.emails[0];
      this.email = email.val;
    }
    if (this.addresses.length === 0) {
      this.addresses = [new BAddress('')];
    }
    this.alignments = parseArray<BAlignment>(json, BAlignment, 'alignments');
  }

  static fromRest(json: any): BInquirer {
    return json && Object.assign(new BInquirer({}), json);
  }

  static fromRestArray(json: any[]): BInquirer[] {
    return json && json.map((v) => BInquirer.fromRest(v));
  }

  displayInfo(): string {
    const fullName = this.fullName().trim();
    return fullName.length
      ? fullName
      : this.emails.length && this.emails[0].val
      ? this.emails[0].val
      : `ID: ${this.pk()}`;
  }

  fullName(): string {
    const comp = [this.firstName, this.lastName];
    return comp.filter((str) => (str ? true : false)).join(' ');
  }

  pk(): number {
    return this.id ? base64ToPK(this.id) : undefined;
  }

  realInquirerPk(): number {
    return this.realInquirerId ? base64ToPK(this.realInquirerId) : this.pk();
  }

  isNew(): boolean {
    if (!this.id) {
      return false;
    }
    return base64ToModel(this.id) == 'NewInquirerNode';
  }

  acceptSuggestions() {
    this.suggestions.fields = [];
    for (let email of this.emails) {
      email.suggestions.isSuggestion = false;
    }
    for (let phone of this.phones) {
      phone.suggestions.isSuggestion = false;
    }
  }

  private _checkSuggestions() {
    this.suggestions.fields.forEach((fieldName) => {
      if (this.objectFields.indexOf(fieldName) >= 0) {
        if (!this[fieldName]) {
          this[fieldName] = this.suggestions[fieldName].value;
        }
      }
      if (fieldName == 'phones') {
        let phone = new BPhone(this.suggestions[fieldName].value);
        phone.suggestions.isSuggestion = true;
        if (this.contactsCount(true) == 0) {
          phone.isMain = true;
        }
        this[fieldName].push(phone);
      }
      if (fieldName == 'emails') {
        let email = new BEmail(this.suggestions[fieldName].value);
        email.suggestions.isSuggestion = true;
        if (this.contactsCount(true) == 0) {
          email.isMain = true;
        }
        this[fieldName].push(email);
      }
      if (fieldName == 'addresses') {
        let address = new BAddress(this.suggestions[fieldName].value);
        address.suggestions.isSuggestion = true;
        if (this.contactsCount(true) == 0) {
          address.isMain = true;
        }
        this[fieldName].push(address);
      }
    });
  }

  getDefaultChannel(): 'email' | 'telephone' | 'letter' | '' {
    let mainEmails = this.emails.filter((x) => x.isMain && !x.isDeleted);
    if (mainEmails.length > 0) {
      return 'email';
    }
    let mainPhone = this.phones.filter((x) => x.isMain && !x.isDeleted);
    if (mainPhone.length > 0) {
      return 'telephone';
    }
    let mainAddress = this.addresses.filter((x) => x.isMain && !x.isDeleted);
    if (mainAddress.length > 0) {
      return 'letter';
    }
    return '';
  }

  getDefaultContact(): string {
    const mainContacts = [
      ...this.emails.filter((e) => e.isMain),
      ...this.phones.filter((p) => p.isMain),
    ];
    return mainContacts.length > 0 ? mainContacts[0].val : '';
  }

  genderToText(): string {
    return Genders.toText(this.gender);
  }

  titleToText(): string {
    return this.title;
  }

  contactsCount(onlyPreferred?: boolean): number {
    let count = 0;
    for (let email of this.emails) {
      if (email && email.val && !email.isDeleted) {
        if ((onlyPreferred && email.isMain) || !onlyPreferred) {
          count += 1;
        }
      }
    }
    for (let phone of this.phones) {
      if (phone && phone.val && !phone.isDeleted) {
        if ((onlyPreferred && phone.isMain) || !onlyPreferred) {
          count += 1;
        }
      }
    }
    for (let a of this.addresses) {
      if (a && a.country && a.city && a.address1 && !a.isDeleted) {
        if ((onlyPreferred && a.isMain) || !onlyPreferred) {
          count += 1;
        }
      }
    }
    return count;
  }

  // if the inquirer was created AFTER its import_ts (ie the moment when the migration in which the user was imported was performed), it means that a brand new inquirer was
  // created during said migration. Otherwise, it means the inquirer was already in medis and was only updated during the migration.
  isMedisNative(): boolean {
    if (this.firstImportTs && this.createdTs) {
      return !(this.firstImportTs.getTime() <= this.createdTs.getTime());
    } else {
      return true;
    }
  }
}

// fragments will contain only actual values of the object, no references to other tables
export const fragment = gql`
  fragment inquirerFragment on InquirerNode {
    id
    title
    gender
    firstName
    lastName
    city
    typeOfInquirer
    hcpVerified
    keyOpinionLeader
    hospitalBased
    createdTs
    editedTs
    preferredChannel
    department
    institution
    certification
    importedId
    importedSource
    importedTs
    firstImportTs
    deletePii
  }
`;

export const fragmentNew = gql`
  fragment newInquirerFragment on NewInquirerNode {
    id
    title
    gender
    firstName
    lastName
    city
    typeOfInquirer
    hcpVerified
    keyOpinionLeader
    hospitalBased
    createdTs
    editedTs
    preferredChannel
    department
    institution
    certification
    realInquirerId
    deletePii
  }
`;

export const fragmentRevision = gql`
  fragment inquirerRevisionFragment on RevisionInquirerNode {
    id
    title
    gender
    firstName
    lastName
    city
    typeOfInquirer
    hcpVerified
    keyOpinionLeader
    hospitalBased
    createdTs
    editedTs
    preferredChannel
    department
    institution
    deletePii
  }
`;

export const fragmentConnection = gql`
  fragment inquirerConnectionFragment on InquirerNodeConnection {
    edges {
      node {
        ...inquirerFragment
      }
    }
  }
  ${fragment}
`;
