import { gql } from 'apollo-angular';
import { BaseModel, rProperty, parseArray } from '../data-model';

import { BMDWordSection } from 'app/modules/data-model/md-word-section/md-word-section';

export class BMDWord extends BaseModel {
  @rProperty() id: string;

  // relations
  sections: BMDWordSection[];

  constructor(json: any) {
    super(json);
    this.init(json);
  }

  init(json: any) {
    this.sections = parseArray<BMDWordSection>(json, BMDWordSection, 'sections');
  }
}

export const fragment = gql`
  fragment mdWordFragment on MDWordNode {
    id
  }
`;

export const fragmentConnection = gql`
  fragment mdWordConnectionFragment on MDWordtNodeConnection {
    edges {
      node {
        ...mdWordFragment
      }
    }
  }
  ${fragment}
`;
