import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss'],
})
export class LoadingIndicatorComponent {
  @Input() empty: boolean;
  @Input() emptyLabel: string;

  private _loading: boolean;
  private loadingStart = new Date();

  @Input()
  set loading(value: boolean) {
    if (value) {
      this.loadingStart = new Date();
      this._loading = value;
    } else {
      const diffMs = new Date().getTime() - this.loadingStart.getTime();
      setTimeout(() => {
        this._loading = value;
      }, 750 - diffMs);
    }
  }

  get loading(): boolean {
    return this._loading;
  }
}
