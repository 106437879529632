import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { UrlResolverService } from 'app/common/url-resolver.service';
import { map, tap } from 'rxjs/operators';
import { isArray, isEmpty, isNil, omitBy } from 'lodash';

import {
  BDocumentWorkflowTemplate,
  MedicalDocumentsAppliedFilters,
  TMedicalDocumentsForApprovalResponse,
  TTeamConfigData,
  TWorkflowData,
} from './medical-documents-approval';

@Injectable({ providedIn: 'root' })
export class MedicalDocumentsApprovalService {
  public appliedFilters$ = new BehaviorSubject<MedicalDocumentsAppliedFilters>(
    MedicalDocumentsAppliedFilters.defaults()
  );
  public documents$ = new BehaviorSubject<TMedicalDocumentsForApprovalResponse | null>(null);

  private readonly URL: string;
  private readonly URL_TEMPLATES: string;
  private readonly URL_TEAMS: string;
  private readonly URL_AUTO_APPROVALS: string;
  private readonly DOCUMENTS_APPROVAL_URL: string;

  constructor(private http: HttpClient, urlResolverService: UrlResolverService) {
    this.URL = urlResolverService.misApiUrlForPath('/documents-approval/');
    this.URL_TEMPLATES = urlResolverService.misApiUrlForPath(
      '/documents-approval/workflow-templates'
    );
    this.URL_TEAMS = urlResolverService.misApiUrlForPath('/documents-approval/teams/');
    this.URL_AUTO_APPROVALS = urlResolverService.misApiUrlForPath(
      '/documents-approval/auto-approvals'
    );
    this.DOCUMENTS_APPROVAL_URL = urlResolverService.misApiUrlForPath(
      '/documents-approval/documents'
    );
  }

  getDocumentsForApproval(
    filters?: Omit<MedicalDocumentsAppliedFilters, 'validUntil' | 'approvalDate' | 'deadline'> & {
      validUntil: string;
      approvalDate: string;
      deadline: string;
    }
  ): Observable<TMedicalDocumentsForApprovalResponse> {
    return this.http
      .get<TMedicalDocumentsForApprovalResponse>(this.DOCUMENTS_APPROVAL_URL, {
        params: omitBy({ ...filters }, (paramValue) => {
          return isArray(paramValue) ? isEmpty(paramValue) : isNil(paramValue);
        }),
      })
      .pipe(
        tap((response) => {
          this.documents$.next(response);
        })
      );
  }

  teamConfigFetch(teamId: number): Observable<TTeamConfigData> {
    return this.http.get<TTeamConfigData>(this.URL_TEAMS + teamId);
  }

  teamConfigUpdate(teamId: number, teamConfigData: TTeamConfigData): Observable<void> {
    return this.http.put<void>(this.URL_TEAMS + teamId, teamConfigData);
  }

  autoApprovalDocumentTypesFetch(teamId: number): Observable<number[]> {
    return this.http.get<number[]>(this.URL_AUTO_APPROVALS, {
      params: { teamId },
    });
  }

  autoApprovalDocumentTypesUpdate(
    teamId: number,
    autoApprovalDocumentTypeIds: number[]
  ): Observable<void | string> {
    return this.http.put<void | string>(
      this.URL_AUTO_APPROVALS + '?teamId=' + teamId,
      autoApprovalDocumentTypeIds
    );
  }

  workflowTemplatesFetch(teamId: number): Observable<BDocumentWorkflowTemplate[]> {
    return this.http
      .get<BDocumentWorkflowTemplate[]>(this.URL_TEMPLATES, {
        params: { teamId },
      })
      .pipe(map((response) => BDocumentWorkflowTemplate.fromRestArray(response)));
  }

  workflowTemplateCreate(workflowData: TWorkflowData): Observable<void | string> {
    return this.http.post<void | string>(this.URL_TEMPLATES, workflowData);
  }

  workflowTemplateUpdate(workflowData: TWorkflowData): Observable<void | string> {
    return this.http.put<void | string>(this.URL_TEMPLATES + '/' + workflowData.id, workflowData);
  }

  workflowTemplateArchive(workflowId: number): Observable<void | string> {
    return this.http.delete<void | string>(this.URL_TEMPLATES + '/' + workflowId);
  }
}
