import { gql } from 'apollo-angular';
import * as MEvent from '../../data-model/event/event';
import { MUser } from '../../data-model/user/user';
import { MMedicalDocument } from '../../data-model/medical-document/medical-document';
import * as MMedicalDocumentVersionFile from '../../data-model/medical-document-version-file/medical-document-version-file';

import { MProduct } from '../region/region';

export const getAllEvents = gql`
  query getAllEvents($archived: Boolean) {
    allEvents(archived: $archived) {
      edges {
        node {
          ...eventFragment
          partecipants {
            ...userConnectionFragment
          }
          defaultValues {
            ...eventDefaultValueFragment
          }
          userShifts {
            edges {
              node {
                ...eventUserShiftFragment
              }
            }
          }
          documents {
            edges {
              node {
                ...medicalDocumentFragment
                type {
                  id
                  code
                }
                currentVersion {
                  id
                  files {
                    ...medicalDocumentVersionFileConnectionFragment
                  }
                }
              }
            }
          }
          products {
            ...productConnectionFragment
          }
        }
      }
    }
  }
  ${MEvent.fragment}
  ${MEvent.eventDefaultValueFragment}
  ${MEvent.eventUserShiftFragment}
  ${MUser.fragmentConnection}
  ${MMedicalDocument.fragment}
  ${MMedicalDocumentVersionFile.fragmentConnection}
  ${MProduct.fragmentConnection}
`;

export const getAllEventsMinimized = gql`
  query getAllEventsMinimized($archived: Boolean) {
    allEvents(archived: $archived) {
      edges {
        node {
          ...eventFragment
        }
      }
    }
  }
  ${MEvent.fragment}
`;

export const mutateUpdateEvent = gql`
  mutation mutateUpdateEvent($params: UpdateEventInput!) {
    updateEvent(input: $params) {
      event {
        ...eventFragment
      }
    }
  }
  ${MEvent.fragment}
`;
