import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class TranslatedMatPaginator extends MatPaginatorIntl {
  itemsPerPageLabel: string;
  nextPageLabel: string;
  previousPageLabel: string;
  firstPageLabel: string;
  lastPageLabel: string;

  constructor(private translateService: TranslateService) {
    super();
    this.itemsPerPageLabel = this.translateService.instant('PAGINATOR_ITEMS_PER_PAGE_LABEL');
    this.nextPageLabel = this.translateService.instant('PAGINATOR_NEXT_PAGE_LABEL');
    this.previousPageLabel = this.translateService.instant('PAGINATOR_PREV_PAGE_LABEL');
    this.firstPageLabel = this.translateService.instant('PAGINATOR_FIRST_PAGE_LABEL');
    this.lastPageLabel = this.translateService.instant('PAGINATOR_LAST_PAGE_LABEL');
  }
}
