import { gql } from 'apollo-angular';
import { Injectable, Injector } from '@angular/core';
import * as MTerritory from './territory';
import { BTerritory } from './territory';
import { BaseDataService } from '../data-service';
import { HttpClient } from '@angular/common/http';
import { UrlResolverService } from 'app/common/url-resolver.service';

const getAllTerritories = gql`
  query getAllTerritories {
    allTerritories {
      edges {
        node {
          ...territoryFragment
        }
      }
    }
  }
  ${MTerritory.fragment}
`;

export type TTerritoryData = {
  id: string;
  name: string;
};

export type TTerritoryDialogData = {
  territory: BTerritory;
};

export type TTerritoryParams = {
  pageIndex: number;
  pageSize: number;
  orderBy?: string;
  id?: number;
  name?: string;
};

@Injectable({ providedIn: 'root' })
export class TerritoryRestService {
  private readonly URL: string;

  constructor(private http: HttpClient, urlResolver: UrlResolverService) {
    this.URL = urlResolver.misApiUrlForPath('/metadata/territories');
  }

  fetchTerritories() {
    return null;
    // Will be implemented in the future <MIIS-3270>
  }

  edit() {
    return null;
    // Will be implemented in the future <MIIS-3270>
  }

  create() {
    return null;
    // Will be implemented in the future <MIIS-3270>
  }
}

@Injectable()
export class TerritoryService extends BaseDataService<MTerritory.BTerritory> {
  query = getAllTerritories;
  objectInstance = new MTerritory.BTerritory({});
  fieldName = 'allTerritories';

  constructor(injector: Injector) {
    super(injector);
  }
}
