import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { MedisSidebarComponent } from './sidebar.component';
import { ValueHolder } from '../../../app/common/value-holder';

export type StateHolder = ValueHolder<State>;
type State = 'folded' | 'unfolded';

@Injectable({ providedIn: 'root' })
export class MedisSidebarService {
  navigationItemIdChange = new BehaviorSubject<string>('');
  lastNavigationItemIdHolder: ValueHolder<string> = { value: 'Profile_settings' };

  private stateRegistry: { [key: string]: StateHolder } = {};
  private registry: { [key: string]: MedisSidebarComponent } = {};

  set lastNavigationItemId(val: string) {
    this.lastNavigationItemIdHolder.value = val;
    this.navigationItemIdChange.next(val);
  }

  get lastNavigationItemId(): string {
    return this.lastNavigationItemIdHolder.value;
  }

  register(key: string, sidebar: MedisSidebarComponent): void {
    if (this.registry[key]) {
      console.error(
        `The sidebar with the key '${key}' already exists. Either unregister it first or use a unique key.`
      );
      return;
    }

    this.registry[key] = sidebar;
    this.stateRegistry[key] = { value: undefined };
  }

  unregister(key: string): void {
    if (!this.registry[key]) {
      console.error(`The sidebar with the key '${key}' doesn't exist in the registry.`);
    }
    delete this.registry[key];
    delete this.stateRegistry[key];
  }

  getSidebar(key: string): MedisSidebarComponent {
    if (!this.registry[key]) {
      throw `No sidebar for ${key}`;
    }
    return this.registry[key];
  }

  getSidebarState(key: string): StateHolder {
    if (!this.stateRegistry[key]) {
      throw `No sidebar events subject for ${key}`;
    }
    return this.stateRegistry[key];
  }
}
