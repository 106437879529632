import { Injectable } from '@angular/core';
import {
  Config,
  newBCategoryIdConfig,
  newBTopicIdConfig,
  newChannelsConfig,
  newCountryConfig,
  newEventConfig,
  newInquirerIdConfig,
  newLanguageConfig,
  newProductConfig,
  newSpecializationsConfig,
  newStatusNameConfig,
  newTeamNameConfig,
  newTerritoryConfig,
  newUserIdConfig,
} from '../../../common/common/search-select/search-select.config';
import {
  FConfigs,
  filterConfigLabels,
  filterConfigMapping,
  filterSelection,
} from '../inquiries-list/inquiries-filter/configs/filter-configs';
import {
  Nomenclatures,
  NomenclaturesService,
  Scopes,
} from '../../data-model/nomenclature/nomenclatures.service';
import { BInquirer } from '../../data-model/inquirer/inquirer';
import { InquirersService } from '../../data-model/inquirer/inquirers.service';
import { BAlignment } from '../../data-model/user/user';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { Channels } from '@mi-tool/consts';

export type TAllMultipleDropdowns = { [key: string]: Config };

@Injectable({
  providedIn: 'root',
})
export class InquiriesStateService {
  allMultipleDropdownsDataForInboxAndAdvancedSearch$ = new BehaviorSubject<TAllMultipleDropdowns>(
    {}
  );
  interactions: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  nomenclaturesData: Nomenclatures;
  landingCommonData;
  allInquirers: BInquirer[];
  allTeritories: BAlignment[];

  allMultipleDropdownsForInboxAndAdvancedSearch = Object.assign(
    {},
    ...filterSelection
      .filter((filter) => filter.multiple)
      .map((object) => {
        return { [object.selector]: filterConfigMapping[object.selector] };
      })
  );

  constructor(
    private nomenclatureService: NomenclaturesService,
    private inquirersService: InquirersService
  ) {}

  private populateDropdownsData(): void {
    Object.keys(this.allMultipleDropdownsForInboxAndAdvancedSearch).forEach((prop) => {
      if (prop == FConfigs.INCOMING || prop == FConfigs.OUTGOING) {
        const options = prop == FConfigs.INCOMING ? Channels.AS_INCOMMING : Channels.AS_OUTGOING;
        const config = newChannelsConfig(options, filterConfigLabels[prop]);
        this.allMultipleDropdownsForInboxAndAdvancedSearch[prop] = config;
      } else if (prop == FConfigs.TEAM) {
        this.allMultipleDropdownsForInboxAndAdvancedSearch[prop] = newTeamNameConfig(
          this.landingCommonData.teams.sort((a, b) => (a.name < b.name ? -1 : 1)),
          filterConfigLabels[FConfigs.TEAM]
        );
      } else if (prop == FConfigs.PRODUCT) {
        this.allMultipleDropdownsForInboxAndAdvancedSearch[prop] = newProductConfig(
          this.landingCommonData.products.sort((a, b) => (a.name < b.name ? -1 : 1)),
          filterConfigLabels[FConfigs.PRODUCT]
        );
      } else if (prop == FConfigs.LANGUAGE) {
        this.allMultipleDropdownsForInboxAndAdvancedSearch[prop] = newLanguageConfig(
          this.landingCommonData.languages.sort((a, b) => (a.name < b.name ? -1 : 1))
        );
      } else if (prop == FConfigs.COUNTRY) {
        this.allMultipleDropdownsForInboxAndAdvancedSearch[prop] = newCountryConfig(
          this.landingCommonData.countries.sort((a, b) => (a.name < b.name ? -1 : 1))
        );
      } else if (prop == FConfigs.STATUS) {
        this.allMultipleDropdownsForInboxAndAdvancedSearch[prop] = newStatusNameConfig(
          this.landingCommonData.status_labels.sort()
        );
      } else if (prop == FConfigs.CATEGORY) {
        this.allMultipleDropdownsForInboxAndAdvancedSearch[prop] = newBCategoryIdConfig(
          this.nomenclaturesData.categories.sort((a, b) => (a.name < b.name ? -1 : 1)),
          filterConfigLabels[FConfigs.CATEGORY]
        );
      } else if (prop == FConfigs.TOPIC) {
        this.allMultipleDropdownsForInboxAndAdvancedSearch[prop] = newBTopicIdConfig(
          this.nomenclaturesData.topics.sort((a, b) => (a.name < b.name ? -1 : 1)),
          filterConfigLabels[FConfigs.TOPIC]
        );
      } else if (prop == FConfigs.EVENT) {
        this.allMultipleDropdownsForInboxAndAdvancedSearch[prop] = newEventConfig(
          this.nomenclaturesData.events.sort((a, b) => (a.name < b.name ? -1 : 1)),
          filterConfigLabels[FConfigs.EVENT]
        );
      } else if (prop == FConfigs.HCP || prop == FConfigs.NON_HCP) {
        const hcpTypeName = prop == FConfigs.HCP ? 'hcp' : 'cons';
        this.allMultipleDropdownsForInboxAndAdvancedSearch[prop] = newSpecializationsConfig(
          this.nomenclaturesData.specializations
            .filter((s) => s.typeName === hcpTypeName)
            .sort((a, b) => (a.name < b.name ? -1 : 1)),
          filterConfigLabels[prop]
        );
      } else if (
        prop == FConfigs.ASSIGNED_TO_ANY ||
        prop == FConfigs.ASSIGNED_TO ||
        prop == FConfigs.ANSWERED_BY ||
        prop == FConfigs.APPROVED_BY ||
        prop == FConfigs.INT_COMM_USER ||
        prop == FConfigs.INSERTED_BY ||
        prop == FConfigs.LAST_EDITED_BY
      ) {
        this.allMultipleDropdownsForInboxAndAdvancedSearch[prop] = newUserIdConfig(
          prop == FConfigs.INSERTED_BY
            ? this.nomenclaturesData.users
            : this.nomenclaturesData.users.filter((user) => user.isInqueryProcessing()),
          filterConfigLabels[prop]
        );
      } else if (prop == FConfigs.INQUIRER) {
        this.allMultipleDropdownsForInboxAndAdvancedSearch[prop] = newInquirerIdConfig(
          this.allInquirers,
          ''
        );
      } else if (prop == FConfigs.TERRITORY) {
        this.allMultipleDropdownsForInboxAndAdvancedSearch[prop] = newTerritoryConfig(
          this.allTeritories
        );
      }
    });

    this.allMultipleDropdownsDataForInboxAndAdvancedSearch$.next(
      this.allMultipleDropdownsForInboxAndAdvancedSearch
    );
  }

  setDropdownsState(landingCommonData): void {
    this.landingCommonData = landingCommonData;

    const scopes = new Scopes()
      .events()
      .specializations()
      .activeCategories()
      .activeTopics()
      .activeUsers();

    /* First get nomenclature data, then get inquires and territories and finally call populate dropdowns method */
    const fetchData = [
      this.nomenclatureService.get(scopes),
      this.inquirersService.get(''),
      this.inquirersService.getTerritory(''),
    ];

    forkJoin(fetchData).subscribe(([nomenclaturesData, inquirersData, territoriesData]: any) => {
      this.nomenclaturesData = nomenclaturesData;
      this.allInquirers = inquirersData;
      this.allTeritories = territoriesData;

      this.populateDropdownsData();
    });
  }

  getDropdownDisplayTerm(fieldName: string, values: any[]): string {
    let result = '';

    const fieldConfig = this.allMultipleDropdownsDataForInboxAndAdvancedSearch$.getValue();
    const valuesArr = [];

    if (fieldConfig[fieldName]) {
      values.forEach((value) => {
        const item = fieldConfig[fieldName].items[0].options.find((option) => {
          return value === option.value;
        });
        if (item) {
          valuesArr.push(item);
        }
      });
    }

    result = valuesArr
      .map((configItem) => {
        return configItem.name;
      })
      .join(',');

    return result;
  }
}
