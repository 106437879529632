import { EDialogStatuses } from '../enums/dialog.enum';

export const STATUSES_TRANSLATION_KEYS = [
  'ARCHIVED',
  'DRAFT',
  'APPROVED',
  'IN_REVIEW',
  'SUBMITTED_FOR_APPROVAL',
] as const;

export const STATUSES_KEY = ['archived', 'draft', 'approved', 'in_review', 'in_approval'] as const;

export const STATUSES_MAP: Record<
  typeof STATUSES_TRANSLATION_KEYS[number],
  { id: number; code: typeof STATUSES_KEY[number] }
> = {
  ARCHIVED: { id: 34, code: 'archived' },
  DRAFT: { id: 40, code: 'draft' },
  APPROVED: { id: 41, code: 'approved' },
  IN_REVIEW: { id: 44, code: 'in_review' },
  SUBMITTED_FOR_APPROVAL: { id: 45, code: 'in_approval' },
} as const;

export const DIALOG_STATUSES_DRAFT_AND_SUBMIT_FOR_APPROVAL: Readonly<EDialogStatuses[]> = [
  EDialogStatuses.CREATE_DRAFT,
  EDialogStatuses.SUBMIT_FOR_APPROVAL,
];
