import { Injectable } from '@angular/core';
import { BTaskLog } from './task-log';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UrlResolverService } from 'app/common/url-resolver.service';
import { SimplePage } from 'app/common/types';
import { Ajax } from 'app/common/ajax';
import { map } from 'rxjs/operators';
import { isNil, omitBy } from 'lodash';

export type TTaskLogParams = {
  pageIndex: number;
  pageSize: number;
  orderBy?: string;
  id?: number;
  taskId?: string;
  startedOn?: string;
  completedOn?: string;
  successful?: string;
  failureCount?: number;
  error?: string;
};

export type TTaskLogDialogData = {
  taskLog: BTaskLog;
};

@Injectable({ providedIn: 'root' })
export class TaskLogRestService {
  private readonly URL: string;

  constructor(private http: HttpClient, private urlResolver: UrlResolverService) {
    this.URL = urlResolver.misApiUrlForPath('/tasks/task-logs');
  }

  fetchTaskLogsList(params: TTaskLogParams): Observable<SimplePage<BTaskLog>> {
    return this.http
      .get<SimplePage<BTaskLog>>(this.URL, {
        ...Ajax.X_NO_LOADING_OPTIONS,
        params: omitBy(params, isNil),
      })
      .pipe(
        map((response) => {
          return { ...response, records: BTaskLog.fromRestArray(response.records) };
        })
      );
  }
}
