import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { parseArray, parseAttr } from '../../data-model/data-model';
import { createGuid } from 'app/common/uuid-generator';
import { MessageHandlerService } from 'app/common/common/message-handler/message-handler.service';

import { getAllEvents, mutateUpdateEvent } from './queries';
import { BEvent } from './event';

@Injectable()
export class EventService {
  values: Subject<BEvent[]>;
  responseEvent: Subject<BEvent>;
  eventsForLogin: Subject<BEvent[]>;

  constructor(private apollo: Apollo, private messageService: MessageHandlerService) {
    this.values = new Subject<BEvent[]>();
    this.responseEvent = new Subject<BEvent>();
    this.eventsForLogin = new Subject<BEvent[]>();
  }

  refresh(archived?: boolean) {
    let query = this.apollo.query({
      query: getAllEvents,
      variables: { archived: archived },
      fetchPolicy: 'network-only',
    });
    query.subscribe((res) => {
      if (res.data) {
        let events = parseArray<BEvent>(res.data, BEvent, 'allEvents');
        this.values.next(events);
      } else {
        this.messageService.error('Response with no data');
      }
    });
  }

  updateEvent(data: any) {
    let mutationData = {};
    mutationData = Object.assign({}, data);
    mutationData['clientMutationId'] = createGuid();
    if (mutationData['partecipantsId']) {
      mutationData['partecipantsId'] = JSON.stringify(mutationData['partecipantsId']);
    }
    if (mutationData['productsId']) {
      mutationData['productsId'] = JSON.stringify(mutationData['productsId']);
    }
    if (mutationData['documentsId']) {
      mutationData['documentsId'] = JSON.stringify(mutationData['documentsId']);
    }
    if (mutationData['defaultValues']) {
      mutationData['defaultValues'] = JSON.stringify(mutationData['defaultValues']);
    }
    if (mutationData['userShifts']) {
      mutationData['userShifts'] = JSON.stringify(mutationData['userShifts']);
    }
    let mutation = this.apollo.mutate({
      mutation: mutateUpdateEvent,
      variables: { params: mutationData },
    });
    mutation.subscribe({
      next: (response) => {
        if (response) {
          this.messageService.info('Event updated.');
          let event = parseAttr<BEvent>(response.data, BEvent, 'updateEvent');
          this.responseEvent.next(event);
        } else {
          this.responseEvent.next(undefined);
          this.messageService.error('Update Event: empty response');
        }
      },
      error: (error) => {
        this.messageService.error('(Update Event) ' + error);
      },
    });
  }
}
