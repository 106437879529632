<h2 mat-dialog-title>
  {{ (user ? 'USER_DETAILS' : 'INQUIRER_DETAILS') | translate }}
  <i class="far fa-times-circle medis-icon-large" (click)="ref.close()"></i>
</h2>
<mat-dialog-content>
  <app-loading-indicator
    [loading]="isLoading"
    [empty]="!inquirer && !user"
    [emptyLabel]="isPermissionDenied ? 'PERMISSION_DENIED' : ''"
  ></app-loading-indicator>
  <user-inquirer-card [user]="user" [inquirer]="inquirer"></user-inquirer-card>
</mat-dialog-content>
