import { gql } from 'apollo-angular';

import { MInquiry } from '../interaction/interaction';
import * as MCategory from '../category/category';
import * as MTopic from '../topic/topic';
import { MProduct } from '../region/region';

export const updateInquiry = gql`
  mutation updateInquiry($params: UpdateInquiryInput!) {
    updateInquiry(input: $params) {
      inquiry {
        ...inquiryFragment
        interaction {
          id
          editedTs
        }
        category {
          ...categoryFragment
        }
        topic {
          ...topicFragment
        }
        product {
          ...productFragment
        }
      }
      newInquiry {
        ...newInquiryFragment
        category {
          ...categoryFragment
        }
        topic {
          ...topicFragment
        }
        product {
          ...productFragment
        }
      }
    }
  }
  ${MInquiry.fragment}
  ${MCategory.fragment}
  ${MTopic.fragment}
  ${MProduct.fragment}
  ${MInquiry.fragmentNew}
`;
