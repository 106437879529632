import { Component, ViewEncapsulation } from '@angular/core';
import { authRequestNavigation } from '@mi-tool/navigation/auth-request-navigation';
import { teamNavigation, userProfileNavigation } from '@mi-tool/navigation/profile-navigation';
import {
  hcAdminNavigation,
  userAdminNavigation,
  userAdminSyncNavigation,
} from '@mi-tool/navigation/user-admin-navigation';
import { Helpers } from '../../utils/helpers';
import { AuthService } from 'app/auth/auth.service';

@Component({
  selector: 'medis-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MedisNavigationComponent {
  isInquiriesView: boolean;
  isDocumentsView: boolean;
  navigation = [];

  private userRoles: string[];
  private routerUrl: Helpers.View;

  constructor(private _helpers: Helpers, private authService: AuthService) {
    this.authService.user.subscribe((user) => {
      if (user) {
        this.userRoles = this.authService.getCurrentUserRoles();
        this.updateNavigation();
      }
    });

    this._helpers.layoutDependsOnRouterEvent$$.subscribe((routerUrl) => {
      this.routerUrl = routerUrl;
      this.updateNavigation();
    });
  }

  private updateNavigation(): void {
    if (this.routerUrl) {
      this.isInquiriesView = this.routerUrl === 'inquiriesView';
      this.isDocumentsView = this.routerUrl === 'documentsView';
      if (this.routerUrl === 'userProfileView' || this.routerUrl === 'userProfileAdminView') {
        this.navigation = userProfileNavigation(this.userRoles);
      } else if (this.routerUrl === 'teamsView') {
        this.navigation = teamNavigation(this.userRoles);
      } else if (this.routerUrl === 'authRequestsView') {
        this.navigation = authRequestNavigation(this.userRoles);
      } else if (this.routerUrl === 'userAdminView') {
        this.navigation = userAdminNavigation(this.userRoles);
      } else if (this.routerUrl === 'hcpAdministration') {
        this.navigation = hcAdminNavigation(this.userRoles);
      } else if (this.routerUrl === 'userSyncView') {
        this.navigation = userAdminSyncNavigation(this.userRoles);
      } else {
        this.navigation = [];
      }
    }
  }
}
