import { gql } from 'apollo-angular';

import { BaseModel, rProperty } from '../data-model';
import { BRole } from '../role/role';
import { IDataValue } from '@mi-tool/models/data-model';

export class BSpecialization extends BaseModel {
  @rProperty() id: string;
  @rProperty() name: string;
  @rProperty() typeName: string;
  @rProperty() nameKey: string;
  @rProperty() countries: IDataValue[] = [];

  // relations
  role: BRole;

  init(json: any) {}

  static fromRest(json: any): BSpecialization {
    return Object.assign(new BSpecialization({}), json);
  }

  static fromRestArray(json: any[]): BSpecialization[] {
    return json && json.map((v) => BSpecialization.fromRest(v));
  }
}

// fragments will contain only actual values of the object, no references to other tables
export const fragment = gql`
  fragment specializationFragment on SpecializationNode {
    id
    name
    typeName
  }
`;

export const fragmentConnection = gql`
  fragment specializationConnectionFragment on SpecializationNodeConnection {
    edges {
      node {
        ...specializationFragment
      }
    }
  }
  ${fragment}
`;
