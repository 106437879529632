import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BUser } from '../modules/data-model/user/user';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-page-access-denied',
  templateUrl: './page-access-denied.component.html',
})
export class PageAccessDeniedComponent implements OnInit, OnDestroy {
  private subs = new Subscription();
  loggedInUser: BUser;

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.subs.add(
      this.authService.user.subscribe((user) => {
        this.loggedInUser = user;
      })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  navigateToPermittedPage(): void {
    this.authService.fetchUserAndNavigateToPermittedPage();
  }
}
