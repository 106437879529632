import { MergeReason } from 'app/modules/data-model/interaction/interaction-merge.service';

export const MERGE_REASONS = {
  followUpResponse: 'fu_response',
  newInquiry: 'newinquiry',
  clarificationQuestion: 'inquiry',
  errorMessage: 'error',
  internalMessage: 'internal',
  thankYouMessage: 'thankyou',
  duplicate: 'duplicate',
} as const;

export const MERGE_REASON_CHOICES = [
  {
    name: 'A_FOLLOW_UP_RESPONSE',
    value: MERGE_REASONS.followUpResponse,
    historyMessages: ['AS', 'A_FOLLOW_UP_RESPONSE'],
    description: 'THIS_MESSAGE_WILL_BE_EXISTING_',
  },
  {
    name: 'NEW_INQ',
    value: MERGE_REASONS.newInquiry,
    historyMessages: ['AS_A', 'NEW_INQ'],
    description: 'MERGE_AS_NEW_INQUIRY.DESCRIPTION_FOR_OPEN_INTERACTION', // or MERGE_AS_NEW_INQUIRY.DESCRIPTION_FOR_CLOSED_INTERACTION
  },
  {
    name: 'CLARIFICATION_QUESTION',
    value: MERGE_REASONS.clarificationQuestion,
    historyMessages: ['AS_A', 'CLARIFICATION_QUESTION'],
    description: 'MERGE_AS_INQUIRY.DESCRIPTION',
  },
  {
    name: 'AN_ERROR_MESSAGE',
    value: MERGE_REASONS.errorMessage,
    historyMessages: ['AS', 'AN_ERROR_MESSAGE'],
    description: 'MARK_AS_AN_ERROR_',
  },
  {
    name: 'AN_INTERNAL_MESSAGE',
    value: MERGE_REASONS.internalMessage,
    historyMessages: ['AS', 'AN_INTERNAL_MESSAGE'],
    description: 'MARK_AS_INTERNAL_',
  },
  {
    name: 'THANK_YOU',
    value: MERGE_REASONS.thankYouMessage,
    historyMessages: ['AS_A', 'THANK_YOU_MESSAGE'],
    description: 'THIS_IS_JUST_A_THANK_YOU',
  },
  {
    name: 'Duplicate',
    value: MERGE_REASONS.duplicate,
    historyMessages: ['AS_A', 'Duplicate'],
    description: 'MERGE_AS_DUPLICATE.DESCRIPTION',
  },
];

export const MERGE_REASONS_WITHOUT_REOPEN: ReadonlyArray<MergeReason> = [
  MERGE_REASONS.newInquiry,
  MERGE_REASONS.thankYouMessage,
  MERGE_REASONS.duplicate,
] as const;

export const MERGE_REASONS_DEFAULT_REOPEN_MAP = {
  [MERGE_REASONS.clarificationQuestion]: true,
  [MERGE_REASONS.errorMessage]: true,
  [MERGE_REASONS.followUpResponse]: true,
  [MERGE_REASONS.internalMessage]: false,
} as const;

export const MERGE_DIALOG_CONFIG_OPTIONS = { minWidth: '95%', disableClose: true } as const;
