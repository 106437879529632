import { gql } from 'apollo-angular';

import { BaseModel, rProperty, base64ToPK } from '../data-model';

export class BMedicalDocumentAudience extends BaseModel {
  @rProperty() id: string;
  @rProperty() name: string;
  @rProperty() code: string;
  @rProperty() group: string;
  @rProperty() selected = false; // dynamically added argument. might not exists everywhere

  isActive = true;

  init(json: any) {}

  static fromRest(json: any): BMedicalDocumentAudience {
    return Object.assign(new BMedicalDocumentAudience({}), json);
  }

  static fromRestArray(json: any[]): BMedicalDocumentAudience[] {
    return json && json.map((v) => BMedicalDocumentAudience.fromRest(v));
  }

  getMId() {
    return base64ToPK(this.id);
  }

  getMName() {
    return this.name;
  }
}

export const fragment = gql`
  fragment medicalDocumentAudienceFragment on MedicalDocumentAudienceNode {
    id
    name
    code
    group
  }
`;

export const fragmentConnection = gql`
  fragment medicalDocumentAudienceConnectionFragment on MedicalDocumentAudienceNodeConnection {
    edges {
      node {
        ...medicalDocumentAudienceFragment
      }
    }
  }
  ${fragment}
`;
