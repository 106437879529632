import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { parseArray } from '../../data-model/data-model';
import { BUserNotification } from '../user/user';
import { allUserNotificationsQuery } from './queries';

@Injectable()
export class UserNotificationService {
  values: BehaviorSubject<BUserNotification[]>;

  constructor(apollo: Apollo) {
    this.values = new BehaviorSubject<BUserNotification[]>([]);
    let query = apollo.query({
      query: allUserNotificationsQuery,
    });

    query.subscribe((res) => {
      if (res.data) {
        let notificationTypes = parseArray<BUserNotification>(
          res.data,
          BUserNotification,
          'allUserNotifications'
        );
        this.values.next(notificationTypes);
      } else {
        console.log('Response with no data!');
      }
    });
  }
}
